import React from "react";

import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const NestedRadio = ({
  setHeaderSelected,
  headerSelected,
  setChildSelected,
  childSelected,
  radioData,
}) => {
  return (
    <RadioGroup
      value={headerSelected}
      onChange={(e) => {
        setHeaderSelected(e.target.value);
        setChildSelected("");
      }}
    >
      {radioData.map((el1) => {
        return (
          <Box>
            <FormControlLabel
              data-test-id={`label-${el1.header}`}
              aria-label={el1.header}
              value={el1.header}
              control={<Radio data-test-id={`radio-${el1.header}`} />}
              label={el1.header}
            />
            {headerSelected === el1?.header &&
              headerSelected !== "undefined" && (
                <RadioGroup
                  value={childSelected}
                  onChange={(e) => setChildSelected(e.target.value)}
                  sx={{ pl: 4 }}
                >
                  {el1.options.map((el2) => (
                    <FormControlLabel
                    sx={{width:"fit-content"}}
                      data-test-id={`label-${el2}`}
                      aria-label={el2}
                      value={el2}
                      control={<Radio data-test-id={`radio-${el2}`}/>}
                      label={el2}
                    />
                  ))}
                </RadioGroup>
              )}
          </Box>
        );
      })}
    </RadioGroup>
  );
};

export default NestedRadio;
