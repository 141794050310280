import React, { useContext } from "react";
import { Box } from "@mui/material";
// import { useLocation } from "react-router-dom";

import HeaderNaming from "../../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../../context/UserContext.jsx";
import OfferDetailsGrid from "../../GridLayout/OfferDetailsGrid.jsx";
// import TFFRP from "../../GridLayout/TFFRP.jsx";

const OfferDetails = () => {
  const { userProfile, currentOrganization, buyerGuideName, scenarioName, vendorName, offerName } =
    useContext(UserProfileContext);
  // const location = useLocation();
  // const { vendor, offer } = location.state || { vendor: "N/A", offer: "N/A" };

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
    Vendor: vendorName,
    Offer: offerName,
  };

  return (
    <Box sx={{ width: "100%", marginTop: 4, borderRadius: "10px" }}>
      <HeaderNaming data={data} />
      {/* <TFFRP/> */}
      <OfferDetailsGrid />
    </Box>
  );
};

export default OfferDetails;
