// import React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { FormControlLabel, Radio, Box, useTheme } from '@mui/material';

// export const DataTable = ({ rows, columns, selectedRowId, onSelectRow }) => {
//  const theme = useTheme(); // Using the theme from the app's context

//  // Ensure rows and columns are passed correctly
//  if (!rows || !columns) {
//    return <div>No Data Available</div>;
//  }

//  // Add headerClassName to each column to apply the custom class
//  const modifiedColumns = columns.map(column => {
//    if (column.field === 'select') {
//      return {
//        ...column,
//        renderCell: (params) => (
//          <FormControlLabel
//            control={
//              <Radio
//                checked={selectedRowId === params.id}
//                onChange={() => {
//                  // If the radio button is clicked and it is already selected, deselect it
//                  const newSelectedRowId = selectedRowId === params.id ? null : params.id;
//                  onSelectRow(newSelectedRowId); // Update selection
//                }} // Update selection
//                value={params.id}
//                onClick={(e) => e.stopPropagation()} // Prevent row click event
//              />
//            }
//            label=""
//          />
//        ),
//        headerClassName: 'super-app-theme--header',  // Apply custom header class
//      };
//    }

//    return {
//      ...column,
//      headerClassName: 'super-app-theme--header',  // Apply custom header class to all columns
//    };
//  });

//  return (
//    <Box>
//      <DataGrid
//        rows={rows}
//        columns={modifiedColumns}
//        pageSize={5}
//        rowsPerPageOptions={[5]}
//        checkboxSelection={false}
//        disableRowSelectionOnClick
//        hideFooter
//        getRowId={(row) => row.vendorId} // Ensure unique row IDs
//        sx={{
//          width: '100%',
//          height: '100%',
//          '& .super-app-theme--header': { // Custom header styling
//            backgroundColor: theme.palette.primary.main,
//            color: 'white',
//            fontSize: '14px',
//            fontWeight: 'bold',
//            '.MuiSvgIcon-root': {
//              color: 'white',
//            },
//          },
//          '& .MuiDataGrid-columnHeaderTitle': {
//            fontWeight: 'normal',
//          },
//          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
//            outline: 'none !important',
//          },
//        }}
//      />
//    </Box>
//  );
// };





// reusable

// import React from 'react';
// import { DataGrid } from '@mui/x-data-grid';
// import { FormControlLabel, Radio, Box, useTheme } from '@mui/material';

// /**
//  * Reusable DataTable component that renders a DataGrid with customizable properties.
//  *
//  * @param {Array} rows - The rows to display in the DataGrid.
//  * @param {Array} columns - The columns configuration for the DataGrid.
//  * @param {string} selectedRowId - The ID of the currently selected row.
//  * @param {function} onSelectRow - Function to call when a row is selected.
//  * @param {number} pageSize - Number of rows per page in the DataGrid.
//  * @param {boolean} hideFooter - Option to hide the footer.
//  * @param {function} getRowId - Function to determine unique row IDs.
//  * @param {boolean} checkboxSelection - Option to enable checkbox selection.
//  * @param {boolean} disableRowSelectionOnClick - Option to disable row selection on click.
//  * @param {Object} sx - Custom styles to apply to the DataGrid.
//  * @param {Object} additionalProps - Additional properties to pass to the DataGrid.
//  */
// export const DataTable = ({
//   rows = [],
//   columns = [],
//   selectedRowId,
//   onSelectRow,
//   pageSize = 5,
//   hideFooter=true,
//   getRowId,
//   checkboxSelection = false,
//   disableRowSelectionOnClick = true,
//   sx = {},
//   ...additionalProps
// }) => {
//   const theme = useTheme(); // Using the theme from the app's context

//   // Ensure rows and columns are passed correctly
//   if (!rows.length || !columns.length) {
//     return <div>No Data Available</div>;
//   }

//   // Modify columns to add custom header styles and rendering logic
//   const modifiedColumns = columns.map((column) => {
//     if (column.field === 'select') {
//       return {
//         ...column,
//         renderCell: (params) => (
//           <FormControlLabel
//             control={
//               <Radio
//                 checked={selectedRowId === params.id}
//                 onChange={() => {
//                   const newSelectedRowId = selectedRowId === params.id ? null : params.id;
//                   onSelectRow(newSelectedRowId);
//                 }}
//                 value={params.id}
//                 onClick={(e) => e.stopPropagation()} // Prevent row click event
//               />
//             }
//             label=""
//           />
//         ),
//         headerClassName: 'super-app-theme--header', // Apply custom header class
//       };
//     }
//     return {
//       ...column,
//       headerClassName: 'super-app-theme--header', // Apply custom header class to all columns
//     };
//   });

//   return (
//     <Box>
//       <DataGrid
//         rows={rows}
//         columns={modifiedColumns}
//         pageSize={pageSize}
//         rowsPerPageOptions={[pageSize]}
//         checkboxSelection={checkboxSelection}
//         disableRowSelectionOnClick={disableRowSelectionOnClick}
//         hideFooter={hideFooter}
//         getRowId={getRowId} // Unique row identifier
//         sx={{
//           width: '100%',
//           height: '100%',
//           '& .super-app-theme--header': { // Custom header styling
//             backgroundColor: theme.palette.primary.main,
//             color: 'white',
//             fontSize: '14px',
//             fontWeight: 'bold',
//             '.MuiSvgIcon-root': {
//               color: 'white',
//             },
//           },
//           '& .MuiDataGrid-columnHeaderTitle': {
//             fontWeight: 'normal',
//           },
//           '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
//             outline: 'none !important',
//           },
//           ...sx, // Merge custom styles
//         }}
//         {...additionalProps} // Spread additional properties to DataGrid
//       />
//     </Box>
//   );
// };


// final reusable 


import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FormControlLabel, Radio, Box, useTheme } from '@mui/material';

/**
 * Reusable DataTable component that renders a DataGrid with customizable properties.
 *
 * @param {Array} rows - The rows to display in the DataGrid.
 * @param {Array} columns - The columns configuration for the DataGrid.
 * @param {string} selectedRowId - The ID of the currently selected row.
 * @param {function} onSelectRow - Function to call when a row is selected.
 * @param {number} pageSize - Number of rows per page in the DataGrid.
 * @param {boolean} hideFooter - Option to hide the footer.
 * @param {function} getRowId - Function to determine unique row IDs.
 * @param {boolean} checkboxSelection - Option to enable checkbox selection.
 * @param {boolean} disableRowSelectionOnClick - Option to disable row selection on click.
 * @param {Object} sx - Custom styles to apply to the DataGrid.
 * @param {Object} additionalProps - Additional properties to pass to the DataGrid.
 */
export const DataTable = ({
  rows = [],
  columns = [],
  selectedRowId,
  onSelectRow,
  pageSize = 5,
  hideFooter = true,
  getRowId,
  checkboxSelection = false,
  disableRowSelectionOnClick = true,
  sx = {},
  ...additionalProps
}) => {
  const theme = useTheme(); // Using the theme from the app's context

  // Ensure rows and columns are passed correctly
  if (!rows.length || !columns.length) {
    return <div>No Data Available</div>;
  }

  // Modify columns to add custom header styles and rendering logic
  const modifiedColumns = columns.map((column) => {
    if (column.field === 'select') {
      return {
        ...column,
        renderCell: (params) => {
          // Use params.row.id as the unique identifier for rows
          const rowId = getRowId ? getRowId(params.row) : params.row.id;

          return (
            <FormControlLabel
              control={
                <Radio
                  data-test-id="radio-button"
                  checked={selectedRowId === rowId}
                   // Use the correct ID for comparison
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the row click event
                    // Toggle the radio button selection: if same row clicked again, deselect it
                    const newSelectedRowId = selectedRowId === rowId ? null : rowId;
                    onSelectRow(newSelectedRowId); // Update the selected row ID
                  }}
                  value={rowId}
                />
              }
              label=""
              onClick={(e) => e.stopPropagation()} // Prevent row click event
            />
          );
        },
        headerClassName: 'super-app-theme--header', // Apply custom header class
      };
    }
    return {
      ...column,
      headerClassName: 'super-app-theme--header', // Apply custom header class to all columns
    };
  });

  return (
    <Box>
      <DataGrid
        rows={rows}
        columns={modifiedColumns}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        hideFooter={hideFooter}
        getRowId={getRowId} // Unique row identifier
        sx={{
          width: '100%',
          height: '100%',
          '& .super-app-theme--header': { // Custom header styling
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
            '.MuiSvgIcon-root': {
              color: 'white',
            },
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'normal',
          },
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
          ...sx, // Merge custom styles
        }}
        {...additionalProps} // Spread additional properties to DataGrid
      />
    </Box>
  );
};
