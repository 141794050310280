import React from "react";
import { Box, Typography } from "@mui/material";

import NumberRenderCell from "../../components/AvailableUnitsForAdminAllocation/RenderCells/NumberRenderCell.jsx";
import UserUnitInputRenderCell from "../../components/AvailableUnitsForAdminAllocation/RenderCells/UserUnitInputRenderCell.jsx";
import CheckboxRenderCell from "../../components/AvailableUnitsForAdminAllocation/RenderCells/CheckboxRenderCell.jsx";
import InputRenderCellWithPlusButton from "../../components/AvailableUnitsForAdminAllocation/RenderCells/InputRenderCellWithRowAdditionButton.jsx";
import DropdownRenderCellWithRowAdditionButton from "../../components/AvailableUnitsForAdminAllocation/RenderCells/DropdownRenderCellWithRowAdditionButton.jsx";

const generateColumnData = (
  page,
  sectionData,
  setSectionData,
  setModalData,
  modalData
) => {
  // Reusable function to create column definitions
  const createColumn = (field, headerName, renderCell) => ({
    field,
    headerName,
    headerClassName: "super-app-theme--header",
    display: "flex",
    flex: 1,
    renderCell,
  });

  // Function to determine the correct render cell based on user type
  const createRenderCell = (unitName, inputsLabel, totalLabel) => (params) => {
    const isOther =
      (params.row?.user === "Self" && unitName === "user") ||
      unitName !== "user";

    const RenderComponent = !isOther ? NumberRenderCell : CheckboxRenderCell;

    return (
      <RenderComponent
        params={params}
        unitName={unitName}
        inputsLabel={inputsLabel}
        totalLabel={totalLabel}
        sectionData={sectionData}
        setSectionData={setSectionData}
        setModalData={setModalData}
        modalData={modalData}
      />
    );
  };

  // User Unit's column data
  const userUnits = [
    createColumn("user", "User", (params) => {
      const id = params.row.id;
      return (
        <Box style={{ width: "100%", height: "100%", display: "flex" }}>
          {!String(id).startsWith("other") && (
            <Typography
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {params.value}
            </Typography>
          )}
        </Box>
      );
    }),
    createColumn("name", "Name", (params) => (
      <UserUnitInputRenderCell
        params={params}
        inputsLabel={"name"}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    createColumn("email", "Email", (params) => (
      <UserUnitInputRenderCell
        params={params}
        inputsLabel={"email"}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),

    // Solo Starters

    createColumn(
      "soloStarterFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "user",
        "soloStarterFullyPrescribedInput",
        "soloStarterFullyPrescribedTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "user",
        "soloStarterPartiallyPrescribedEUInput",
        "soloStarterPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "user",
        "soloStarterPartiallyPrescribedBUInput",
        "soloStarterPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "soloStarterUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "user",
        "soloStarterUnPrescribedInput",
        "soloStarterUnPrescribedTotal"
      )
    ),

    //

    // Duel Dynamics

    createColumn(
      "duelDynamicsFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "user",
        "duelDynamicsFullyPrescribedInput",
        "duelDynamicsFullyPrescribedTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "user",
        "duelDynamicsPartiallyPrescribedEUInput",
        "duelDynamicsPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "user",
        "duelDynamicsPartiallyPrescribedBUInput",
        "duelDynamicsPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "duelDynamicsUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "user",
        "duelDynamicsUnPrescribedInput",
        "duelDynamicsUnPrescribedTotal"
      )
    ),

    // Focus Five

    createColumn(
      "focusFiveFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "user",
        "focusFiveFullyPrescribedInput",
        "focusFiveFullyPrescribedTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "user",
        "focusFivePartiallyPrescribedEUInput",
        "focusFivePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "user",
        "focusFivePartiallyPrescribedBUInput",
        "focusFivePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "focusFiveUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "user",
        "focusFiveUnPrescribedInput",
        "focusFiveUnPrescribedTotal"
      )
    ),

    // All Available

    createColumn(
      "allAvailableFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "user",
        "allAvailableFullyPrescribedInput",
        "allAvailableFullyPrescribedTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "user",
        "allAvailablePartiallyPrescribedEUInput",
        "allAvailablePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "user",
        "allAvailablePartiallyPrescribedBUInput",
        "allAvailablePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "allAvailableUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "user",
        "allAvailableUnPrescribedInput",
        "allAvailableUnPrescribedTotal"
      )
    ),
  ];

  // End User Organization Unit's column data
  const endUserOrganizationUnits = [
    createColumn("organisationName", "Organisation Name", (params) => (
      <InputRenderCellWithPlusButton
        params={params}
        inputsLabel={"organisationName"}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),

    // Solo Starters

    createColumn(
      "soloStarterFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "organisationName",
        "soloStarterFullyPrescribedInput",
        "soloStarterFullyPrescribedTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "organisationName",
        "soloStarterPartiallyPrescribedEUInput",
        "soloStarterPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "organisationName",
        "soloStarterPartiallyPrescribedBUInput",
        "soloStarterPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "soloStarterUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "organisationName",
        "soloStarterUnPrescribedInput",
        "soloStarterUnPrescribedTotal"
      )
    ),

    //

    // Duel Dynamics

    createColumn(
      "duelDynamicsFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "organisationName",
        "duelDynamicsFullyPrescribedInput",
        "duelDynamicsFullyPrescribedTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "organisationName",
        "duelDynamicsPartiallyPrescribedEUInput",
        "duelDynamicsPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "organisationName",
        "duelDynamicsPartiallyPrescribedBUInput",
        "duelDynamicsPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "duelDynamicsUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "organisationName",
        "duelDynamicsUnPrescribedInput",
        "duelDynamicsUnPrescribedTotal"
      )
    ),

    // Focus Five

    createColumn(
      "focusFiveFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "organisationName",
        "focusFiveFullyPrescribedInput",
        "focusFiveFullyPrescribedTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "organisationName",
        "focusFivePartiallyPrescribedEUInput",
        "focusFivePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "organisationName",
        "focusFivePartiallyPrescribedBUInput",
        "focusFivePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "focusFiveUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "organisationName",
        "focusFiveUnPrescribedInput",
        "focusFiveUnPrescribedTotal"
      )
    ),

    // All Available

    createColumn(
      "allAvailableFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "organisationName",
        "allAvailableFullyPrescribedInput",
        "allAvailableFullyPrescribedTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "organisationName",
        "allAvailablePartiallyPrescribedEUInput",
        "allAvailablePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "organisationName",
        "allAvailablePartiallyPrescribedBUInput",
        "allAvailablePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "allAvailableUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "organisationName",
        "allAvailableUnPrescribedInput",
        "allAvailableUnPrescribedTotal"
      )
    ),
  ];

  // Buyer Guides Unit's column data
  const buyerGuideUnits = [
    createColumn("buyerGuideName", "Buyer Guide Name", (params) => (
      <DropdownRenderCellWithRowAdditionButton
        inputsLabel={"buyerGuideName"}
        params={params}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),

    // Solo Starters

    createColumn(
      "soloStarterFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "buyerGuideName",
        "soloStarterFullyPrescribedInput",
        "soloStarterFullyPrescribedTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "buyerGuideName",
        "soloStarterPartiallyPrescribedEUInput",
        "soloStarterPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "buyerGuideName",
        "soloStarterPartiallyPrescribedBUInput",
        "soloStarterPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "soloStarterUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "buyerGuideName",
        "soloStarterUnPrescribedInput",
        "soloStarterUnPrescribedTotal"
      )
    ),

    //

    // Duel Dynamics

    createColumn(
      "duelDynamicsFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "buyerGuideName",
        "duelDynamicsFullyPrescribedInput",
        "duelDynamicsFullyPrescribedTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "buyerGuideName",
        "duelDynamicsPartiallyPrescribedEUInput",
        "duelDynamicsPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "buyerGuideName",
        "duelDynamicsPartiallyPrescribedBUInput",
        "duelDynamicsPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "duelDynamicsUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "buyerGuideName",
        "duelDynamicsUnPrescribedInput",
        "duelDynamicsUnPrescribedTotal"
      )
    ),

    // Focus Five

    createColumn(
      "focusFiveFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "buyerGuideName",
        "focusFiveFullyPrescribedInput",
        "focusFiveFullyPrescribedTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "buyerGuideName",
        "focusFivePartiallyPrescribedEUInput",
        "focusFivePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "buyerGuideName",
        "focusFivePartiallyPrescribedBUInput",
        "focusFivePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "focusFiveUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "buyerGuideName",
        "focusFiveUnPrescribedInput",
        "focusFiveUnPrescribedTotal"
      )
    ),

    // All Available

    createColumn(
      "allAvailableFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "buyerGuideName",
        "allAvailableFullyPrescribedInput",
        "allAvailableFullyPrescribedTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "buyerGuideName",
        "allAvailablePartiallyPrescribedEUInput",
        "allAvailablePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "buyerGuideName",
        "allAvailablePartiallyPrescribedBUInput",
        "allAvailablePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "allAvailableUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "buyerGuideName",
        "allAvailableUnPrescribedInput",
        "allAvailableUnPrescribedTotal"
      )
    ),
  ];

  // Unlocked Vendors Unit's column data
  const unlockedVendors = [
    createColumn("vendorName", "Vendor Name", (params) => (
      <DropdownRenderCellWithRowAdditionButton
        inputsLabel={"vendorName"}
        params={params}
        sectionData={sectionData}
        setSectionData={setSectionData}
      />
    )),
    // Solo Starters

    createColumn(
      "soloStarterFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "vendorName",
        "soloStarterFullyPrescribedInput",
        "soloStarterFullyPrescribedTotal"
      )
    ),
    createColumn(
      "soloStarterPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "vendorName",
        "soloStarterPartiallyPrescribedEUInput",
        "soloStarterPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "soloStarterPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "vendorName",
        "soloStarterPartiallyPrescribedBUInput",
        "soloStarterPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "soloStarterUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "vendorName",
        "soloStarterUnPrescribedInput",
        "soloStarterUnPrescribedTotal"
      )
    ),

    //

    // Duel Dynamics

    createColumn(
      "duelDynamicsFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "vendorName",
        "duelDynamicsFullyPrescribedInput",
        "duelDynamicsFullyPrescribedTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "vendorName",
        "duelDynamicsPartiallyPrescribedEUInput",
        "duelDynamicsPartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "duelDynamicsPartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "vendorName",
        "duelDynamicsPartiallyPrescribedBUInput",
        "duelDynamicsPartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "duelDynamicsUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "vendorName",
        "duelDynamicsUnPrescribedInput",
        "duelDynamicsUnPrescribedTotal"
      )
    ),

    // Focus Five

    createColumn(
      "focusFiveFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "vendorName",
        "focusFiveFullyPrescribedInput",
        "focusFiveFullyPrescribedTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "vendorName",
        "focusFivePartiallyPrescribedEUInput",
        "focusFivePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "focusFivePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "vendorName",
        "focusFivePartiallyPrescribedBUInput",
        "focusFivePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "focusFiveUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "vendorName",
        "focusFiveUnPrescribedInput",
        "focusFiveUnPrescribedTotal"
      )
    ),

    // All Available

    createColumn(
      "allAvailableFullyPrescribedTotal",
      "Fully Prescribed",
      createRenderCell(
        "vendorName",
        "allAvailableFullyPrescribedInput",
        "allAvailableFullyPrescribedTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedEUTotal",
      "Partially Prescribed (End-User)",
      createRenderCell(
        "vendorName",
        "allAvailablePartiallyPrescribedEUInput",
        "allAvailablePartiallyPrescribedEUTotal"
      )
    ),

    createColumn(
      "allAvailablePartiallyPrescribedBUTotal",
      "Partially Prescribed (Buyer Guide)",
      createRenderCell(
        "vendorName",
        "allAvailablePartiallyPrescribedBUInput",
        "allAvailablePartiallyPrescribedBUTotal"
      )
    ),

    createColumn(
      "allAvailableUnPrescribedTotal",
      "UnPrescribed",
      createRenderCell(
        "vendorName",
        "allAvailableUnPrescribedInput",
        "allAvailableUnPrescribedTotal"
      )
    ),
  ];

  const dataMapping = {
    userUnits,
    endUserOrganizationUnits,
    buyerGuideUnits,
    unlockedVendors,
  };

  return dataMapping[page];
};

export default generateColumnData;
