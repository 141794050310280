import React, { useState, useEffect } from "react";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import theme from "../../theme/theme.js";

import DataGridTable from "../../components/DataGridTable.jsx";

import generateColumnData from "../../utils/Allocation/generateColumnData.jsx";

// For Updation of total value in User Defined row after Deletion of single row
import updateColumnsForOtherUnits from "../../utils/Allocation/updateColumnsForOtherUnits.jsx";
import { updateColumnDataForUserUnits } from "../../utils/Allocation/handleSelfChange.jsx";
//import RowDeletionModal from "../../components/AvailableUnitsForAdminAllocation/RowDeletionModal.jsx";

const AllocationOfUnits = ({ idx, page, rowData, setIdx, setRadioData }) => {
  // All the data
  const [sectionData, setSectionData] = useState([]);

  //For Row Deletion Modal
  const [modalData, setModalData] = useState({
    isopen: false,
  });
  const [deleteTheRow, setDeleteTheRow] = useState(false);

  useEffect(() => {
    if (page === "userUnits") {
      setSectionData(
        rowData?.map((row) => {
          if (row.id === rowData[rowData?.length - 2].id)
            return {
              ...row,
              error: { name: false, email: false }, //if name and email input are empty then this line will assign name and email as false and we will see a error symbol
            };
          return row;
        })
      );
    } else setSectionData(rowData);
  }, [rowData, page]);

  useEffect(() => {
    if (deleteTheRow === true) {
      let rowData = modalData.rowData;
      const unitName = modalData.unitName;
      const totalLabel = modalData.totalLabel;
      const inputsLabel = modalData.inputsLabel;
      const isSelf = modalData?.isSelf; // if the row deletion is called due to change in self the only this value will exist

      let updatedSectionData = [];
      //Deleting the whole row if there are other more than one rows then deleted row else resetting the original row
      if (sectionData?.length > 3) {
        console.log(sectionData);  //show me this ..........................
        updatedSectionData = sectionData?.filter((el) => {
          if (el.id === modalData.rowData.id) return false;
          return true;
        });
      } else {
        updatedSectionData = sectionData?.map((el) => {
          if (
            el.id === modalData?.rowData?.id &&
            unitName === "organisationName"
          ) {
            rowData = {
              ...el,
              [unitName]: "",
              [inputsLabel]: 0,
            };
            return {
              ...el,
              [unitName]: "",
              [inputsLabel]: 0,
            };
          } else if (
            el.id === modalData?.rowData?.id &&
            unitName !== "organisationName"
          ) {
            const updatedUnitsObject = {
              ...rowData[unitName],
              selected: "None",
            };
            rowData = {
              ...el,
              [unitName]: updatedUnitsObject,
              [inputsLabel]: 0,
            };
            return {
              ...el,
              [unitName]: updatedUnitsObject,
              [inputsLabel]: 0,
            };
          }
          return el;
        });
      }

      setDeleteTheRow(false);

      if (unitName === "user") {
        // For updating last second row in User Units Page
        if (isSelf)
          updatedSectionData = updatedSectionData?.map((el) => {
            if (el[unitName] === "Self") {
              return {
                ...el,
                [inputsLabel]: 1,
              };
            } else if (el[unitName] === "Other")
              return {
                ...el,
                [inputsLabel]: el[inputsLabel] - 1,
                [totalLabel]: el[totalLabel] - 1,
              };
            return el;
          });
// ............................................../
        updateColumnDataForUserUnits(
          updatedSectionData,
          setSectionData,
          rowData,
          totalLabel,
          inputsLabel
        );
      } else {
        // For updation of User Defined rows value
        updateColumnsForOtherUnits(
          updatedSectionData,
          setSectionData,
          rowData,
          totalLabel,
          inputsLabel
        );
      }
    }
  }, [modalData, sectionData, deleteTheRow]);

  const handleAllocate = () => {
    const isinputsEmpty = sectionData?.some((el) => {
      if (page === "userUnits")
        return (
          (el?.name === "" || el?.email === "") &&
          (el.solo_starter_entered > 0 ||
            el.duel_dynamics_entered > 0 ||
            el.focus_five_entered > 0 ||
            el.all_available_entered > 0)
        );
      if (page === "endUserOrganizationUnits")
        return (
          el?.organisationName === "" &&
          (el.solo_starter_entered > 0 ||
            el.duel_dynamics_entered > 0 ||
            el.focus_five_entered > 0 ||
            el.all_available_entered > 0)
        );
      return false;
    });

    if (isinputsEmpty) {
      setSectionData(
        sectionData?.map((el) => {
          const inValidRow =
            (el?.name === "" || el?.email === "") &&
            (el.solo_starter_total > 0 ||
              el.duel_dynamics_total > 0 ||
              el.focus_five_total > 0 ||
              el.all_available_total > 0);

          if (inValidRow) {
            let er = el.error; //here sectionData.error is used to show error when name and email is empty
            if (el?.name === "") er = { ...er, name: true };
            if (el?.email === "") er = { ...er, email: true };

            return {
              ...el,
              error: er,
            };
          }
          return el;
        })
      );
    } else {
      sessionStorage.setItem("idx", idx + 1);
      sessionStorage.setItem(page, JSON.stringify(sectionData));
      setRadioData((radioData) => {
        const radioDataForState = radioData?.map((el) => {
          if (el.page === page) return { ...el, prescription: true };
          return el;
        });

        const sessionRadioData = JSON.parse(sessionStorage.getItem("latestRadioData"));
        if (sessionRadioData) {
          const updatedRadioData = sessionRadioData?.map((el) =>
            el.page === page ? { ...el, prescription: true } : el
          );
          sessionStorage.setItem("latestRadioData", JSON.stringify(updatedRadioData));
        } else sessionStorage.setItem("latestRadioData", JSON.stringify(radioDataForState));

        return radioDataForState;
      });

      setIdx((idx) => idx + 1);
    }
  };

  const handleGoBack = () => {
    if (idx) {
      sessionStorage.setItem("idx", idx - 1);
      setIdx((idx) => idx - 1);
    }

    if (sectionData?.length > 0) sessionStorage.setItem(page, JSON.stringify(sectionData));
  };

  let columns = generateColumnData(page, sectionData, setSectionData, setModalData, modalData);
  const columnGroupingModel = generateColumnGroupingModel();

  // For Removal of column if total is zero or undefined
  if (columns && sectionData) columns = filterColumns(columns, sectionData);

  if (
    !columns ||
    columns === "undefined" ||
    columns === null ||
    !sectionData ||
    sectionData === "undefined" ||
    sectionData === null
  ) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 4,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/*modalData.isopen && (
        <RowDeletionModal
          modalData={modalData}
          setModalData={setModalData}
          setDeleteTheRow={setDeleteTheRow}
        />
      )*/}
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            
            borderBottom: "none", // Remove the bottom border
            height: "fit-content",
          },
        }}
      >
        <Typography sx={{ marginLeft: 4, marginBottom: 1 }}>
          For the Allocation of User Units provide the user name and email
        </Typography>
        <DataGridTable
          rows={sectionData}
          columns={columns}
          page={"allocation"}
          columnGroupingModel={columnGroupingModel}
        />
      </Box>
      <Button
        disableRipple
        variant="contained"
        color="secondary"
        sx={{ marginTop: "16px", width: "250px" }}
        onClick={handleGoBack}
      >
        Go Back
      </Button>
      <Button
        disableRipple
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px", width: "250px" }}
        onClick={handleAllocate}
      >
        {page === "userUnits"
          ? "Allocate Users to Packages"
          : page === "endUserOrganizationUnits"
          ? "Allocate End User Organisations to Package"
          : page === "buyerGuideUnits"
          ? "Allocate Buyer Guides to Package"
          : "Allocate Vendors Unlocked to Packages"}
      </Button>
    </Box>
  );
};

export default AllocationOfUnits;

const filterColumns = (columns, sectionData) => {
  console.log("sectionData", sectionData[sectionData?.length - 1]);
  const filteredComun = columns?.filter((col) => {
    // Solo Starters

    if (col.field === "soloStarterFullyPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.soloStarterFullyPrescribedTotal >
        0
      );
    else if (col.field === "soloStarterPartiallyPrescribedEUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.soloStarterPartiallyPrescribedEUTotal > 0
      );
    else if (col.field === "soloStarterPartiallyPrescribedBUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.soloStarterPartiallyPrescribedBUTotal > 0
      );
    else if (col.field === "soloStarterUnPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.soloStarterUnPrescribedTotal > 0
      );
    //
    // Duel Dynamics
    //
    else if (col.field === "duelDynamicsFullyPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.duelDynamicsFullyPrescribedTotal >
        0
      );
    else if (col.field === "duelDynamicsPartiallyPrescribedEUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.duelDynamicsPartiallyPrescribedEUTotal > 0
      );
    else if (col.field === "duelDynamicsPartiallyPrescribedBUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.duelDynamicsPartiallyPrescribedBUTotal > 0
      );
    else if (col.field === "duelDynamicsUnPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.duelDynamicsUnPrescribedTotal > 0
      );
    //
    // Focus Five
    //
    else if (col.field === "focusFiveFullyPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.focusFiveFullyPrescribedTotal > 0
      );
    else if (col.field === "focusFivePartiallyPrescribedEUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.focusFivePartiallyPrescribedEUTotal > 0
      );
    else if (col.field === "focusFivePartiallyPrescribedBUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.focusFivePartiallyPrescribedBUTotal > 0
      );
    else if (col.field === "focusFiveUnPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.focusFiveUnPrescribedTotal > 0
      );
    //
    // All Available
    //
    else if (col.field === "allAvailableFullyPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.allAvailableFullyPrescribedTotal >
        0
      );
    else if (col.field === "allAvailablePartiallyPrescribedEUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.allAvailablePartiallyPrescribedEUTotal > 0
      );
    else if (col.field === "allAvailablePartiallyPrescribedBUTotal")
      return (
        sectionData[sectionData?.length - 1]
          ?.allAvailablePartiallyPrescribedBUTotal > 0
      );
    else if (col.field === "allAvailableUnPrescribedTotal")
      return (
        sectionData[sectionData?.length - 1]?.allAvailableUnPrescribedTotal > 0
      );

    //

    return true;
  });

  return filteredComun;
};

const generateColumnGroupingModel = () => {
  const columnGroupingModel = [
    {
      groupId: "user",
      headerName: "",
      headerClassName: "super-app-theme--header",
      resizable: false,
      display: "flex",
      flex: 1,

      children: [{ field: "user" }],
    },
    {
      groupId: "name",
      headerName: "",
      headerClassName: "super-app-theme--header",
      resizable: false,
      display: "flex",
      flex: 1,
      children: [{ field: "name" }],
    },
    {
      groupId: "email",
      headerName: "",
      headerClassName: "super-app-theme--header",
      resizable: false,
      display: "flex",
      flex: 1,
      children: [{ field: "email" }],
    },

    {
      groupId: "organisationName",
      headerName: "",
      headerClassName: "super-app-theme--header",
      resizable: false,
      display: "flex",
      flex: 1,
      children: [{ field: "organisationName" }],
    },

    {
      groupId: "buyerGuideName",
      headerName: "",
      headerClassName: "super-app-theme--header",
      resizable: false,
      display: "flex",
      flex: 1,
      children: [{ field: "buyerGuideName" }],
    },

    {
      groupId: "vendorName",
      headerName: "",
      headerClassName: "super-app-theme--header",
      resizable: false,
      display: "flex",
      flex: 1,
      children: [{ field: "vendorName" }],
    },

    {
      groupId: "Solo Starters",
      headerClassName: "super-app-theme--header",
      display: "flex",
      headerAlign: "center",
      flex: 1,
      children: [
        { field: "soloStarterFullyPrescribedTotal" },
        { field: "soloStarterPartiallyPrescribedEUTotal" },
        { field: "soloStarterPartiallyPrescribedBUTotal" },
        { field: "soloStarterUnPrescribedTotal" },
      ],
    },
    {
      groupId: "Duel Dynamics",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,

      children: [
        { field: "duelDynamicsFullyPrescribedTotal" },
        { field: "duelDynamicsPartiallyPrescribedEUTotal" },
        { field: "duelDynamicsPartiallyPrescribedBUTotal" },
        { field: "duelDynamicsUnPrescribedTotal" },
      ],
    },
    {
      groupId: "Focus Five",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      children: [
        { field: "focusFiveFullyPrescribedTotal" },
        { field: "focusFivePartiallyPrescribedEUTotal" },
        { field: "focusFivePartiallyPrescribedBUTotal" },
        { field: "focusFiveUnPrescribedTotal" },
      ],
    },
    {
      groupId: "All Available",
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",
      flex: 1,
      children: [
        { field: "allAvailableFullyPrescribedTotal" },
        { field: "allAvailablePartiallyPrescribedEUTotal" },
        { field: "allAvailablePartiallyPrescribedBUTotal" },
        { field: "allAvailableUnPrescribedTotal" },
      ],
    },
  ];

  return columnGroupingModel;
};
