import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {   Popover } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import NumberInputAvailableUnitsOfAllocation from '../../components/AvailableUnitsForAdminAllocation/NumberInputAvailableUnitsOfAllocation.jsx'
// import NumberInput from '@mui/material/NumberInput'; 
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import theme from "../../theme/theme.js";

import DataGridTable from "../../components/DataGridTable.jsx";
import CustomizeRadio from "../../components/AvailableUnitsForAdminAllocation/CustomizeRadio.jsx";

import { UserProfileContext } from "../../context/UserContext";
// import { COLUMNS_DIMENSION_PROPERTIES } from "@mui/x-data-grid/internals";

const AvailableUnitsForAdminAllocation = () => {
  const {
    userProfile,
    // availableUnitsForAllocation
  } = useContext(UserProfileContext);
  const [warningIds, setWarningIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // Popover state

  // Popover handlers
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);
 
  // console.log("availableUnitsForAllocation", availableUnitsForAllocation);

  // DATA
  const availableUnitsForAllocation = [
    {
      id: 883,
      purchasedPackageName: "Solo Starter",
      allocationType: "Fully Prescribed",
      userUnits: 7,
      endUserOrganizationUnits: 12,
      buyerGuideUnits: 54,
      totalUnits: 4536,
    },
    {
      id: 88993,
      purchasedPackageName: "Solo Starter",
      allocationType: "UnPrescribed",
      userUnits: null,
      endUserOrganizationUnits: null,
      buyerGuideUnits: null,
      totalUnits: 45,
    },
    {
      id: 88993,
      purchasedPackageName: "Solo Starter",
      allocationType: "Partially Prescribed (End-User)",
      userUnits: null,
      endUserOrganizationUnits: 5,
      buyerGuideUnits: null,
      totalUnits: 45,
    },
    {
      id: 81215445,
      purchasedPackageName: "Solo Starter",
      allocationType: "Partially Prescribed (Buyer Guide)",
      userUnits: null,
      endUserOrganizationUnits: null,
      buyerGuideUnits: 3,
      totalUnits: 45,
    },
    {
      id: 1231233,
      purchasedPackageName: "Duel Dynamics",
      allocationType: "Fully Prescribed",
      userUnits: 84,
      endUserOrganizationUnits: 34,
      buyerGuideUnits: 98,
      totalUnits: 98,
    },

    {
      id: 445,
      purchasedPackageName: "Duel Dynamics",
      allocationType: "Partially Prescribed (Buyer Guide)",
      userUnits: null,
      endUserOrganizationUnits: null,
      buyerGuideUnits: 3,
      totalUnits: 45,
    },

    {
      id: 5678,
      purchasedPackageName: "Focus Five",
      allocationType: "UnPrescribed",
      userUnits: null,
      endUserOrganizationUnits: null,
      buyerGuideUnits: null,
      totalUnits: 10,
    },
    {
      id: 56334,
      purchasedPackageName: "All Available",
      allocationType: "UnPrescribed",
      userUnits: null,
      endUserOrganizationUnits: null,
      buyerGuideUnits: null,
      totalUnits: 89,
    },
  ];

  // Stores the data for Radio buttons and selected values
  const [selectedRadioValue, setSelectedRadioValue] = useState([
    { page: "userUnits", value: "self", prescription: false },
    {
      page: "endUserOrganizationUnits",
      value: "userDefined",
      prescription: false,
    },
    {
      page: "buyerGuideUnits",
      value: "userDefined",
      prescription: false,
    },
    {
      page: "unlockedVendors",
      value: "userDefined",
      prescription: false,
    },
  ]);

  //Radio Buttons All the data is stored here
  const radioData = [
    {
      heading: "Users Units",
      option1: "self",
      text1: "Self (max 1 unit)",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
    {
      heading: "End User Organizations Units",
      option1: "userDefined",
      text1: "User Defined",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
    {
      heading: "Buyer Guide Units",
      option1: "userDefined",
      text1: "User Defined",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
    {
      heading: "Vendor to be Unlocked",
      option1: "userDefined",
      text1: "User Defined",
      option2: "prescibe",
      text2: "Let me prescribe",
    },
  ]?.filter((idx) => {
    if (idx === 1 && userProfile.profileType !== "consultant") return false;
    return true;
  });
  // console.log("availableUnitsForAllocation", availableUnitsForAllocation);

  //Radio Buttons All the data is stored here

  const [loading, setLoading] = useState(true); // Add loading state

  // All the data
  const [sectionData, setSectionData] = useState([]);

  // Selection of Guides in "Checkbox"
  const [packageCheck, setPackageCheck] = useState([]);
  const [allPackageCheck, setAllPackageCheck] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // checks if there is "availableUnitsForAllocation" in session if yes then tries to store the earlier changed values to current availableUnitsForAllocation
    const availableUnitsForAllocationInSession = JSON.parse(
      sessionStorage.getItem("availableUnitsForAllocation")
    );

    const selectedRadioValueFromSession = JSON.parse(sessionStorage.getItem("radioData"));

    if (
      availableUnitsForAllocationInSession?.length > 0 &&
      availableUnitsForAllocation?.length > 0
    ) {
      const availableUnitsForAllocationInCorrectedForm = availableUnitsForAllocation?.map(
        (packag) => ({
          id: Math.floor(Math.random() * 10000), // max limit of id is 1000
          purchasedPackageName: packag.purchasedPackageName,
          allocationType: packag.allocationType,
          userUnitsTotal: packag.userUnits,
          endUserOrganizationUnitsTotal: packag.endUserOrganizationUnits,
          buyerGuideUnitsTotal: packag.buyerGuideUnits,
          totalUnits: packag.totalUnits,

          userUnitsInput: 0,
          endUserOrganizationUnitsInput: 0,
          buyerGuideUnitsInput: 0,
          totalUnitsInput: 0,

          // 'datatestid':Math.floor(Math.random() * 10000),
          errorInputsLabel: null,
        }));

      const mergedAvailableUnitsForAllocation = availableUnitsForAllocationInCorrectedForm?.map(
        (row1) => {
          const updatedAvailable = availableUnitsForAllocationInSession?.find(
            (row2) =>
              row1.purchasedPackageName === row2.purchasedPackageName &&
              row1.allocationType === row2.allocationType
          );
          if (updatedAvailable) return updatedAvailable;
          return row1;
        }
      );

      if (selectedRadioValueFromSession?.length > 0) {
        setSelectedRadioValue(selectedRadioValueFromSession);
      }
      setSectionData(mergedAvailableUnitsForAllocation);
      setLoading(false); // Set loading to false once data is loaded
    } else if (availableUnitsForAllocation?.length > 0) {
      const availableUnitsForAllocationLatest =
        availableUnitsForAllocation?.map((packag) => ({
          id: Math.floor(Math.random() * 10000), // max limit of id is 1000
          purchasedPackageName: packag.purchasedPackageName,
          allocationType: packag.allocationType,
          userUnitsTotal: packag.userUnits,
          endUserOrganizationUnitsTotal: packag.endUserOrganizationUnits,
          buyerGuideUnitsTotal: packag.buyerGuideUnits,
          totalUnits: packag.totalUnits,

          userUnitsInput: 0,
          endUserOrganizationUnitsInput: 0,
          buyerGuideUnitsInput: 0,
          totalUnitsInput: 0,
          // 'datatestid':Math.floor(Math.random() * 10000),
          errorInputsLabel: null,
        }));

      setSectionData(availableUnitsForAllocationLatest);

      setLoading(false); // Set loading to false once data is loaded
    }
    // return () => {
    //   sessionStorage.removeItem("availableUnitsForAllocation");
    //   sessionStorage.removeItem("radioData");
    // };
    // }, [availableUnitsForAllocation]); // when uncomment remove the below line as well
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
console.log(sectionData);
  useEffect(() => {
    const packagToBeChecked = sectionData?.filter((e1) => {
      // (el) => el.totalUnitsInput > 0
      // (e1)=> e1.totalUnitsInput > 0 && e1.buyerGuideUnitsTotal*e1.userUnitsTotal*e1.endUserOrganizationUnitsTotal===e1.totalUnitsInput
      const buyerGuideUnits = e1.buyerGuideUnitsTotal === null ?  (e1.buyerGuideUnitsInput !== 0 ? e1.buyerGuideUnitsInput : 1) : e1.buyerGuideUnitsTotal;
  const userUnits = e1.userUnitsTotal === null ?  (e1.userUnitsInput !== 0 ? e1.userUnitsInput : 1) : e1.userUnitsTotal;
  const endUserOrgUnits = e1.endUserOrganizationUnitsTotal === null ?  (e1.endUserOrganizationUnitsInput !== 0 ? e1.endUserOrganizationUnitsInput: 1) : e1.endUserOrganizationUnitsTotal;
  const factorVerify = (e1.endUserOrganizationUnitsInput === 0 ? 1: e1.endUserOrganizationUnitsInput)*(e1.buyerGuideUnitsInput === 0 ? 1: e1.buyerGuideUnitsInput)*(e1.userUnitsInput === 0 ? 1: e1.userUnitsInput);
  // Apply the condition to filter
  console.log(factorVerify + "this si a")
  return e1.totalUnitsInput > 0 && (e1.totalUnitsInput%factorVerify===0) && (buyerGuideUnits * userUnits * endUserOrgUnits === e1.totalUnitsInput);
  });

    setPackageCheck(packagToBeChecked?.map((el) => el.id));

    let sumOfUserUnits = 0;
    sectionData?.forEach((el) => (sumOfUserUnits += el.userUnitsInput));

    if (sumOfUserUnits)
      setSelectedRadioValue(
        selectedRadioValue?.map((el) => {
          if (el.page === "userUnits")
            return { ...el, value: sumOfUserUnits > 1 ? "prescibe" : "self" };
          return el;
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionData]);

  useEffect(() => {
    const allChecked =
      sectionData?.length > 0 && sectionData.every((packag) => packageCheck?.includes(packag.id));

    setAllPackageCheck(allChecked);
  }, [packageCheck, sectionData]);

  const handleAllocateQuantitiesAndContinue = () => {
    const sectionDataChecked = sectionData?.filter((el) =>
      packageCheck?.includes(el.id)
    );

    console.log("packageCheck", packageCheck);
    console.log("selectedRadioValue", selectedRadioValue);

    sessionStorage.removeItem("idx");
    sessionStorage.removeItem("latestRadioData");

    if (sectionDataChecked?.length > 0) {
      sessionStorage.setItem("radioData", JSON.stringify(selectedRadioValue));

      sessionStorage.setItem(
        "availableUnitsForAllocation",
        JSON.stringify(sectionDataChecked)
      );

      navigate("/admin-allocation");
    }
  };

  const updatedColumn = columnData(
    sectionData,
    setSectionData,
    allPackageCheck,
    setAllPackageCheck,
    packageCheck,
    setPackageCheck,
    userProfile,
    warningIds,
    setWarningIds,
    anchorEl, // Add anchorEl as a parameter
  handlePopoverOpen, // Add handlePopoverOpen as a parameter
  handlePopoverClose, // Add handlePopoverClose as a parameter
  isPopoverOpen // Add isPopoverOpen as a parameter
  );

  if (loading) {
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 4,
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,
            
            borderBottom: "none", // Remove the bottom border
            height: "fit-content",
          },
        }}
      >
        <DataGridTable
          rows={sectionData}
          columns={updatedColumn}
          page={"allocation"}
          // getRowClassName={(params) => `row-${params.row.datatestid}`}
        />
      </Box>
      <Typography
        data-test-id="I want to allocate"
        sx={{ marginTop: "20px", fontFamily: theme.typography.body1 }}
      >
        I want to allocate
      </Typography>

      {radioData?.map((data, index) => (
        <CustomizeRadio
          key={index}
          data={data}
          index={index}
          selectedRadioValue={selectedRadioValue}
          setSelectedRadioValue={setSelectedRadioValue}
        />
      ))}

      {/* button  */}
      <Button
        data-test-id="Allocate Quantities & Continue"
        disableRipple
        variant="contained"
        color="primary"
        sx={{ marginTop: "16px", width: "255px" }}
        onClick={handleAllocateQuantitiesAndContinue}
      >
        Allocate Quantities & Continue
      </Button>
    </Box>
  );
};

export default AvailableUnitsForAdminAllocation;

const columnData = (
  sectionData,
  setSectionData,
  allPackageCheck,
  setAllPackageCheck,
  packageCheck,
  setPackageCheck,
  userProfile,
  warningIds,
  setWarningIds,
  anchorEl,
  handlePopoverOpen,
  handlePopoverClose, 
  isPopoverOpen 
) => {

  const handleCheckboxChange = (id) => {
    setPackageCheck((prevState) =>
      // console.log(prevState);
      prevState?.includes(id)
        ? prevState?.filter((guideId) => guideId !== id)
        : [...prevState, id]
    );
  };

  const getValidPackages = (packag) => {
    const buyerGuideUnits = packag?.buyerGuideUnitsTotal === null
      ? (packag?.buyerGuideUnitsInput !== 0 ? packag?.buyerGuideUnitsInput : 1)
      : packag?.buyerGuideUnitsTotal;
  
    const userUnits = packag?.userUnitsTotal === null
      ? (packag?.userUnitsInput !== 0 ? packag?.userUnitsInput : 1)
      : packag?.userUnitsTotal;
  
    const endUserOrgUnits = packag?.endUserOrganizationUnitsTotal === null
      ? (packag?.endUserOrganizationUnitsInput !== 0 ? packag?.endUserOrganizationUnitsInput : 1)
      : packag?.endUserOrganizationUnitsTotal;
  
    const factorVerify = 
      (packag?.endUserOrganizationUnitsInput === 0 ? 1 : packag?.endUserOrganizationUnitsInput) *
      (packag?.buyerGuideUnitsInput === 0 ? 1 : packag?.buyerGuideUnitsInput) *
      (packag?.userUnitsInput === 0 ? 1 : packag?.userUnitsInput);
  
    return (
      packag?.totalUnitsInput > 0 &&
      (packag?.totalUnitsInput % factorVerify === 0) &&
      (buyerGuideUnits * userUnits * endUserOrgUnits === packag?.totalUnitsInput)
    );
  };
 
  const handleCheckboxClick = (id) => {
    const selectedPackage = sectionData.find((packag) => packag.id === id);
  
    if (getValidPackages(selectedPackage)) {
      // If the condition is met, proceed with the checkbox action
      handleCheckboxChange(id);
      // Remove warning if present
      setWarningIds((prev) => prev.filter((warnId) => warnId !== id));
    } else {
      // If the condition is not met, show a warning
      setWarningIds((prev) => [...prev, id]);
    }
  };
  
  const handleSelectAllChange = () => {
    const validPackages = sectionData
      ?.filter(getValidPackages) 
      .map((packag) => packag.id);
  
    const areAllValidPackagesSelected = validPackages.every((id) =>
      packageCheck.includes(id)
    );
  
    if (areAllValidPackagesSelected) {
      const remainingSelected = packageCheck.filter((id) => !validPackages.includes(id));
      setPackageCheck(remainingSelected);
    } else {
      const newSelected = [...new Set([...packageCheck, ...validPackages])];
      setPackageCheck(newSelected);
    }
    setAllPackageCheck(!areAllValidPackagesSelected); // Toggle the "all selected" state
  };
  

  const NumberInputRenderCell = ({ params, inputsLabel }) => {
    const max = getUpdatedMax(params?.value, params?.row, inputsLabel);
    const isDisabled =
      params?.row.errorInputsLabel !== null &&
      params?.row.errorInputsLabel !== inputsLabel &&
      inputsLabel !== "totalUnitsInput";

    return (
      <NumberInputAvailableUnitsOfAllocation
        id={`${params?.row.purchasedPackageName}-${params?.row.allocationType}-${params?.field}`}
        max={max}
        rowData={params?.row}
        inputsLabel={inputsLabel}
        isDisabled={isDisabled}
        totalLabel={params?.field}
        page={"avalable_for_allocation"}
        onSet={() => {
          if (inputsLabel === "totalUnitsInput") {
            if (params.row[inputsLabel] > 0) {
              params.row["userUnitsInput"] = 1;
              params.row["endUserOrganizationUnitsInput"] = 0;
              params.row["buyerGuideUnitsInput"] = 0;
            } else {
              params.row["userUnitsInput"] = 0;
              params.row["endUserOrganizationUnitsInput"] = 0;
              params.row["buyerGuideUnitsInput"] = 0;
            }
          }
          
          setWarningIds((prev) => prev.filter((warnId) => warnId !== params.row.id))
          setSectionData(
            sectionData?.map((packag) => {
              if (packag?.id === params?.row.id) return params?.row;
              return packag;
            })
          );
        }}
      />
    );
  };


  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 90,
      sortable: false,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      display: "flex",

      flex: 0.5,
      renderHeader: () => (
        <Checkbox
          checked={allPackageCheck}
          onChange={handleSelectAllChange}
          color="secondary"
          sx={{ color: "white" }}
        />
      ),

      renderCell: (params) => {
        console.log(params);
        const hasWarning = warningIds.includes(params?.row.id);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={packageCheck?.includes(params?.row.id)}
              onChange={() => handleCheckboxClick(params?.row.id)}
              color="secondary"
            />
            {hasWarning && (
              <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                <WarningAmberRoundedIcon
                  data-test-id="warning"
                  color="warning"
                  style={{ marginLeft: "8px", cursor: "pointer" }}
                />
                <Popover
                  open={isPopoverOpen}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                    <Typography variant="body2" 
                    sx={{ color: "white", background: theme.palette.secondary.main ,padding: 0.5}}
                    >
                      The total units input is invalid or not equal to the product of the inputs.
                    </Typography>
                </Popover>
              </Box>
            )}
          </Box>
        );
      },
    },

    {
      field: "purchasedPackageName",
      headerName: "Purchased Packages",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
    },

    {
      field: "allocationType",
      headerName: "Allocation Type",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
    },

    {
      field: "userUnitsTotal",
      headerName: "User Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <NumberInputRenderCell  params={params} inputsLabel={"userUnitsInput"} />
      ),
    },

    {
      field: "endUserOrganizationUnitsTotal",
      headerName: "End-User Organization Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      renderCell: (params) => (
        <NumberInputRenderCell
          params={params}
          inputsLabel={"endUserOrganizationUnitsInput"}
        />
      ),
    },

    {
      field: "buyerGuideUnitsTotal",
      headerName: "Buyer Guide Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,

      renderCell: (params) => (
        <NumberInputRenderCell
          params={params}
          inputsLabel={"buyerGuideUnitsInput"}
        />
      ),
    },

    {
      field: "totalUnits",
      headerName: "Total Units",
      headerClassName: "super-app-theme--header",
      display: "flex",
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <span>
      //       {params.row.totalUnitsInput} /{params.value}
      //     </span>
      //   );
      // },
      renderCell: (params) => (
        <NumberInputRenderCell
          params={params}
          inputsLabel={"totalUnitsInput"}
        />
      ),
    },
  ];

  const updatedColumn = columns?.filter((el, idx) => {
    if (
      el.field === "endUserOrganizationUnitsTotal" &&
      userProfile.profileType !== "consultant"
    )
      return false;
    return true;
  });

  return updatedColumn;
};

const getUpdatedMax = (totalValue, row, inputsLabel) => {
  if (row?.allocationType === "UnPrescribed" && totalValue === null) {
    let product = 1;

    product *=
      inputsLabel !== "userUnitsInput" && row["userUnitsInput"] !== 0
        ? row["userUnitsInput"]
        : 1;
    product *=
      inputsLabel !== "endUserOrganizationUnitsInput" &&
      row["endUserOrganizationUnitsInput"] !== 0
        ? row["endUserOrganizationUnitsInput"]
        : 1;
    product *=
      inputsLabel !== "buyerGuideUnitsInput" &&
      row["buyerGuideUnitsInput"] !== 0
        ? row["buyerGuideUnitsInput"]
        : 1;

    const max = Math.floor(row["totalUnitsInput"] / product);
    const currValue = row[inputsLabel];

    return max < currValue ? currValue : max;
  } else if (
    row?.allocationType === "Partially Prescribed (End-User)" ||
    row?.allocationType === "Partially Prescribed (Buyer Guide)"
  ) {
    if (totalValue === null) {
      let product = 1;

      product *=
        row["userUnitsTotal"] !== null
          ? row["userUnitsTotal"]
          : inputsLabel !== "userUnitsInput" && row["userUnitsInput"] !== 0
          ? row["userUnitsInput"]
          : 1;
      product *=
        row["endUserOrganizationUnitsTotal"] !== null
          ? row["endUserOrganizationUnitsTotal"]
          : inputsLabel !== "endUserOrganizationUnitsInput" &&
            row["endUserOrganizationUnitsInput"] !== 0
          ? row["endUserOrganizationUnitsInput"]
          : 1;

      product *=
        row["buyerGuideUnitsTotal"] !== null
          ? row["buyerGuideUnitsTotal"]
          : inputsLabel !== "buyerGuideUnitsInput" &&
            row["buyerGuideUnitsInput"] !== 0
          ? row["buyerGuideUnitsInput"]
          : 1;

      const max = Math.floor(row["totalUnitsInput"] / product);
      const currValue = row[inputsLabel];
      const updatedMax = max < currValue ? currValue : max;
      return updatedMax;
    } else if (inputsLabel !== "totalUnitsInput") {
      const updatedMax = Math.min(totalValue, row["totalUnitsInput"]);
      return updatedMax;
    }
  }
  return totalValue;
};
