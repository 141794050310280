import React, { useCallback, useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import theme from "../theme/theme";
import { useGridApiRef } from "@mui/x-data-grid";

// Modify columns to set center alignment for cell values

const DataGridTable = ({
  name,
  columns,
  rows,
  menu,
  page,
  columnGroupingModel,
  setFilterData = () => {},
  flag,
}) => {
  const centeredColumns = useMemo(
    () =>
      columns?.map((column) => ({
        ...column,
        headerAlign: "center", // center header
      })),
    [columns]
  );
  const apiRef = useGridApiRef();

  const handleStateChange = useCallback(
    (state) => {
      const filteredKeys = Object.keys(state?.filter?.filteredRowsLookup)?.filter(
        (key) => state?.filter?.filteredRowsLookup[key] === true
      );

      const rows = filteredKeys.map((key) => apiRef.current?.getRow(key));
      const transformedRows = rows.map((row) => {
        return flag
          ? [
              row.column0,
              row.column1,
              row.column3 === "Overall Use Cases" ? "overall" : "mandatory", // Status
              row.column2,
              row.column3,
              row.column4,
              row.column5,
              row.column6,
              row.column7,
              row.column8,
            ]
          : [
              row.column0, // Vendor Name
              row.column1, // Offer Name
              row.column2 === "Overall Use Cases" ? "overall" : "mandatory", // Status
              row.column2,
              `${row.column3}%`, // Offer Score with percentage
              `${row.column4}%`, // Offer Relevance with percentage
              `${row.column5}%`, // Offer Utilization with percentage
              row.column6, // offerId
              row.column7, // vendorId
            ];
      });
      const header = [
        "Vendor Name",
        "Status",
        "Offer Name",
        "Mandatory Status",
        "Offer Score",
        "Offer Relevance",
        "Offer Utilization",
      ];

      const newData = [header, ...transformedRows];

      setFilterData((prevData) =>
        JSON.stringify(prevData) === JSON.stringify(newData)
          ? prevData // No change, don't trigger re-render
          : newData
      );

      return rows;
    },
    [apiRef, flag, setFilterData]
  );

  const formatValue = (value) => {
    if (typeof value === "number") {
      const decimalPlaces = (value.toString().split(".")[1] || "").length;
      if (decimalPlaces === 0 || decimalPlaces === 1) {
        return value;
      } else {
        return value.toFixed(2);
      }
    }
    return value;
  };

  // Safeguard to ensure `rows` is an array and not boolean
  const formattedRows =
    Array.isArray(rows) && rows.length > 0
      ? rows.map((row) => {
          const newRow = { ...row };
          Object.keys(newRow).forEach((key) => {
            newRow[key] = formatValue(newRow[key]);
          });
          return newRow;
        })
      : []; // Fallback to empty array

  return (
    <Box
      data-test-id={`table-${name}`}
      sx={{
        overflow: "auto",
        "& .super-app-theme--header": {
          backgroundColor: theme.palette.primary.main,
          border: "0.5px solid white",
          color: "white",
        },
      }}
      style={{
        height: formattedRows.length >= 6 ? 480 : "auto",
        width:
          page === "allocation"
            ? centeredColumns?.length === 2
              ? "40%"
              : centeredColumns?.length === 3
              ? "60%"
              : centeredColumns?.length === 4
              ? "80%"
              : "100%"
            : "100%",
      }}
    >
      <DataGrid
        apiRef={apiRef}
        onStateChange={handleStateChange}
        rows={formattedRows}
        columns={centeredColumns}
        columnGroupingModel={columnGroupingModel}
        disableColumnSorting
        disableRowSelectionOnClick
        hideFooter
        getRowHeight={() => 70}
        scrollbarSize={0}
        rowBufferPx={20}
        sx={{
          minWidth: "100%",
          height: "100%",
          border: "none",

          // borderBottom:"0.5px solid gray ",
          "& .MuiDataGrid-cell:hover": {
            cursor: "default",
          },

          "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },

          " .MuiDataGrid-withBorderColor ": {
            borderBottom: "none !important",
            borderTop: "none !important",
            // border: "none !important",
          },

          "& .MuiDataGrid-columnSeparator:not(.MuiDataGrid-columnSeparator--resizable)": {
            display: "none !important",
          },

          ".MuiDataGrid-iconButtonContainer": {
            // marginLeft: "10px !important",
            right: 15,
            position: "absolute",
          },
          "& .MuiDataGrid-sortIcon": {
            color: "white",
          },
          " .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root": {
            color: "white ",
          },
        }}
      />
    </Box>
  );
};
export default DataGridTable;
