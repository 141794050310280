import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import * as d3 from "d3";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  MoreVert as MoreVertIcon,
  ContentCopy as CopyIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import "./TreeComponent.css";
import { Menu, IconButton, Tooltip, Typography } from "@mui/material"; 




const keyMappings = {
  name: "Name",
  description: "Description",
  source: "Source",
  accepted_by: "Accepted By",
  totalCountOfMappings: "Total Mappings", 
};

const renderStyledJson = (json) => {

  const filteredKeys = Object.keys(json).filter((key) => key !== 'element_id');

  return (
    <pre style={{ fontSize: '16px', lineHeight: '1.5', fontFamily: "'Roboto', sans-serif", whiteSpace: 'normal', wordWrap: 'break-word' }}>
      {filteredKeys.map((key) => (
        <div key={key} style={{ marginBottom: '10px' }}>
          <span style={{ fontWeight: 'bold', color: '#000000' }}>
            {keyMappings[key] || key}: 
          </span>
          <span style={{ color: '#000000' }}>{JSON.stringify(json[key], null, 2)}</span>
        </div>
      ))}
    </pre>
  );
};


const TreeComponent = ({ data, onUpdateJson, onNodeClick }) => {
  const treeRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null); 
  const [selectedNodeData, setSelectedNodeData] = useState(null); 

  const handleClick = (event, nodeData) => {
    const { children, ...nodeDataWithoutChildren } = nodeData.data;

    setAnchorEl(event.currentTarget); 
    setSelectedNodeData(nodeDataWithoutChildren); 
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedNodeData(null);
  };


  const handleCopy = () => {
    if (selectedNodeData) {
      const { children, ...nodeDataWithoutChildren } = selectedNodeData;
      const textToCopy = JSON.stringify(nodeDataWithoutChildren, null, 2);
      navigator.clipboard.writeText(textToCopy);
    }
  };



  useEffect(() => {
    if (!data) return;
    let selectedNode = null;    
    localStorage.setItem('buyerGuideElementId', data[0].element_id);
    localStorage.setItem('featureId', data[0].offerId);
    const savedPath = JSON.parse(localStorage.getItem("selectedNodePath"));

    const findChildren = (node) => {
      if (!node || typeof node !== 'object') return null;
      const children = node["Use Case"] || node["Features"];

      return {
        name: node?.buyerGuideName || node?.useCaseName || node.featureName || node.offerName,
        element_id: node?.buyerGuideId || node?.useCaseId || node?.featureId || node.offerId,
        description: node?.buyerGuideDescription || node?.useCaseDescription || node.description || '',
        source: node?.buyerGuideSource || node?.useCaseSource || node.source || '',
        accepted_by: node.acceptedBy || [],
        ...(typeof node?.totalCountOfMappings === 'number' && { totalCountOfMappings: node?.totalCountOfMappings }),
        children: Array.isArray(children) ? children?.map(findChildren) : []

      };
    };

    const rootData = data.map(findChildren);
    const root = d3.hierarchy(rootData[0]);
if (root.children && root.children.length > 0) {
  root.children.forEach(collapse);
}

    const findNodeByElementId = (node, elementId) => {
      if (!node || typeof node !== 'object') {
        console.warn(`Invalid node encountered. Node: ${JSON.stringify(node)}`);
        return null;
      }


      if (node.data.element_id === elementId) {
        return node;
      }

      if (Array.isArray(node.children)) {
        for (let child of node.children) {
          const result = findNodeByElementId(child, elementId);
          if (result) return result;
        }
      }

      return null;
    };

    const expandTreeToSavedPath = (rootData, savedPath) => {
      let currentNode = rootData;

      if (!Array.isArray(savedPath) || savedPath.length === 0) {
        console.warn("Invalid or empty savedPath array. Cannot traverse.");
        return;
      }

      for (let i = 0; i < savedPath.length; i++) {
        const { element_id } = savedPath[i];

        if (!element_id) {
          console.warn(`Invalid element_id at path index ${i}: ${element_id}`);
          break;
        }

        if (!currentNode) {
          console.warn(`Current node is undefined at path index ${i}. Element ID: ${element_id}`);
          break;
        }

        const nextNode = findNodeByElementId(currentNode, element_id);

        if (!nextNode) {
          console.error(`Node with element_id ${element_id} not found in the tree.`);
          break;
        }

        currentNode = nextNode;
        if (currentNode && currentNode._children) {
          currentNode.children = currentNode._children;
          currentNode._children = null;
        }
      }

      console.log("Final expanded node in path:", currentNode);
    };


    if (savedPath) {
      expandTreeToSavedPath(root, savedPath);
    }


    const margin = { top: 80, right: 0, bottom: 0, left: 120 },
      width = treeRef.current.clientWidth - margin.left - margin.right,
      height = treeRef.current.clientHeight - margin.top - margin.bottom;

    const treeLayout = d3.tree().size([height, width]);
    treeLayout(root);

    const rectWidth = 490;
    const verticalSpacing = 165;
    const iconSize = 25; 
    const iconPadding = 8; 
    const iconSpacing = iconSize + iconPadding; 
    const numberOfIcons = 3;
    const textVerticalPadding = 25;

    d3.select(treeRef.current).selectAll("*").remove();

    const svg = d3
      .select(treeRef.current)
      .append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .call(
        d3.zoom().on("zoom", function (event) {
          svg.attr("transform", event.transform);
        })
      )
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const node = svg
      .selectAll(".node")
      .data(root.descendants())
      .enter()
      .append("g")
      .attr("class", "node")
      .attr("transform", (d) => `translate(${d.y},${d.x})`);

    node
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", rectWidth)
      .attr("height", (d) => {
        d.height = calculateHeight(
          d.data.name || d.data.featureName,
          textVerticalPadding,
          rectWidth,
          iconSpacing,
          numberOfIcons,
          iconPadding
        );
        return d.height;
      })
      .attr("rx", 15)
      .attr("ry", 15)
      .attr("fill", (d) =>
        typeof d.data.totalCountOfMappings === "number" && d.data.totalCountOfMappings > 0
          ? "#D5EDEC"  
          : "#fff"     
      );


    appendStyledText(
      node,
      rectWidth,
      textVerticalPadding,
      iconSpacing,
      numberOfIcons,
      iconPadding
    );


    node
      .append("foreignObject")
      .attr("x", rectWidth - iconSpacing * 2.5)
      .attr("y", (d) => d.height / 2 - iconSize / 2) 
      .attr("width", iconSize)
      .attr("height", iconSize)
      .append("xhtml:div")
      .attr("class", "toggle-icon")
      .style("cursor", "pointer")
      .each(function (d) {
        ReactDOM.render(
          d.children || d._children ? (
            d.children ? (
              <RemoveCircleOutline
                onClick={(event) => {
                  event.stopPropagation();
                  toggle(d);
                  updateTree(d);
                }}
              />
            ) : (
              <AddCircleOutline
                onClick={(event) => {
                  event.stopPropagation();
                  toggle(d);
                  updateTree(d);
                }}
              />
            )
          ) : null,
          this
        );
      });


    node.each(function (d) {
      if (d.children || d._children) {
        d3.select(this)
          .append("foreignObject")
          .attr("x", rectWidth - iconSpacing * 1.5)
          .attr("y", (d) => d.height / 2 - iconSize / 2) 
          .attr("width", iconSize * 2)
          .attr("height", iconSize)
          .append("xhtml:div")
          .attr("class", "expand-all-icon")
          .style("cursor", "pointer")
          .each(function (d) {
            ReactDOM.render(
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  expandAll(d);
                  updateTree(d);
                }}
              >
                ++
              </span>,
              this
            );
          });
      }
    });


    node
      .append("foreignObject")
      .attr("x", 460) 
      .attr("width", 30)
      .attr("y", (d) => d.height / 2 - iconSize / 2) 

      .attr("height", iconSize)
      .append("xhtml:div")
      .attr("class", "more-icon")
      .style("cursor", "pointer")
      .each(function (d) {
        ReactDOM.render(
          <MoreVertIcon
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event, d);
            }}
          />,
          this
        );
      });

    let index = 0;
    let previousHeight = 0;

    function setNodeX(d) {
      if (d.children) {
        d.children.forEach(setNodeX);
      }


      d.x = previousHeight + index * verticalSpacing;
      previousHeight += d.height;
      index++;
    }

    setNodeX(root);

    function centerNode(d) {
      if (d.children) {
        const firstChild = d.children[0];
        const lastChild = d.children[d.children.length - 1];
        d.x = (firstChild.x + lastChild.x) / 2;
      }
    }

    function centerAllNodes(d) {
      if (d.children) {
        d.children.forEach(centerAllNodes);
        centerNode(d);
      }
    }

    centerAllNodes(root);


    function toggle(d) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        if (d._children) {
          d.children = d._children;
          d._children = null;
        }
      }
    }

    function expandAll(d) {
      if (d._children) {
        d.children = d._children;
        d._children = null;
      }
      if (d.children) {
        d.children.forEach(expandAll);
      }
    }


    function updateTree(source) {
      const nodes = root.descendants();
      const links = root.descendants().slice(1);

      treeLayout(root);



      nodes.forEach((d) => {
        const rootSpacing = 800;  
        const childSpacing = 800; 

        d.y = d.depth === 1
          ? d.depth * rootSpacing 
          : d.depth * childSpacing; 
      });
      let i = 0;

      const node = svg
        .selectAll("g.node")
        .data(nodes, (d) => d.id || (d.id = ++i));


      const nodeEnter = node.enter().append("g")
        .attr("class", "node")
        .attr("transform", d => `translate(${source.y0},${source.x0})`)
        .on("click", (event, d) => {
          toggle(d);
          updateTree(d);
        });


      nodeEnter.append("rect")
        .style("stroke-width", "2px")
        .attr("width", rectWidth)
        .attr("height", d => {
          d.height = calculateHeight(d.data.name || d.data.featureName, verticalSpacing, rectWidth, iconSpacing, numberOfIcons, iconPadding);
          return d.height;
        })
        .attr("rx", 15)
        .attr("ry", 15)
        .attr("fill", (d) =>
          typeof d.data.totalCountOfMappings === "number" && d.data.totalCountOfMappings > 0
            ? "#D5EDEC"  
            : "#fff"     
        );


      nodeEnter.append("text")
        .attr("dx", 10)
        .attr("dy", 20)
        .style("stroke-width", "2px")
        .text(d => d.data.name || d.data.featureName);

      nodeEnter
        .append("rect")
        .attr("width", rectWidth)
        .attr("height", (d) => {
          d.height = calculateHeight(
            d.data.name,
            verticalSpacing,
            rectWidth,
            iconSpacing,
            numberOfIcons,
            iconPadding
          ) - 70;
          return d.height - 50;
        })
        .attr("x", 0) 
        .attr("y", 0)
        .attr("rx", 15)
        .attr("ry", 15)
        .attr("fill", (d) =>
          typeof d.data.totalCountOfMappings === "number" && d.data.totalCountOfMappings > 0
            ? "#D5EDEC"  
            : "#fff"     
        );

      appendStyledText(
        nodeEnter,
        rectWidth,
        textVerticalPadding,
        iconSpacing,
        numberOfIcons,
        iconPadding
      );


      nodeEnter
        .append("foreignObject")
        .attr("x", rectWidth - iconSpacing * 2.5)
        .attr("y", (d) => d.height / 2 - iconSize / 2) 
        .attr("width", iconSize)
        .attr("height", iconSize)
        .append("xhtml:div")
        .attr("class", "toggle-icon")
        .style("cursor", "pointer")
        .each(function (d) {
          ReactDOM.render(
            d.children || d._children ? (
              d.children ? (
                <RemoveCircleOutline
                  onClick={(event) => {
                    event.stopPropagation();
                    toggle(d);
                    updateTree(d);
                  }}
                />
              ) : (
                <AddCircleOutline
                  onClick={(event) => {
                    event.stopPropagation();
                    toggle(d);
                    updateTree(d);
                  }}
                />
              )
            ) : null,
            this
          );
        });


      nodeEnter.each(function (d) {
        if (d.children || d._children) {
          d3.select(this)
            .append("foreignObject")
            .attr("x", rectWidth - iconSpacing * 1.5)
            .attr("y", (d) => d.height / 2 - iconSize / 2) 
            .attr("width", iconSize * 2)
            .attr("height", iconSize)
            .append("xhtml:div")
            .attr("class", "expand-all-icon")
            .style("cursor", "pointer")
            .each(function (d) {
              ReactDOM.render(
                <span
                  onClick={(event) => {
                    event.stopPropagation();
                    expandAll(d);
                    updateTree(d);
                  }}
                >
                  ++
                </span>,
                this
              );
            });
        }
      });

      previousHeight = 0;
      index = 0;
      setNodeX(root);
      centerAllNodes(root);

      const nodeUpdate = nodeEnter.merge(node);
      nodeUpdate.select("rect")
        .style("fill", (d) =>
          typeof d.data.totalCountOfMappings === "number" && d.data.totalCountOfMappings > 0
            ? "#D5EDEC"  
            : "#fff"     
        )
        .style("stroke", "steelblue")
        .style("stroke-width", "1px");
      nodeUpdate.on("click", function (event, d) {
        if (selectedNode) {
          selectedNode.style("stroke", "steelblue") 
            .style("stroke-width", "2px");  
        }

        const currentRect = d3.select(this).select("rect");
        currentRect.style("stroke", "#4F40BA") 
          .style("stroke-width", "2px"); 
        selectedNode = currentRect;
        const path = d
          .ancestors()
          .reverse() 
          .map((node) => ({
            name: node.data.name || node.data.featureName || node.id || node.offerName,
            element_id: node.data.element_id || node.data.featureId || node.data.elementId || node.id,
          }));
        localStorage.setItem("selectedNodePath", JSON.stringify(path));

        if (onNodeClick) {
          const nodeId = d.data.element_id || node.id || node.elementId || node.data.id || node.featureId; 
          onNodeClick(nodeId)
        }
        event.stopPropagation();
      });

      d3.select("svg").on("click", function (event) {
        if (selectedNode) {
          selectedNode.style("stroke", "steelblue") 
            .style("stroke-width", "1px");  
          selectedNode = null;  
        }
      });

      nodeUpdate
        .transition()
        .duration(400)
        .attr("transform", (d) => `translate(${d.y},${d.x})`);
      nodeUpdate.select(".toggle-icon").each(function (d) {
        ReactDOM.render(
          d.children || d._children ? (
            d.children ? (
              <RemoveCircleOutline
                onClick={(event) => {
                  event.stopPropagation();
                  toggle(d);
                  updateTree(d);
                }}
              />
            ) : (
              <AddCircleOutline
                onClick={(event) => {
                  event.stopPropagation();
                  toggle(d);
                  updateTree(d);
                }}
              />
            )
          ) : null,
          this
        );
      });

      nodeUpdate.select(".expand-all-icon").each(function (d) {
        if (d.children || d._children) {
          ReactDOM.render(
            <span
              onClick={(event) => {
                event.stopPropagation();
                expandAll(d);
                updateTree(d);
              }}
            >
              ++
            </span>,
            this
          );
        }
      });


      node
        .exit()
        .transition()
        .duration(400)
        .attr("transform", (d) => `translate(${source.y},${source.x})`)
        .remove();

      const link = svg.selectAll("path.link").data(links, (d) => d.id);
      const linkEnter = link.enter().insert("path", "g").attr("class", "link").attr("d", (d) => {
        const o = { x: source.x0, y: source.y0 };
        return diagonal(o, o);
      }).style("fill", "none")  
        .style("stroke-width", 2)


      const linkUpdate = linkEnter.merge(link);

      linkUpdate
        .transition()
        .duration(400)
        .attr("d", (d) => diagonal(d, d.parent))
        .style("stroke-width", 2)


      link
        .exit()
        .transition()
        .duration(400)
        .attr("d", (d) => {
          const o = { x: source.x, y: source.y };
          return diagonal(o, o);
        })
        .remove();

      nodes.forEach((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });

      function diagonal(s, d) {
        const offsetY = rectWidth;

        const path = `M${s.y + offsetY},${s.x + s.height / 2}
                          H${s.y - rectWidth / 5}
                          V${d.x + d.height / 2}
                          H${d.y}`;
        return path;
      }

    }

    function collapse(d) {
      if (d.children) {
        d.children.forEach(collapse);
        d._children = d.children;
        d.children = null;
      }
    }

    updateTree(root);
  }, [data, onUpdateJson, onNodeClick]);

  return (
    <div ref={treeRef} className="tree-container">
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "500px", 
            height: "auto", 
            padding: "20px", 
            boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)", 
            borderRadius: "12px", 
            backgroundColor: '#FFFFFF', 
            position: 'fixed', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            transition: "all 0.3s ease",
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.6)", 
          },
        }}
      >
        {selectedNodeData && (
          <div style={{ color: '#000000', fontFamily: "'Roboto', sans-serif" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
              <Typography
                variant="h5"
                style={{
                  color: '#4F40BA', 
                  fontWeight: '600', 
                  fontSize: '18px', 
                }}>
                Node Content
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon style={{ color: '#4F40BA' }} /> 
              </IconButton>
            </div>
            <div
              style={{
                position: "relative",
                backgroundColor: "#F7F7F7", 
                padding: "15px", 
                borderRadius: "10px", 
                height: "auto",
                maxHeight: "300px",
                overflowY: "auto",
                boxShadow: "inset 0px 2px 6px rgba(0, 0, 0, 0.05)", 
              }}
            >
              <pre
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  fontSize: "16px", 
                  margin: "0", 
                  color: '#000000', 
                  fontFamily: "'Roboto', sans-serif", 
                  lineHeight: '1.6', 
                }}
              >
                {renderStyledJson(selectedNodeData)} 
              </pre>
              <Tooltip title="Copy node details">
                <IconButton
                  onClick={handleCopy}
                  style={{
                    position: "absolute",
                    top: "10px", 
                    right: "10px", 
                    color: '#000000', 
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </Menu>
    </div>
  );
};


export default TreeComponent;


function appendStyledText(
  node,
  rectWidth,
  textVerticalPadding,
  iconSpacing,
  numberOfIcons,
  iconPadding
) {
  const lineHeight = 18; 
  node.each(function (d) {
    const nodeGroup = d3.select(this);
    const data = d.data; 
    const x = iconPadding; 
    let y = textVerticalPadding; 
    const textWidth = numberOfIcons === 1 ? rectWidth * 0.9 : rectWidth * 0.8;
    function addWrappedTextWithBoldKey(key, value, x, startY, maxWidth) {
      let lineNumber = 0;
      const words = value.split(/\s+/); 
      let line = [];
      let keyTextHeight = lineHeight;
      const keyElement = nodeGroup.append("text")
        .attr("x", x)
        .attr("y", startY)
        .style("text-anchor", "start")
        .style("font-family", "Roboto, sans-serif")
        .style("font-size", "14px")
        .style("font-weight", "bold") 
        .text(key + ": ");
      const keyWidth = keyElement.node().getComputedTextLength(); 
      let textElement = nodeGroup.append("text")
        .attr("x", x + keyWidth) 
        .attr("y", startY)
        .style("text-anchor", "start")
        .style("font-family", "Roboto, sans-serif")
        .style("font-size", "14px")
        .style("font-weight", "normal"); 
      words.forEach((word) => {
        line.push(word);
        textElement.text(line.join(" "));

        if (textElement.node().getComputedTextLength() > maxWidth - keyWidth) {
          line.pop(); 
          textElement.text(line.join(" ")); 
          line = [word];
          lineNumber++;
          textElement = nodeGroup.append("text")
            .attr("x", x) 
            .attr("y", startY + lineNumber * lineHeight)
            .style("text-anchor", "start")
            .style("font-family", "Roboto, sans-serif")
            .style("font-size", "14px")
            .text(word);
        }
      });
      return keyTextHeight + lineNumber * lineHeight;
    }
    y += addWrappedTextWithBoldKey("Name", data.name, x, y, textWidth);

    if (data.description) {
      y += addWrappedTextWithBoldKey("Description", data.description, x, y, textWidth);
    }

    if (data.source) {
      y += addWrappedTextWithBoldKey("Source", data.source, x, y, textWidth);
    }

    if (data.accepted_by && data.accepted_by.length > 0) {
      y += addWrappedTextWithBoldKey("Accepted By", data.accepted_by.join(", "), x, y, textWidth);
    }

    if (typeof data.totalCountOfMappings === "number") {
      y += addWrappedTextWithBoldKey("Total Mappings", data.totalCountOfMappings.toString(), x, y, textWidth);
    }
    nodeGroup.select("rect").attr("height", y + textVerticalPadding - 29); 
  });
}





function calculateHeight(
  text,
  textVerticalPadding,
  rectWidth,
  iconSpacing,
  numberOfIcons,
  iconPadding
) {

  const context = document.createElement("canvas").getContext("2d");
  context.font = "16px Roboto, sans-serif";
  function measureTextWidth(text) {
    return context.measureText(text).width;
  }

  const words = text?.split(/\s+/);
  const textWidth = rectWidth - iconSpacing * numberOfIcons - iconPadding;
  let lineNumber = 1;
  let currentLine = "";

  words?.forEach((word) => {
    const testLine = currentLine ? currentLine + " " + word : word;
    const testWidth = measureTextWidth(testLine);

    if (testWidth > textWidth) {
      lineNumber++;
      currentLine = word; 
    } else {
      currentLine = testLine;
    }
  });

  return (textVerticalPadding * 2 + 25 * lineNumber);
}
