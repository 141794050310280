import React, { useContext } from "react";
import { Box } from "@mui/material";
// import { useLocation } from "react-router-dom";

import HeaderNaming from "../../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../../context/UserContext.jsx";
import VendorOfferDetail from "../../GridLayout/VendorOfferDetail.jsx";

const VendorDetails = () => {
  const { userProfile, buyerGuideName, scenarioName, vendorName } = useContext(UserProfileContext);
  // const location = useLocation();
  // const { vendor } = location.state || { vendor: "N/A" };

  const data = {
    "User Name": userProfile?.displayName,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
    Vendor: vendorName,
  };

  return (
    <Box sx={{ width: "100%", marginTop: 4, borderRadius: "10px" }}>
      <HeaderNaming data={data} />
      <VendorOfferDetail />
    </Box>
  );
};

export default VendorDetails;
