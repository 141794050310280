import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Snackbar, ThemeProvider, useTheme, Container } from '@mui/material';
import theme from '../theme/theme';
import { UserProfileContext } from "../context/UserContext";
import { DataTable } from './reusable/DataTable';
 
const VendorOfferMenuMapping = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const { userProfile } = useContext(UserProfileContext);
 
 
  const offers = location.state?.offers ?? [];
 
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [collaboratorEmail, setCollaboratorEmail] = useState('');
  const [openInvite, setOpenInvite] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
 
  // Function to toggle offer selection
  const toggleSelection = (id) => {
    const newSelectedOfferId = selectedOfferId === id ? null : id;
    const selectedVendorData = offers.find((offer) => offer?.offerId === newSelectedOfferId);
    setSelectedOfferId(newSelectedOfferId);
    localStorage.setItem('currentOfferData', JSON.stringify(selectedVendorData));
    localStorage.setItem('selectedOfferId', newSelectedOfferId);
  };
 
  // Define columns for DataTable
  const columns = [
    { field: 'select', headerName: 'Select', width: 150 },
    { field: 'offerName', headerName: 'Offer Name', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'version', headerName: 'Version', flex: 1 },
    { field: 'createdBy', headerName: 'Created By', flex: 1 },
  ];
 
  const handleCloseInvite = () => setOpenInvite(false);
  const handleCloseSnackbar = () => setOpenSnackbar(false);
 
  // Save the invitation to collaborate on an offer
  const handleSaveInvite = async () => {
    if (!collaboratorEmail || !selectedOfferId) {
      console.error("Collaborator email or selected offer is missing");
      return;
    }
 
    const payload = {
      email: userProfile.email,
      currentOfferId: selectedOfferId,
      collaboratorEmail,
    };
 
    try {
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/inviteAnalystToCoCreateOffer",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );
 
      if (!response.ok) throw new Error("Failed to send invitation");
 
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error sending invitation:", error);
    }
 
    handleCloseInvite();
  };
 
  const handleSelectOffer = async () => {
    if (!selectedOfferId) {
      console.error("No offer selected.");
      return;
    }
 
 
    const currentbuyerGuideData = JSON.parse(localStorage.getItem('currentbuyerGuideData'));
   const currentVendorData  = JSON.parse(localStorage.getItem('currentVendorData'))
 
    const payload = {
      email: userProfile.email,
      currentBuyerGuideId: currentbuyerGuideData?.buyerGuideId,
      currentVendorId: currentVendorData?.vendorId,
      currentOfferId: selectedOfferId,
    };
 
    try {
      const response = await fetch("https://newtestfuncpython.azurewebsites.net/api/selectOfferForAnalyst", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
 
      if (!response.ok) throw new Error("Failed to select offer for analyst");
 
      navigate('/model-parameter');
    } catch (error) {
      console.error("Error selecting offer:", error);
    }
  };
 
 
 
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container sx={{ display: 'contents', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 0 }}>
          <Typography variant="h4" gutterBottom>
            Vendor Offers
          </Typography>
          <Box>
            <DataTable
              rows={offers}
              columns={columns}
              pageSize={5}
              hideFooter
              selectedRowId={selectedOfferId}
              onSelectRow={toggleSelection} // Pass toggleSelection function for toggling
              getRowId={(row) => row.offerId} // Using offerId as the unique identifier
              sx={{
                width: '100%',
                height: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: muiTheme.palette.primary.main,
                  color: 'white',
                  fontSize: '14px',
                  '.MuiSvgIcon-root': { color: 'white' },
                },
              }}
            />
          </Box>
          <Box mt={2} display="flex" flexDirection="column" gap={2}>
            {/* Handle select offer action */}
            <Button sx={{ width: '250px' }} variant="contained" color="primary" onClick={handleSelectOffer}>
              Selected Offer
            </Button>
          </Box>
        </Container>
      </Box>
 
      {/* Invite Collaborator Dialog */}
      <Dialog open={openInvite} onClose={handleCloseInvite} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Collaborator</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Collaborator's Email"
            type="email"
            fullWidth
            value={collaboratorEmail}
            onChange={(e) => setCollaboratorEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseInvite}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSaveInvite}>Send Invitation</Button>
        </DialogActions>
      </Dialog>
 
      {/* Snackbar for invitation success message */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} message="Invitation sent successfully!" />
    </ThemeProvider>
  );
};
 
export default VendorOfferMenuMapping;
 
