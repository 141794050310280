const generateData = (userProfile, page, availableUnitsForAllocation) => {
  //
  const getPackageData = (packageName, field, type) =>
    availableUnitsForAllocation?.find(
      (packag) =>
        packag.purchasedPackageName === packageName &&
        packag.allocationType === type
    )?.[field];

  // Updated User Units
  const userUnits = [
    {
      id: 123,
      user: "Self",
      name: userProfile.displayName + userProfile.surname,
      email: userProfile.email,

      //Fully Prescribed Packages are called
      //
      soloStarterFullyPrescribedInput: 0,
      duelDynamicsFullyPrescribedInput: 0,
      focusFiveFullyPrescribedInput: 0,
      allAvailableFullyPrescribedInput: 0,

      soloStarterFullyPrescribedTotal: Math.min(
        getPackageData("Solo Starter", "userUnitsInput", "Fully Prescribed") ||
          0,
        1
      ),

      duelDynamicsFullyPrescribedTotal: Math.min(
        getPackageData("Duel Dynamics", "userUnitsInput", "Fully Prescribed") ||
          0,
        1
      ),

      focusFiveFullyPrescribedTotal: Math.min(
        getPackageData("Focus Five", "userUnitsInput", "Fully Prescribed") || 0,
        1
      ),

      allAvailableFullyPrescribedTotal: Math.min(
        getPackageData("All Available", "userUnitsInput", "Fully Prescribed") ||
          0,
        1
      ),

      //

      //Partially Prescribed End User Units are called
      //
      soloStarterPartiallyPrescribedEUInput: 0,
      duelDynamicsPartiallyPrescribedEUInput: 0,
      focusFivePartiallyPrescribedEUInput: 0,
      allAvailablePartiallyPrescribedEUInput: 0,

      soloStarterPartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "userUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),

      duelDynamicsPartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "userUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),

      focusFivePartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Focus Five",
          "userUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),

      allAvailablePartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "All Available",
          "userUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),

      //

      //Partially Prescribed Buyer Guide Units are called
      //
      soloStarterPartiallyPrescribedBUInput: 0,
      duelDynamicsPartiallyPrescribedBUInput: 0,
      focusFivePartiallyPrescribedBUInput: 0,
      allAvailablePartiallyPrescribedBUInput: 0,

      soloStarterPartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "userUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),

      duelDynamicsPartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "userUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),

      focusFivePartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Focus Five",
          "userUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),

      allAvailablePartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "All Available",
          "userUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),

      //

      //Un Prescribed Packages

      //
      soloStarterUnPrescribedInput: 0,
      duelDynamicsUnPrescribedInput: 0,
      focusFiveUnPrescribedInput: 0,
      allAvailableUnPrescribedInput: 0,

      soloStarterUnPrescribedTotal: Math.min(
        getPackageData("Solo Starter", "userUnitsInput", "UnPrescribed") || 0,
        1
      ),

      duelDynamicsUnPrescribedTotal: Math.min(
        getPackageData("Duel Dynamics", "userUnitsInput", "UnPrescribed") || 0,
        1
      ),

      focusFiveUnPrescribedTotal: Math.min(
        getPackageData("Focus Five", "userUnitsInput", "UnPrescribed") || 0,
        1
      ),

      allAvailableUnPrescribedTotal: Math.min(
        getPackageData("All Available", "userUnitsInput", "UnPrescribed") || 0,
        1
      ),

      //
    },
    {
      id: 89,
      user: "Other",

      //Fully Prescribed Packages are called
      //
      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed End User Org Units are called
      //
      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //

      //Partially Prescribed BU are called
      //
      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //

      //Un prescribed Packages
      //

      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "UnPrescribed"
      ),

      //
    },
    {
      id: "other1",
      name: "",
      email: "",

      //Fully Prescribed Packages are called

      //

      soloStarterFullyPrescribedInput: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedInput: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedInput: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedInput: getPackageData(
        "All Available",
        "userUnitsInput",
        "Fully Prescribed"
      ),

      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed EU are called

      //

      soloStarterPartiallyPrescribedEUInput: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUInput: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUInput: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUInput: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //

      //Partially Prescribed BU are called

      //

      soloStarterPartiallyPrescribedBUInput: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUInput: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUInput: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUInput: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //

      //Un Prescribed Packages are called

      //

      soloStarterUnPrescribedInput: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedInput: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedInput: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedInput: getPackageData(
        "All Available",
        "userUnitsInput",
        "UnPrescribed"
      ),

      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "UnPrescribed"
      ),

      //
    },
    {
      id: 83,
      user: "Total",

      //Fully Prescribed Packages are called

      //

      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed EU are called

      //

      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //

      //Partially Prescribed BU are called

      //

      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //

      //Un Prescribed Packages are called

      //

      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "userUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "userUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "userUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "userUnitsInput",
        "UnPrescribed"
      ),

      //
    },
  ];

  //

  // Updated End User Organization Units
  const endUserOrganizationUnits = [
    {
      id: "eu1",
      organisationName: "",

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedInput: 0,
      duelDynamicsFullyPrescribedInput: 0,
      focusFiveFullyPrescribedInput: 0,
      allAvailableFullyPrescribedInput: 0,

      soloStarterFullyPrescribedTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "endUserOrganizationUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      duelDynamicsFullyPrescribedTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "endUserOrganizationUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      focusFiveFullyPrescribedTotal: Math.min(
        getPackageData(
          "Focus Five",
          "endUserOrganizationUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      allAvailableFullyPrescribedTotal: Math.min(
        getPackageData(
          "All Available",
          "endUserOrganizationUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      //

      //Partially Prescribed EU are called

      //
      soloStarterPartiallyPrescribedEUInput: 0,
      duelDynamicsPartiallyPrescribedEUInput: 0,
      focusFivePartiallyPrescribedEUInput: 0,
      allAvailablePartiallyPrescribedEUInput: 0,

      soloStarterPartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      duelDynamicsPartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      focusFivePartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Focus Five",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      allAvailablePartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "All Available",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      //

      //Partially Prescribed BU are called

      //
      soloStarterPartiallyPrescribedBUInput: 0,
      duelDynamicsPartiallyPrescribedBUInput: 0,
      focusFivePartiallyPrescribedBUInput: 0,
      allAvailablePartiallyPrescribedBUInput: 0,

      soloStarterPartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      duelDynamicsPartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      focusFivePartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Focus Five",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      allAvailablePartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "All Available",
          "endUserOrganizationUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      //

      //Un Prescribed Packages are called

      //
      soloStarterUnPrescribedInput: 0,
      duelDynamicsUnPrescribedInput: 0,
      focusFiveUnPrescribedInput: 0,
      allAvailableUnPrescribedInput: 0,

      soloStarterUnPrescribedTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "endUserOrganizationUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),
      duelDynamicsUnPrescribedTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "endUserOrganizationUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),
      focusFiveUnPrescribedTotal: Math.min(
        getPackageData(
          "Focus Five",
          "endUserOrganizationUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),
      allAvailableUnPrescribedTotal: Math.min(
        getPackageData(
          "All Available",
          "endUserOrganizationUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),
      //
    },
    {
      id: 23432,
      organisationName: "User Defined",

      //Fully Prescribed Packages are called
      //
      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed EU are called
      //
      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUInput: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //

      //Partially Prescribed BU are called
      //
      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUInput: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //

      //Un Prescribed Packages are called
      //
      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),

      //
    },
    {
      id: 93,
      organisationName: "Total",

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed EU are called

      //
      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUTotal: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //Partially Prescribed BU are called

      //
      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUTotal: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //Un Prescribed Packages are called

      //
      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "endUserOrganizationUnitsInput",
        "UnPrescribed"
      ),

      //
    },
  ];

  // Updated Buyer Guide Units
  const buyerGuideUnits = [
    {
      id: "bu1",
      buyerGuideName: {
        id: 768,
        selected: "None",
        options: [
          { name: "Buyer Guide 1" },
          { name: "Buyer Guide 2" },
          { name: "Buyer Guide 3" },
          { name: "Buyer Guide 4" },
        ],
      },

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedInput: 0,
      duelDynamicsFullyPrescribedInput: 0,
      focusFiveFullyPrescribedInput: 0,
      allAvailableFullyPrescribedInput: 0,

      soloStarterFullyPrescribedTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "buyerGuideUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      duelDynamicsFullyPrescribedTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "buyerGuideUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      focusFiveFullyPrescribedTotal: Math.min(
        getPackageData(
          "Focus Five",
          "buyerGuideUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),
      allAvailableFullyPrescribedTotal: Math.min(
        getPackageData(
          "All Available",
          "buyerGuideUnitsInput",
          "Fully Prescribed"
        ) || 0,
        1
      ),

      //

      //Partially Prescribed EU are called

      //
      soloStarterPartiallyPrescribedEUInput: 0,
      duelDynamicsPartiallyPrescribedEUInput: 0,
      focusFivePartiallyPrescribedEUInput: 0,
      allAvailablePartiallyPrescribedEUInput: 0,

      soloStarterPartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "buyerGuideUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      duelDynamicsPartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "buyerGuideUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      focusFivePartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "Focus Five",
          "buyerGuideUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      allAvailablePartiallyPrescribedEUTotal: Math.min(
        getPackageData(
          "All Available",
          "buyerGuideUnitsInput",
          "Partially Prescribed (End-User)"
        ) || 0,
        1
      ),
      //

      //Partially Prescribed BU are called

      //
      soloStarterPartiallyPrescribedBUInput: 0,
      duelDynamicsPartiallyPrescribedBUInput: 0,
      focusFivePartiallyPrescribedBUInput: 0,
      allAvailablePartiallyPrescribedBUInput: 0,

      soloStarterPartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "buyerGuideUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      duelDynamicsPartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "buyerGuideUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      focusFivePartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "Focus Five",
          "buyerGuideUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      allAvailablePartiallyPrescribedBUTotal: Math.min(
        getPackageData(
          "All Available",
          "buyerGuideUnitsInput",
          "Partially Prescribed (Buyer Guide)"
        ) || 0,
        1
      ),
      //

      //Un Prescribed Packages are called

      //
      soloStarterUnPrescribedInput: 0,
      duelDynamicsUnPrescribedInput: 0,
      focusFiveUnPrescribedInput: 0,
      allAvailableUnPrescribedInput: 0,

      soloStarterUnPrescribedTotal: Math.min(
        getPackageData(
          "Solo Starter",
          "buyerGuideUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),
      duelDynamicsUnPrescribedTotal: Math.min(
        getPackageData(
          "Duel Dynamics",
          "buyerGuideUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),
      focusFiveUnPrescribedTotal: Math.min(
        getPackageData("Focus Five", "buyerGuideUnitsInput", "UnPrescribed") ||
          0,
        1
      ),
      allAvailableUnPrescribedTotal: Math.min(
        getPackageData(
          "All Available",
          "buyerGuideUnitsInput",
          "UnPrescribed"
        ) || 0,
        1
      ),

      //
    },

    {
      id: 93,
      buyerGuideName: "User Defined",

      //Fully Prescribed Packages are called

      //

      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed EU are called
      //
      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUInput: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //

      //Partially Prescribed BU are called
      //
      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUInput: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //

      //Un Prescribed Packages are called
      //
      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),

      //
    },

    {
      id: 88993,
      buyerGuideName: "Total",

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),
      duelDynamicsFullyPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),
      focusFiveFullyPrescribedTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),
      allAvailableFullyPrescribedTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "Fully Prescribed"
      ),

      //

      //Partially Prescribed EU are called

      //
      soloStarterPartiallyPrescribedEUTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      duelDynamicsPartiallyPrescribedEUTotal: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      focusFivePartiallyPrescribedEUTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),
      allAvailablePartiallyPrescribedEUTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "Partially Prescribed (End-User)"
      ),

      //Partially Prescribed BU are called

      //
      soloStarterPartiallyPrescribedBUTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      duelDynamicsPartiallyPrescribedBUTotal: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      focusFivePartiallyPrescribedBUTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),
      allAvailablePartiallyPrescribedBUTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "Partially Prescribed (Buyer Guide)"
      ),

      //Un Prescribed Packages are called

      //
      soloStarterUnPrescribedTotal: getPackageData(
        "Solo Starter",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),
      duelDynamicsUnPrescribedTotal: getPackageData(
        "Duel Dynamics",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),
      focusFiveUnPrescribedTotal: getPackageData(
        "Focus Five",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),
      allAvailableUnPrescribedTotal: getPackageData(
        "All Available",
        "buyerGuideUnitsInput",
        "UnPrescribed"
      ),

      //
    },
  ];
  //

  // Updated Unlocked Vendors
  const unlockedVendors = [
    {
      id: "vu1",
      vendorName: {
        id: 768,
        selected: "None",
        options: [
          { name: "Vendor 1" },
          { name: "Vendor 2" },
          { name: "Vendor 3" },
          { name: "Vendor 4" },
        ],
      },

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedInput: 0,
      duelDynamicsFullyPrescribedInput: 0,
      focusFiveFullyPrescribedInput: 0,
      allAvailableFullyPrescribedInput: 0,

      soloStarterFullyPrescribedTotal: 1,
      duelDynamicsFullyPrescribedTotal: 1,
      focusFiveFullyPrescribedTotal: 1,
      allAvailableFullyPrescribedTotal: 1,

      //

      //Partially Prescribed EU are called

      //
      soloStarterPartiallyPrescribedEUInput: 0,
      duelDynamicsPartiallyPrescribedEUInput: 0,
      focusFivePartiallyPrescribedEUInput: 0,
      allAvailablePartiallyPrescribedEUInput: 0,

      soloStarterPartiallyPrescribedEUTotal: 1,
      duelDynamicsPartiallyPrescribedEUTotal: 1,
      focusFivePartiallyPrescribedEUTotal: 1,
      allAvailablePartiallyPrescribedEUTotal: 1,

      //

      //Partially Prescribed BU are called

      //
      soloStarterPartiallyPrescribedBUInput: 0,
      duelDynamicsPartiallyPrescribedBUInput: 0,
      focusFivePartiallyPrescribedBUInput: 0,
      allAvailablePartiallyPrescribedBUInput: 0,

      soloStarterPartiallyPrescribedBUTotal: 1,
      duelDynamicsPartiallyPrescribedBUTotal: 1,
      focusFivePartiallyPrescribedBUTotal: 1,
      allAvailablePartiallyPrescribedBUTotal: 1,

      //

      //UnPrescribed Packages are called

      //
      soloStarterUnPrescribedInput: 0,
      duelDynamicsUnPrescribedInput: 0,
      focusFiveUnPrescribedInput: 0,
      allAvailableUnPrescribedInput: 0,

      soloStarterUnPrescribedTotal: 1,
      duelDynamicsUnPrescribedTotal: 1,
      focusFiveUnPrescribedTotal: 1,
      allAvailableUnPrescribedTotal: 1,

      //
    },
    {
      id: 13,
      vendorName: "User Defined",

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedTotal: 1,
      duelDynamicsFullyPrescribedTotal: 2,
      focusFiveFullyPrescribedTotal: 5,
      allAvailableFullyPrescribedTotal: "Unlimited",

      //

      //Partially Prescribed EU are called

      //

      soloStarterPartiallyPrescribedEUTotal: 1,
      duelDynamicsPartiallyPrescribedEUTotal: 2,
      focusFivePartiallyPrescribedEUTotal: 5,
      allAvailablePartiallyPrescribedEUTotal: "Unlimited",

      //

      //Partially Prescribed BU are called

      //

      soloStarterPartiallyPrescribedBUTotal: 1,
      duelDynamicsPartiallyPrescribedBUTotal: 2,
      focusFivePartiallyPrescribedBUTotal: 5,
      allAvailablePartiallyPrescribedBUTotal: "Unlimited",

      //

      //UnPrescribed Packages are called

      //
      soloStarterUnPrescribedTotal: 1,
      duelDynamicsUnPrescribedTotal: 2,
      focusFiveUnPrescribedTotal: 5,
      allAvailableUnPrescribedTotal: "Unlimited",

      //
    },
    {
      id: 88993,
      vendorName: "Total",
      listOfVendors: [
        { name: "Vendor 1" },
        { name: "Vendor 2" },
        { name: "Vendor 3" },
        { name: "Vendor 4" },
      ],

      //Fully Prescribed Packages are called

      //
      soloStarterFullyPrescribedTotal: 1,
      duelDynamicsFullyPrescribedTotal: 2,
      focusFiveFullyPrescribedTotal: 5,
      allAvailableFullyPrescribedTotal: "Unlimited",

      //

      //Partially Prescribed EU are called

      //

      soloStarterPartiallyPrescribedEUTotal: 1,
      duelDynamicsPartiallyPrescribedEUTotal: 2,
      focusFivePartiallyPrescribedEUTotal: 5,
      allAvailablePartiallyPrescribedEUTotal: "Unlimited",

      //

      //Partially Prescribed BU are called

      //

      soloStarterPartiallyPrescribedBUTotal: 1,
      duelDynamicsPartiallyPrescribedBUTotal: 2,
      focusFivePartiallyPrescribedBUTotal: 5,
      allAvailablePartiallyPrescribedBUTotal: "Unlimited",

      //

      //UnPrescribed Packages are called

      //
      soloStarterUnPrescribedTotal: 1,
      duelDynamicsUnPrescribedTotal: 2,
      focusFiveUnPrescribedTotal: 5,
      allAvailableUnPrescribedTotal: "Unlimited",

      //
    },
  ];

  const latestRadioData = JSON.parse(sessionStorage.getItem("latestRadioData"));

  // It maps the data from previous page with the data from session storage of allocated pages
  const dataMapping = {
    userUnits: latestRadioData?.find((el) => el.page === "userUnits")
      ?.prescription
      ? JSON.parse(sessionStorage.getItem("userUnits"))
      : userUnits,

    endUserOrganizationUnits: latestRadioData?.find(
      (el) => el.page === "endUserOrganizationUnits"
    )?.prescription
      ? JSON.parse(sessionStorage.getItem("endUserOrganizationUnits"))
      : endUserOrganizationUnits,

    buyerGuideUnits: latestRadioData?.find(
      (el) => el.page === "buyerGuideUnits"
    )?.prescription
      ? JSON.parse(sessionStorage.getItem("buyerGuideUnits"))
      : buyerGuideUnits,

    unlockedVendors: latestRadioData?.find(
      (el) => el.page === "unlockedVendors"
    )?.prescription
      ? JSON.parse(sessionStorage.getItem("unlockedVendors"))
      : unlockedVendors,
  };
  console.log("dataMapping", dataMapping[page]);
  return dataMapping[page];
};

export default generateData;
