// import {
//   Box,
//   useTheme,
//   ThemeProvider,
//   Checkbox,
//   Pagination,
//   CircularProgress,
//   Button,
//   Typography,
//   IconButton,
//   MenuItem,
//   FormControl,
//   Select,
//   InputLabel,
//   Stack,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   FormLabel,
// } from "@mui/material";
// import React, { useEffect, useState, useContext, useRef } from "react";
// import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
// import { useLocation } from "react-router-dom";
// import { UserProfileContext } from "../context/UserContext";
// import { DataGrid } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
// import { apiService } from "../utils/utils";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// function MappingRecommendation() {
//   const location = useLocation();
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const [pageData, setPageData] = useState([]);
//   const [isloading, setIsLoading] = useState(false);
//   const totalPages = useRef(0);
//   const pageType = location.state?.pageType;
//   const data = location.state?.data;
//   const endPoint = location.state?.endPoint;
//   const payload = location.state?.payload;
//   const [currentPageNumber, setCurrentPageNumber] = useState(1);
//   const { userProfile } = useContext(UserProfileContext);
//   const [selectAcceptAll, setSelectAll] = useState(false);
//   const [selectRejectAll, setRejectAll] = useState(false);
//   const [selectedMappedBy, setSelectedMappedBy] = useState("Everyone");
//   const currentbuyerGuideData = JSON.parse(
//     localStorage.getItem("currentbuyerGuideData")
//   );
//   const currentVendorData = JSON.parse(
//     localStorage.getItem("currentVendorData")
//   );
//   const currentOfferData = JSON.parse(localStorage.getItem("currentOfferData"));
//   const [pageLimit, setPageLimit] = useState(10);

//   const HeaderData = {
//     "Buyer Guide": currentbuyerGuideData?.buyerGuideName || "",
//     "Vendor Name": currentVendorData?.vendorName,
//     "Offer Name": currentOfferData?.offerName,
//   };
//   useEffect(() => {
//     totalPages.current = data?.totalPages;
//     setCurrentPageNumber(data?.currentPageNumber);
//     setPageData(() => {
//       return data?.recommendations.map((item, index) => {
//         return { ...item, id: index };
//       });
//     });
//     setIsLoading(false);
//   }, [totalPages, data]);
//   const fetchPagedata = async (pageNumber) => {
//     try {
//       const reponse = await apiService(
//         `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
//         {
//           method: "POST",
//           data: {
//             ...payload,
//             currentPageNumber: pageNumber,
//             recommendationsPerPage: pageLimit,
//           },
//         }
//       );
//       setPageData(() => {
//         return reponse?.recommendations.map((item, index) => {
//           return { ...item, id: index };
//         });
//       });
//     } catch (error) {
//       console.log("request Error :", error);
//     }
//   };
//   const saveData = async () => {
//     try {
//       const reponse = await apiService(
//         "https://newtestfuncpython.azurewebsites.net/api/saveMappingToDB",
//         {
//           method: "POST",
//           data: {
//             email: userProfile?.email,
//             recommendations: {
//               totalPages: totalPages.current,
//               currentPageNumber: currentPageNumber,
//               recommendations: pageData,
//             },
//           },
//         }
//       );
//       return reponse;
//     } catch (error) {
//       console.log("request Error:", error);
//     }
//   };

//   const handlePageChange = async (event, pageNumber) => {
//     let CheckFlag = false;
//     for (let i = 0; i < pageData?.length; i++) {
//       if (
//         !(
//           pageData[i]?.acceptedBy.includes(userProfile?.email) ||
//           pageData[i]?.rejectedBy.includes(userProfile?.email)
//         )
//       ) {
//         CheckFlag = true;
//         break;
//       }
//     }
//     if (CheckFlag) {
//       alert("please Either Accept or Reject all the UseCase");
//       return;
//     } else {
//       const reponse = await saveData();
//       if (reponse) {
//         await fetchPagedata(pageNumber);
//         setCurrentPageNumber(pageNumber);
//       }
//     }
//   };
//   const acceptAll = () => {
//     console.log("selectAcceptAll", selectAcceptAll);
//     setPageData((prev) => {
//       return prev.map((item) => {
//         if (!item.acceptedBy.includes(userProfile?.email) && !selectAcceptAll) {
//           return {
//             ...item,
//             rejectedBy: item?.rejectedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//             acceptedBy: [...item?.acceptedBy, userProfile?.email],
//           };
//         } else if (
//           item.acceptedBy.includes(userProfile?.email) &&
//           selectAcceptAll
//         ) {
//           return {
//             ...item,
//             rejectedBy: item?.rejectedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//             acceptedBy: item?.acceptedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//           };
//         } else {
//           return item;
//         }
//       });
//     });
//   };
//   const rejectAll = () => {
//     setPageData((prev) => {
//       return prev.map((item) => {
//         if (!item.rejectedBy.includes(userProfile?.email) && !selectRejectAll) {
//           return {
//             ...item,
//             rejectedBy: [...item?.rejectedBy, userProfile?.email],
//             acceptedBy: item?.acceptedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//           };
//         } else if (
//           item.rejectedBy.includes(userProfile?.email) &&
//           selectRejectAll
//         ) {
//           return {
//             ...item,
//             rejectedBy: item?.rejectedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//             acceptedBy: item?.acceptedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//           };
//         } else {
//           return item;
//         }
//       });
//     });
//   };
//   const handleChange = async (event) => {
//     setPageLimit(event.target.value);
//     setIsLoading(true);
//     try {
//       const reponse = await apiService(
//         `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
//         {
//           method: "POST",
//           data: {
//             ...payload,
//             currentPageNumber: currentPageNumber,
//             recommendationsPerPage: event.target.value,
//           },
//         }
//       );
//       totalPages.current = reponse?.totalPages;
//       setPageData(() => {
//         return reponse?.recommendations.map((item, index) => {
//           return { ...item, id: index };
//         });
//       });
//     } catch (error) {
//       console.log("request Error :", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const handleFilterChange = (event)=>{
//      const value = event.target.value;
//      setSelectedMappedBy(value);

//   }
//   const columnDataOverAll = [
//     {
//       field: "accepted",
//       headerName: "Accept",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectAcceptAll}
//             onChange={() => {
//               setSelectAll((prev) => {
//                 return !prev;
//               });
//               setRejectAll(false);
//               acceptAll();
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Accept
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                 ) {
//                   setRejectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                           acceptedBy: [...item?.acceptedBy, userProfile?.email],
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectAcceptAll) {
//                     setSelectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "rejected",
//       headerName: "Rejected",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectRejectAll}
//             onChange={() => {
//               setRejectAll((prev) => {
//                 return !prev;
//               });
//               rejectAll();
//               setSelectAll(false);
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Rejected
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                 ) {
//                   setSelectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectRejectAll) {
//                     setRejectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           accepted: false,
//                           rejected: false,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },

//     {
//       field: "useCaseLineage",
//       headerName: "Use Case",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.useCaseLineage });
//       },
//     },
//     {
//       field: "featureLineage",
//       headerName: "Feature",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.featureLineage });
//       },
//     },
//     {
//       field: "acceptedBy",
//       headerName: "Accepted By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].acceptedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].acceptedBy.join(", ");
//       },
//     },
//     {
//       field: "rejectedBy",
//       headerName: "Rejected By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].rejectedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].rejectedBy.join(", ");
//       },
//     },
//   ];
//   const columnDataUseCase = [
//     {
//       field: "accepted",
//       headerName: "Accept",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectAcceptAll}
//             onChange={() => {
//               setSelectAll((prev) => {
//                 return !prev;
//               });
//               setRejectAll(false);
//               acceptAll();
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Accept
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                 ) {
//                   setRejectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                           acceptedBy: [...item?.acceptedBy, userProfile?.email],
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectAcceptAll) {
//                     setSelectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "rejected",
//       headerName: "Rejected",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectRejectAll}
//             onChange={() => {
//               setRejectAll((prev) => {
//                 return !prev;
//               });
//               rejectAll();
//               setSelectAll(false);
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Rejected
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                 ) {
//                   setSelectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectRejectAll) {
//                     setRejectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           accepted: false,
//                           rejected: false,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "useCaseLineage",
//       headerName: "Use Case",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.useCaseLineage });
//       },
//     },
//     {
//       field: "featureLineage",
//       headerName: "Feature",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.featureLineage });
//       },
//     },
//     {
//       field: "acceptedBy",
//       headerName: "Accepted By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].acceptedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].acceptedBy.join(", ");
//       },
//     },
//     {
//       field: "rejectedBy",
//       headerName: "Rejected By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].rejectedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].rejectedBy.join(", ");
//       },
//     },
//   ];
//   const columnDataFeature = [
//     {
//       field: "accepted",
//       headerName: "Accept",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectAcceptAll}
//             onChange={() => {
//               setSelectAll((prev) => {
//                 return !prev;
//               });
//               setRejectAll(false);
//               acceptAll();
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Accept
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                 ) {
//                   setRejectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                           acceptedBy: [...item?.acceptedBy, userProfile?.email],
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectAcceptAll) {
//                     setSelectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "rejected",
//       headerName: "Rejected",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectRejectAll}
//             onChange={() => {
//               setRejectAll((prev) => {
//                 return !prev;
//               });
//               rejectAll();
//               setSelectAll(false);
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Rejected
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                 ) {
//                   setSelectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectRejectAll) {
//                     setRejectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           accepted: false,
//                           rejected: false,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "featureLineage",
//       headerName: "Feature",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.featureLineage });
//       },
//     },
//     {
//       field: "useCaseLineage",
//       headerName: "Use Case",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.useCaseLineage });
//       },
//     },

//     {
//       field: "acceptedBy",
//       headerName: "Accepted By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].acceptedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].acceptedBy.join(", ");
//       },
//     },
//     {
//       field: "rejectedBy",
//       headerName: "Rejected By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].rejectedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].rejectedBy.join(", ");
//       },
//     },
//   ];
//   return (
//     <ThemeProvider theme={theme}>
//       <Box>
//         <HeaderNaming data={HeaderData} />
//         {isloading ? (
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               minHeight: "100vh", // Ensures the loader is centered vertically
//             }}
//           >
//             <CircularProgress />
//           </Box>
//         ) : (
//           <>
//             <Box display="flex" justifyContent={"space-between"}>
//               <Box minWidth={300}>
//                 <Stack direction="row" alignItems="center">
//                   <InputLabel>Select Page Limit : </InputLabel>
//                   <FormControl variant="outlined" sx={{ width: "50%" }}>
//                     <Select
//                       value={pageLimit}
//                       onChange={handleChange}
//                       displayEmpty
//                     >
//                       <MenuItem value={10}>10</MenuItem>
//                       <MenuItem value={20}>20</MenuItem>
//                       <MenuItem value={30}>30</MenuItem>
//                       <MenuItem value={40}>40</MenuItem>
//                       <MenuItem value={50}>50</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Stack>
//               </Box>
//               <Box minWidth={300}>
//                 <Stack direction="row" alignItems="center">
                  
//                   <FormControl value={selectedMappedBy} component="fieldset" sx={{ marginBottom: 2 }}>
//                     <FormLabel component="legend">Mapped by :</FormLabel>
//                     <RadioGroup onChange={handleFilterChange}>
//                       <FormControlLabel
//                         value="Me"
//                         control={<Radio />}
//                         label="Me"
//                       />
//                       <FormControlLabel
//                         value="Everyone"
//                         control={<Radio />}
//                         label="Everyone"
//                       />
                      
//                     </RadioGroup>
//                   </FormControl>
//                 </Stack>
//               </Box>
//             </Box>
//             <DataGrid
//               columns={
//                 pageType === "overAll"
//                   ? columnDataOverAll
//                   : pageType === "specificUseCase"
//                   ? columnDataUseCase
//                   : pageType === "specificFeature"
//                   ? columnDataFeature
//                   : []
//               }
//               rows={selectedMappedBy=== 'Everyone'?pageData: pageData.filter((item)=>{  return item.acceptedBy?.includes(userProfile?.email)||item.rejectedBy?.includes(userProfile?.email)})}
//               hideFooter
//               sx={{
//                 "& .super-app-theme--header": {
//                   backgroundColor: theme.palette.primary.main,
//                   color: "white",
//                   fontSize: "14px",
//                   ".MuiSvgIcon-root": {
//                     color: "white",
//                   },
//                 },
//                 "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
//                   backgroundColor: theme.palette.primary.main,
//                   color: "white",
//                 },
//                 "& .MuiDataGrid-columnHeaderTitle": {
//                   fontWeight: "normal",
//                 },
//                 "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
//                   outline: "none !important",
//                 },
//               }}
//             />
//             <Pagination
//               count={totalPages?.current}
//               page={currentPageNumber}
//               color="primary"
//               onChange={async (event, pageNumber) => {
//                 await handlePageChange(event, pageNumber);
//               }}
//               sx={{ mt: 2, display: "flex", justifyContent: "center" }}
//             />
//             <Box sx={{ marginTop: "20px" }}>
//               <Button
//                 sx={{ width: "300px" }}
//                 color="primary"
//                 variant="contained"
//                 disableRipple
//                 onClick={async () => {
//                   const reponse = await saveData();
//                   if (reponse) {
//                     navigate("/usecase-to-feature");
//                   }
//                 }}
//               >
//                 Save & Exit to Main Mapping Page
//               </Button>
//             </Box>
//           </>
//         )}
//       </Box>
//     </ThemeProvider>
//   );
// }

// export default MappingRecommendation;

// const ShowPath = (params) => {
//   const [showCell, setShowCell] = useState(false);
//   const { value } = params;
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "flex-start",
//         alignItems: "center",
//         height: "100%",
//         width: "100%",
//         overflowY: "hidden",
//         gap: "0px 10px",
//       }}
//     >
//       <Box>
//         <IconButton
//           onClick={() => {
//             setShowCell(!showCell);
//           }}
//         >
//           {showCell ? (
//             <KeyboardDoubleArrowLeftIcon />
//           ) : (
//             <KeyboardDoubleArrowRightIcon />
//           )}
//         </IconButton>
//       </Box>
//       <Box>{showCell ? value.join(" > ") : value[value.length - 1]}</Box>
//     </Box>
//   );
// };




// test1  :

// import {
//   Box,
//   useTheme,
//   ThemeProvider,
//   Checkbox,
//   Pagination,
//   CircularProgress,
//   Button,
//   Typography,
//   IconButton,
//   MenuItem,
//   FormControl,
//   Select,
//   InputLabel,
//   Stack,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   FormLabel,
// } from "@mui/material";
// import React, { useEffect, useState, useContext, useRef } from "react";
// import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
// import { useLocation } from "react-router-dom";
// import { UserProfileContext } from "../context/UserContext";
// import { DataGrid } from "@mui/x-data-grid";
// import { useNavigate } from "react-router-dom";
// import { apiService } from "../utils/utils";
// import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
// import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
// function MappingRecommendation() {
//   const location = useLocation();
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const [pageData, setPageData] = useState([]);
//   const [isloading, setIsLoading] = useState(false);
//   const totalPages = useRef(0);
//   const pageType = location.state?.pageType;
//   const data = location.state?.data;
//   const endPoint = location.state?.endPoint;
//   const payload = location.state?.payload;
//   const [currentPageNumber, setCurrentPageNumber] = useState(1);
//   const { userProfile } = useContext(UserProfileContext);
//   const [selectAcceptAll, setSelectAll] = useState(false);
//   const [selectRejectAll, setRejectAll] = useState(false);
//   const [selectedMappedBy, setSelectedMappedBy] = useState("Everyone");
//   const currentbuyerGuideData = JSON.parse(
//     localStorage.getItem("currentbuyerGuideData")
//   );
//   const currentVendorData = JSON.parse(
//     localStorage.getItem("currentVendorData")
//   );
//   const currentOfferData = JSON.parse(localStorage.getItem("currentOfferData"));
//   const [pageLimit, setPageLimit] = useState(10);

//   const HeaderData = {
//     "Buyer Guide": currentbuyerGuideData?.buyerGuideName || "",
//     "Vendor Name": currentVendorData?.vendorName,
//     "Offer Name": currentOfferData?.offerName,
//   };
//   useEffect(() => {
//     totalPages.current = data?.totalPages;
//     setCurrentPageNumber(data?.currentPageNumber);
//     setPageData(() => {
//       return data?.recommendations.map((item, index) => {
//         return { ...item, id: index };
//       });
//     });
//     setIsLoading(false);
//   }, [totalPages, data]);
//   const fetchPagedata = async (pageNumber) => {
//     try {
//       const reponse = await apiService(
//         `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
//         {
//           method: "POST",
//           data: {
//             ...payload,
//             currentPageNumber: pageNumber,
//             recommendationsPerPage: pageLimit,
//           },
//         }
//       );
//       setPageData(() => {
//         return reponse?.recommendations.map((item, index) => {
//           return { ...item, id: index };
//         });
//       });
//     } catch (error) {
//       console.log("request Error :", error);
//     }
//   };
//   const saveData = async () => {
//     try {
//       const reponse = await apiService(
//         "https://newtestfuncpython.azurewebsites.net/api/saveMappingToDB",
//         {
//           method: "POST",
//           data: {
//             email: userProfile?.email,
//             recommendations: {
//               totalPages: totalPages.current,
//               currentPageNumber: currentPageNumber,
//               recommendations: pageData,
//             },
//           },
//         }
//       );
//       return reponse;
//     } catch (error) {
//       console.log("request Error:", error);
//     }
//   };

//   const handlePageChange = async (event, pageNumber) => {
//     let CheckFlag = false;
//     for (let i = 0; i < pageData?.length; i++) {
//       if (
//         !(
//           pageData[i]?.acceptedBy.includes(userProfile?.email) ||
//           pageData[i]?.rejectedBy.includes(userProfile?.email)
//         )
//       ) {
//         CheckFlag = true;
//         break;
//       }
//     }
//     if (CheckFlag) {
//       alert("please Either Accept or Reject all the UseCase");
//       return;
//     } else {
//       const reponse = await saveData();
//       if (reponse) {
//         await fetchPagedata(pageNumber);
//         setCurrentPageNumber(pageNumber);
//       }
//     }
//   };
//   const acceptAll = () => {
//     console.log("selectAcceptAll", selectAcceptAll);
//     setPageData((prev) => {
//       return prev.map((item) => {
//         if (!item.acceptedBy.includes(userProfile?.email) && !selectAcceptAll) {
//           return {
//             ...item,
//             rejectedBy: item?.rejectedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//             acceptedBy: [...item?.acceptedBy, userProfile?.email],
//           };
//         } else if (
//           item.acceptedBy.includes(userProfile?.email) &&
//           selectAcceptAll
//         ) {
//           return {
//             ...item,
//             rejectedBy: item?.rejectedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//             acceptedBy: item?.acceptedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//           };
//         } else {
//           return item;
//         }
//       });
//     });
//   };
//   const rejectAll = () => {
//     setPageData((prev) => {
//       return prev.map((item) => {
//         if (!item.rejectedBy.includes(userProfile?.email) && !selectRejectAll) {
//           return {
//             ...item,
//             rejectedBy: [...item?.rejectedBy, userProfile?.email],
//             acceptedBy: item?.acceptedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//           };
//         } else if (
//           item.rejectedBy.includes(userProfile?.email) &&
//           selectRejectAll
//         ) {
//           return {
//             ...item,
//             rejectedBy: item?.rejectedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//             acceptedBy: item?.acceptedBy?.filter((item) => {
//               return item !== userProfile?.email;
//             }),
//           };
//         } else {
//           return item;
//         }
//       });
//     });
//   };
//   const handleChange = async (event) => {
//     setPageLimit(event.target.value);
//     setIsLoading(true);
//     try {
//       const reponse = await apiService(
//         `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
//         {
//           method: "POST",
//           data: {
//             ...payload,
//             currentPageNumber: currentPageNumber,
//             recommendationsPerPage: event.target.value,
//           },
//         }
//       );
//       totalPages.current = reponse?.totalPages;
//       setPageData(() => {
//         return reponse?.recommendations.map((item, index) => {
//           return { ...item, id: index };
//         });
//       });
//     } catch (error) {
//       console.log("request Error :", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const handleFilterChange = (event)=>{
//      const value = event.target.value;
//      setSelectedMappedBy(value);

//   }
//   const columnDataOverAll = [
//     {
//       field: "accepted",
//       headerName: "Accept",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectAcceptAll}
//             onChange={() => {
//               setSelectAll((prev) => !prev);
//               setRejectAll(false);
//               acceptAll();
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Accept
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         const rowData = pageData[params?.id];
//         const acceptedByCurrentUser = rowData.acceptedBy.includes(userProfile?.email);
  
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={acceptedByCurrentUser}
//               onClick={() => {
//                 if (!acceptedByCurrentUser) {
//                   setRejectAll(false);
//                   setPageData((prev) =>
//                     prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter(
//                             (email) => email !== userProfile?.email
//                           ),
//                           acceptedBy: [...item?.acceptedBy, userProfile?.email],
//                         };
//                       } else {
//                         return item;
//                       }
//                     })
//                   );
//                 } else {
//                   if (selectAcceptAll) {
//                     setSelectAll(false);
//                   }
//                   setPageData((prev) =>
//                     prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           acceptedBy: item?.acceptedBy?.filter(
//                             (email) => email !== userProfile?.email
//                           ),
//                         };
//                       } else {
//                         return item;
//                       }
//                     })
//                   );
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "rejected",
//       headerName: "Rejected",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectRejectAll}
//             onChange={() => {
//               setRejectAll((prev) => !prev);
//               rejectAll();
//               setSelectAll(false);
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Rejected
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         const rowData = pageData[params?.id];
//         const acceptedByOthers = rowData.acceptedBy.some(
//           (email) => email !== userProfile?.email
//         );
//         const rejectedByCurrentUser = rowData.rejectedBy.includes(userProfile?.email);
  
//         // Automatically add the current user to "rejectedBy" if other users are in "acceptedBy"
//         if (acceptedByOthers && !rejectedByCurrentUser) {
//           setPageData((prev) =>
//             prev.map((item) => {
//               if (item?.id === params?.row?.id) {
//                 return {
//                   ...item,
//                   rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                 };
//               } else {
//                 return item;
//               }
//             })
//           );
//         }
  
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={acceptedByOthers || rejectedByCurrentUser}
//               onClick={() => {
//                 if (!rejectedByCurrentUser) {
//                   setPageData((prev) =>
//                     prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                           acceptedBy: item?.acceptedBy?.filter(
//                             (email) => email !== userProfile?.email
//                           ),
//                         };
//                       } else {
//                         return item;
//                       }
//                     })
//                   );
//                 } else {
//                   if (selectRejectAll) {
//                     setRejectAll(false);
//                   }
//                   setPageData((prev) =>
//                     prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter(
//                             (email) => email !== userProfile?.email
//                           ),
//                         };
//                       } else {
//                         return item;
//                       }
//                     })
//                   );
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "useCaseLineage",
//       headerName: "Use Case",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.useCaseLineage });
//       },
//     },
//     {
//       field: "featureLineage",
//       headerName: "Feature",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.featureLineage });
//       },
//     },
//     {
//       field: "acceptedBy",
//       headerName: "Accepted By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].acceptedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].acceptedBy.join(", ");
//       },
//     },
//     {
//       field: "rejectedBy",
//       headerName: "Rejected By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].rejectedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].rejectedBy.join(", ");
//       },
//     },
//   ];
  
  
  
  
  
  
  
//   const columnDataUseCase = [
//     {
//       field: "accepted",
//       headerName: "Accept",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectAcceptAll}
//             onChange={() => {
//               setSelectAll((prev) => {
//                 return !prev;
//               });
//               setRejectAll(false);
//               acceptAll();
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Accept
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                 ) {
//                   setRejectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                           acceptedBy: [...item?.acceptedBy, userProfile?.email],
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectAcceptAll) {
//                     setSelectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "rejected",
//       headerName: "Rejected",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectRejectAll}
//             onChange={() => {
//               setRejectAll((prev) => {
//                 return !prev;
//               });
//               rejectAll();
//               setSelectAll(false);
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Rejected
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                 ) {
//                   setSelectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectRejectAll) {
//                     setRejectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           accepted: false,
//                           rejected: false,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "useCaseLineage",
//       headerName: "Use Case",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.useCaseLineage });
//       },
//     },
//     {
//       field: "featureLineage",
//       headerName: "Feature",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.featureLineage });
//       },
//     },
//     {
//       field: "acceptedBy",
//       headerName: "Accepted By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].acceptedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].acceptedBy.join(", ");
//       },
//     },
//     {
//       field: "rejectedBy",
//       headerName: "Rejected By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].rejectedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].rejectedBy.join(", ");
//       },
//     },
//   ];
//   const columnDataFeature = [
//     {
//       field: "accepted",
//       headerName: "Accept",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectAcceptAll}
//             onChange={() => {
//               setSelectAll((prev) => {
//                 return !prev;
//               });
//               setRejectAll(false);
//               acceptAll();
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Accept
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].acceptedBy.includes(userProfile?.email)
//                 ) {
//                   setRejectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                           acceptedBy: [...item?.acceptedBy, userProfile?.email],
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectAcceptAll) {
//                     setSelectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "rejected",
//       headerName: "Rejected",
//       flex: 0.5,
//       minWidth: 100,
//       maxWidth: 100,
//       renderHeader: () => (
//         <Box display="flex" alignItems="center">
//           <Checkbox
//             checked={selectRejectAll}
//             onChange={() => {
//               setRejectAll((prev) => {
//                 return !prev;
//               });
//               rejectAll();
//               setSelectAll(false);
//             }}
//             color="secondary"
//           />
//           <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
//             Rejected
//           </Typography>
//         </Box>
//       ),
//       renderCell: (params) => {
//         return (
//           <Box display="flex" alignItems="center">
//             <Checkbox
//               checked={
//                 pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                   ? true
//                   : false
//               }
//               onClick={() => {
//                 if (
//                   !pageData[params?.id].rejectedBy.includes(userProfile?.email)
//                 ) {
//                   setSelectAll(false);
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           rejectedBy: [...item?.rejectedBy, userProfile?.email],
//                           acceptedBy: item?.acceptedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 } else {
//                   if (selectRejectAll) {
//                     setRejectAll(false);
//                   }
//                   setPageData((prev) => {
//                     return prev.map((item) => {
//                       if (item?.id === params?.row?.id) {
//                         return {
//                           ...item,
//                           accepted: false,
//                           rejected: false,
//                           rejectedBy: item?.rejectedBy?.filter((item) => {
//                             return item !== userProfile?.email;
//                           }),
//                         };
//                       } else {
//                         return item;
//                       }
//                     });
//                   });
//                 }
//               }}
//             />
//           </Box>
//         );
//       },
//     },
//     {
//       field: "featureLineage",
//       headerName: "Feature",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.featureLineage });
//       },
//     },
//     {
//       field: "useCaseLineage",
//       headerName: "Use Case",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return ShowPath({ value: params?.row?.useCaseLineage });
//       },
//     },

//     {
//       field: "acceptedBy",
//       headerName: "Accepted By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].acceptedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].acceptedBy.join(", ");
//       },
//     },
//     {
//       field: "rejectedBy",
//       headerName: "Rejected By",
//       flex: 0.5,
//       minWidth: 150,
//       renderCell: (params) => {
//         return pageData[params?.id].rejectedBy?.length === 0
//           ? "N/A"
//           : pageData[params?.id].rejectedBy.join(", ");
//       },
//     },
//   ];
//   return (
//     <ThemeProvider theme={theme}>
//       <Box>
//         <HeaderNaming data={HeaderData} />
//         {isloading ? (
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               minHeight: "100vh", // Ensures the loader is centered vertically
//             }}
//           >
//             <CircularProgress />
//           </Box>
//         ) : (
//           <>
//             <Box display="flex" justifyContent={"space-between"}>
//               <Box minWidth={300}>
//                 <Stack direction="row" alignItems="center">
//                   <InputLabel>Select Page Limit : </InputLabel>
//                   <FormControl variant="outlined" sx={{ width: "50%" }}>
//                     <Select
//                       value={pageLimit}
//                       onChange={handleChange}
//                       displayEmpty
//                     >
//                       <MenuItem value={10}>10</MenuItem>
//                       <MenuItem value={20}>20</MenuItem>
//                       <MenuItem value={30}>30</MenuItem>
//                       <MenuItem value={40}>40</MenuItem>
//                       <MenuItem value={50}>50</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Stack>
//               </Box>
//               <Box minWidth={300}>
//                 <Stack direction="row" alignItems="center">
                  
//                   <FormControl value={selectedMappedBy} component="fieldset" sx={{ marginBottom: 2 }}>
//                     <FormLabel component="legend">Mapped by :</FormLabel>
//                     <RadioGroup onChange={handleFilterChange}>
//                       <FormControlLabel
//                         value="Me"
//                         control={<Radio />}
//                         label="Me"
//                       />
//                       <FormControlLabel
//                         value="Everyone"
//                         control={<Radio />}
//                         label="Everyone"
//                       />
                      
//                     </RadioGroup>
//                   </FormControl>
//                 </Stack>
//               </Box>
//             </Box>
//             <DataGrid
//               columns={
//                 pageType === "overAll"
//                   ? columnDataOverAll
//                   : pageType === "specificUseCase"
//                   ? columnDataUseCase
//                   : pageType === "specificFeature"
//                   ? columnDataFeature
//                   : []
//               }
//               rows={selectedMappedBy=== 'Everyone'?pageData: pageData.filter((item)=>{  return item.acceptedBy?.includes(userProfile?.email)||item.rejectedBy?.includes(userProfile?.email)})}
//               hideFooter
//               sx={{
//                 "& .super-app-theme--header": {
//                   backgroundColor: theme.palette.primary.main,
//                   color: "white",
//                   fontSize: "14px",
//                   ".MuiSvgIcon-root": {
//                     color: "white",
//                   },
//                 },
//                 "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
//                   backgroundColor: theme.palette.primary.main,
//                   color: "white",
//                 },
//                 "& .MuiDataGrid-columnHeaderTitle": {
//                   fontWeight: "normal",
//                 },
//                 "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
//                   outline: "none !important",
//                 },
//               }}
//             />
//             <Pagination
//               count={totalPages?.current}
//               page={currentPageNumber}
//               color="primary"
//               onChange={async (event, pageNumber) => {
//                 await handlePageChange(event, pageNumber);
//               }}
//               sx={{ mt: 2, display: "flex", justifyContent: "center" }}
//             />
//             <Box sx={{ marginTop: "20px" }}>
//               <Button
//                 sx={{ width: "300px" }}
//                 color="primary"
//                 variant="contained"
//                 disableRipple
//                 onClick={async () => {
//                   const reponse = await saveData();
//                   if (reponse) {
//                     navigate("/usecase-to-feature");
//                   }
//                 }}
//               >
//                 Save & Exit to Main Mapping Page
//               </Button>
//             </Box>
//           </>
//         )}
//       </Box>
//     </ThemeProvider>
//   );
// }

// export default MappingRecommendation;

// const ShowPath = (params) => {
//   const [showCell, setShowCell] = useState(false);
//   const { value } = params;
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "flex-start",
//         alignItems: "center",
//         height: "100%",
//         width: "100%",
//         overflowY: "hidden",
//         gap: "0px 10px",
//       }}
//     >
//       <Box>
//         <IconButton
//           onClick={() => {
//             setShowCell(!showCell);
//           }}
//         >
//           {showCell ? (
//             <KeyboardDoubleArrowLeftIcon />
//           ) : (
//             <KeyboardDoubleArrowRightIcon />
//           )}
//         </IconButton>
//       </Box>
//       <Box>{showCell ? value.join(" > ") : value[value.length - 1]}</Box>
//     </Box>
//   );
// };

// test2....



import {
  Box,
  useTheme,
  ThemeProvider,
  Checkbox,
  Pagination,
  CircularProgress,
  Button,
  Typography,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Stack,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import React, { useEffect, useState, useContext, useRef } from "react";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { useLocation } from "react-router-dom";
import { UserProfileContext } from "../context/UserContext";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { apiService } from "../utils/utils";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
function MappingRecommendation() {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const totalPages = useRef(0);
  const pageType = location.state?.pageType;
  const data = location.state?.data;
  const endPoint = location.state?.endPoint;
  const payload = location.state?.payload;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const { userProfile } = useContext(UserProfileContext);
  const [selectAcceptAll, setSelectAll] = useState(false);
  const [selectRejectAll, setRejectAll] = useState(false);
  const [selectedMappedBy, setSelectedMappedBy] = useState("Everyone");
  const currentbuyerGuideData = JSON.parse(
    localStorage.getItem("currentbuyerGuideData")
  );
  const currentVendorData = JSON.parse(
    localStorage.getItem("currentVendorData")
  );
  const currentOfferData = JSON.parse(localStorage.getItem("currentOfferData"));
  const [pageLimit, setPageLimit] = useState(10);

  const HeaderData = {
    "Buyer Guide": currentbuyerGuideData?.buyerGuideName || "",
    "Vendor Name": currentVendorData?.vendorName,
    "Offer Name": currentOfferData?.offerName,
  };
  useEffect(() => {
    totalPages.current = data?.totalPages;
    setCurrentPageNumber(data?.currentPageNumber);
    setPageData(() => {
      return data?.recommendations.map((item, index) => {
        return { ...item, id: index };
      });
    });
    setIsLoading(false);
  }, [totalPages, data]);
  const fetchPagedata = async (pageNumber) => {
    try {
      const reponse = await apiService(
        `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
        {
          method: "POST",
          data: {
            ...payload,
            currentPageNumber: pageNumber,
            recommendationsPerPage: pageLimit,
          },
        }
      );
      setPageData(() => {
        return reponse?.recommendations.map((item, index) => {
          return { ...item, id: index };
        });
      });
    } catch (error) {
      console.log("request Error :", error);
    }
  };
  const saveData = async () => {
    try {
      const reponse = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/saveMappingToDB",
        {
          method: "POST",
          data: {
            email: userProfile?.email,
            recommendations: {
              totalPages: totalPages.current,
              currentPageNumber: currentPageNumber,
              recommendations: pageData,
            },
          },
        }
      );
      return reponse;
    } catch (error) {
      console.log("request Error:", error);
    }
  };

  const handlePageChange = async (event, pageNumber) => {
    let CheckFlag = false;
    for (let i = 0; i < pageData?.length; i++) {
      if (
        !(
          pageData[i]?.acceptedBy.includes(userProfile?.email) ||
          pageData[i]?.rejectedBy.includes(userProfile?.email)
        )
      ) {
        CheckFlag = true;
        break;
      }
    }
    if (CheckFlag) {
      alert("please Either Accept or Reject all the UseCase");
      return;
    } else {
      const reponse = await saveData();
      if (reponse) {
        await fetchPagedata(pageNumber);
        setCurrentPageNumber(pageNumber);
      }
    }
  };
  const acceptAll = () => {
    console.log("selectAcceptAll", selectAcceptAll);
    setPageData((prev) => {
      return prev.map((item) => {
        if (!item.acceptedBy.includes(userProfile?.email) && !selectAcceptAll) {
          return {
            ...item,
            rejectedBy: item?.rejectedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
            acceptedBy: [...item?.acceptedBy, userProfile?.email],
          };
        } else if (
          item.acceptedBy.includes(userProfile?.email) &&
          selectAcceptAll
        ) {
          return {
            ...item,
            rejectedBy: item?.rejectedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
            acceptedBy: item?.acceptedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
          };
        } else {
          return item;
        }
      });
    });
  };
  const rejectAll = () => {
    setPageData((prev) => {
      return prev.map((item) => {
        if (!item.rejectedBy.includes(userProfile?.email) && !selectRejectAll) {
          return {
            ...item,
            rejectedBy: [...item?.rejectedBy, userProfile?.email],
            acceptedBy: item?.acceptedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
          };
        } else if (
          item.rejectedBy.includes(userProfile?.email) &&
          selectRejectAll
        ) {
          return {
            ...item,
            rejectedBy: item?.rejectedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
            acceptedBy: item?.acceptedBy?.filter((item) => {
              return item !== userProfile?.email;
            }),
          };
        } else {
          return item;
        }
      });
    });
  };
  const handleChange = async (event) => {
    setPageLimit(event.target.value);
    setIsLoading(true);
    try {
      const reponse = await apiService(
        `https://newtestfuncpython.azurewebsites.net/api/${endPoint}`,
        {
          method: "POST",
          data: {
            ...payload,
            currentPageNumber: currentPageNumber,
            recommendationsPerPage: event.target.value,
          },
        }
      );
      totalPages.current = reponse?.totalPages;
      setPageData(() => {
        return reponse?.recommendations.map((item, index) => {
          return { ...item, id: index };
        });
      });
    } catch (error) {
      console.log("request Error :", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFilterChange = (event)=>{
     const value = event.target.value;
     setSelectedMappedBy(value);

  }
  const columnDataOverAll = [
    {
      field: "accepted",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAcceptAll}
            onChange={() => {
              setSelectAll((prev) => !prev);
              setRejectAll(false);
              acceptAll();
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isAcceptedByCurrentUser = pageData[params?.id].acceptedBy.includes(userProfile?.email);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isAcceptedByCurrentUser}
              onChange={() => {
                setPageData((prev) => {
                  return prev.map((item) => {
                    if (item?.id === params?.row?.id) {
                      if (isAcceptedByCurrentUser) {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((email) => email !== userProfile?.email),
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                        };
                      } else {
                        return {
                          ...item,
                          acceptedBy: [...item?.acceptedBy, userProfile?.email],
                          rejectedBy: item?.rejectedBy?.filter((email) => email !== userProfile?.email),
                        };
                      }
                    }
                    return item;
                  });
                });
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectRejectAll}
            onChange={() => {
              setRejectAll((prev) => !prev);
              rejectAll();
              setSelectAll(false);
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Rejected
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isRejectedByCurrentUser = pageData[params?.id].rejectedBy.includes(userProfile?.email);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isRejectedByCurrentUser}
              onChange={() => {
                setPageData((prev) => {
                  return prev.map((item) => {
                    if (item?.id === params?.row?.id) {
                      // Toggle rejection for the current user
                      if (isRejectedByCurrentUser) {
                        return {
                          ...item,
                          rejectedBy: item?.rejectedBy?.filter((email) => email !== userProfile?.email),
                        };
                      } else {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((email) => email !== userProfile?.email),
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                        };
                      }
                    }
                    return item;
                  });
                });
              }}
            />
          </Box>
        );
      },
    },
    // ... other columns
  // ];
  
    // {
    //   field: "rejected",
    //   headerName: "Rejected",
    //   flex: 0.5,
    //   minWidth: 100,
    //   maxWidth: 100,
    //   renderHeader: () => (
    //     <Box display="flex" alignItems="center">
    //       <Checkbox
    //         checked={selectRejectAll}
    //         onChange={() => {
    //           setRejectAll((prev) => {
    //             return !prev;
    //           });
    //           rejectAll();
    //           setSelectAll(false);
    //         }}
    //         color="secondary"
    //       />
    //       <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
    //         Rejected
    //       </Typography>
    //     </Box>
    //   ),
    //   renderCell: (params) => {
    //     return (
    //       <Box display="flex" alignItems="center">
    //         <Checkbox
    //           checked={
    //             pageData[params?.id].rejectedBy.includes(userProfile?.email)
    //               ? true
    //               : false
    //           }
    //           onClick={() => {
    //             if (
    //               !pageData[params?.id].rejectedBy.includes(userProfile?.email)
    //             ) {
    //               setSelectAll(false);
    //               setPageData((prev) => {
    //                 return prev.map((item) => {
    //                   if (item?.id === params?.row?.id) {
    //                     return {
    //                       ...item,
    //                       rejectedBy: [...item?.rejectedBy, userProfile?.email],
    //                       acceptedBy: item?.acceptedBy?.filter((item) => {
    //                         return item !== userProfile?.email;
    //                       }),
    //                     };
    //                   } else {
    //                     return item;
    //                   }
    //                 });
    //               });
    //             } else {
    //               if (selectRejectAll) {
    //                 setRejectAll(false);
    //               }
    //               setPageData((prev) => {
    //                 return prev.map((item) => {
    //                   if (item?.id === params?.row?.id) {
    //                     return {
    //                       ...item,
    //                       accepted: false,
    //                       rejected: false,
    //                       rejectedBy: item?.rejectedBy?.filter((item) => {
    //                         return item !== userProfile?.email;
    //                       }),
    //                     };
    //                   } else {
    //                     return item;
    //                   }
    //                 });
    //               });
    //             }
    //           }}
    //         />
    //       </Box>
    //     );
    //   },
    // },

    {
      field: "useCaseLineage",
      headerName: "Use Case",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return ShowPath({ value: params?.row?.useCaseLineage });
      },
    },
    {
      field: "featureLineage",
      headerName: "Feature",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return ShowPath({ value: params?.row?.featureLineage });
      },
    },
    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].acceptedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].acceptedBy.join(", ");
      },
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].rejectedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].rejectedBy.join(", ");
      },
    },
  ];
  const columnDataUseCase = [
    {
      field: "accepted",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAcceptAll}
            onChange={() => {
              setSelectAll((prev) => !prev);
              setRejectAll(false);
              acceptAll();
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isAcceptedByCurrentUser = pageData[params?.id].acceptedBy.includes(userProfile?.email);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isAcceptedByCurrentUser}
              onChange={() => {
                setPageData((prev) => {
                  return prev.map((item) => {
                    if (item?.id === params?.row?.id) {
                      // Toggle acceptance for the current user
                      if (isAcceptedByCurrentUser) {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((email) => email !== userProfile?.email),
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                        };
                      } else {
                        return {
                          ...item,
                          acceptedBy: [...item?.acceptedBy, userProfile?.email],
                          rejectedBy: item?.rejectedBy?.filter((email) => email !== userProfile?.email),
                        };
                      }
                    }
                    return item;
                  });
                });
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectRejectAll}
            onChange={() => {
              setRejectAll((prev) => !prev);
              rejectAll();
              setSelectAll(false);
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Rejected
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isRejectedByCurrentUser = pageData[params?.id].rejectedBy.includes(userProfile?.email);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isRejectedByCurrentUser}
              onChange={() => {
                setPageData((prev) => {
                  return prev.map((item) => {
                    if (item?.id === params?.row?.id) {
                      // Toggle rejection for the current user
                      if (isRejectedByCurrentUser) {
                        return {
                          ...item,
                          rejectedBy: item?.rejectedBy?.filter((email) => email !== userProfile?.email),
                        };
                      } else {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((email) => email !== userProfile?.email),
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                        };
                      }
                    }
                    return item;
                  });
                });
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "useCaseLineage",
      headerName: "Use Case",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return ShowPath({ value: params?.row?.useCaseLineage });
      },
    },
    {
      field: "featureLineage",
      headerName: "Feature",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return ShowPath({ value: params?.row?.featureLineage });
      },
    },
    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].acceptedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].acceptedBy.join(", ");
      },
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].rejectedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].rejectedBy.join(", ");
      },
    },
  ];
  
  const columnDataFeature = [
    {
      field: "accepted",
      headerName: "Accept",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectAcceptAll}
            onChange={() => {
              setSelectAll((prev) => !prev);
              setRejectAll(false);
              acceptAll();
            }}
            color="secondary"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Accept
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isAcceptedByCurrentUser = pageData[params?.id].acceptedBy.includes(userProfile?.email);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isAcceptedByCurrentUser}
              onChange={() => {
                setPageData((prev) => {
                  return prev.map((item) => {
                    if (item?.id === params?.row?.id) {
                      // Toggle acceptance for the current user
                      if (isAcceptedByCurrentUser) {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((email) => email !== userProfile?.email),
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                        };
                      } else {
                        return {
                          ...item,
                          acceptedBy: [...item?.acceptedBy, userProfile?.email],
                          rejectedBy: item?.rejectedBy?.filter((email) => email !== userProfile?.email),
                        };
                      }
                    }
                    return item;
                  });
                });
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 0.5,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: () => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={selectRejectAll}
            onChange={() => {
              setRejectAll((prev) => !prev);
              rejectAll();
              setSelectAll(false);
            }}
            color="#40BAB4"
          />
          <Typography variant="body2" style={{ marginLeft: 8, color: "white" }}>
            Rejected
          </Typography>
        </Box>
      ),
      renderCell: (params) => {
        const isRejectedByCurrentUser = pageData[params?.id].rejectedBy.includes(userProfile?.email);
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isRejectedByCurrentUser}
              onChange={() => {
                setPageData((prev) => {
                  return prev.map((item) => {
                    if (item?.id === params?.row?.id) {
                      // Toggle rejection for the current user
                      if (isRejectedByCurrentUser) {
                        return {
                          ...item,
                          rejectedBy: item?.rejectedBy?.filter((email) => email !== userProfile?.email),
                        };
                      } else {
                        return {
                          ...item,
                          acceptedBy: item?.acceptedBy?.filter((email) => email !== userProfile?.email),
                          rejectedBy: [...item?.rejectedBy, userProfile?.email],
                        };
                      }
                    }
                    return item;
                  });
                });
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "featureLineage",
      headerName: "Feature",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return ShowPath({ value: params?.row?.featureLineage });
      },
    },
    {
      field: "useCaseLineage",
      headerName: "Use Case",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return ShowPath({ value: params?.row?.useCaseLineage });
      },
    },
    {
      field: "acceptedBy",
      headerName: "Accepted By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].acceptedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].acceptedBy.join(", ");
      },
    },
    {
      field: "rejectedBy",
      headerName: "Rejected By",
      flex: 0.5,
      minWidth: 150,
      renderCell: (params) => {
        return pageData[params?.id].rejectedBy?.length === 0
          ? "N/A"
          : pageData[params?.id].rejectedBy.join(", ");
      },
    },
  ];
  
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <HeaderNaming data={HeaderData} />
        {isloading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "100vh", // Ensures the loader is centered vertically
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box display="flex" justifyContent={"space-between"}>
              <Box minWidth={300}>
                <Stack direction="row" alignItems="center">
                  <InputLabel>Select Page Limit : </InputLabel>
                  <FormControl variant="outlined" sx={{ width: "50%" }}>
                    <Select
                      value={pageLimit}
                      onChange={handleChange}
                      displayEmpty
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={30}>30</MenuItem>
                      <MenuItem value={40}>40</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Box>
              <Box minWidth={300}>
                <Stack direction="row" alignItems="center">
                  
                  <FormControl value={selectedMappedBy} component="fieldset" sx={{ marginBottom: 2 }}>
                    <FormLabel component="legend">Mapped by :</FormLabel>
                    <RadioGroup onChange={handleFilterChange}>
                      <FormControlLabel
                        value="Me"
                        control={<Radio />}
                        label="Me"
                      />
                      <FormControlLabel
                        value="Everyone"
                        control={<Radio />}
                        label="Everyone"
                      />
                      
                    </RadioGroup>
                  </FormControl>
                </Stack>
              </Box>
            </Box>
            <DataGrid
              columns={
                pageType === "overAll"
                  ? columnDataOverAll
                  : pageType === "specificUseCase"
                  ? columnDataUseCase
                  : pageType === "specificFeature"
                  ? columnDataFeature
                  : []
              }
              rows={selectedMappedBy=== 'Everyone'?pageData: pageData.filter((item)=>{  return item.acceptedBy?.includes(userProfile?.email)||item.rejectedBy?.includes(userProfile?.email)})}
              hideFooter
              sx={{
                "& .super-app-theme--header": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                  fontSize: "14px",
                  ".MuiSvgIcon-root": {
                    color: "white",
                  },
                },
                "& .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaders": {
                  backgroundColor: theme.palette.primary.main,
                  color: "white",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "normal",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
              }}
            />
            <Pagination
              count={totalPages?.current}
              page={currentPageNumber}
              color="primary"
              onChange={async (event, pageNumber) => {
                await handlePageChange(event, pageNumber);
              }}
              sx={{ mt: 2, display: "flex", justifyContent: "center" }}
            />
            <Box sx={{ marginTop: "20px" }}>
              <Button
                sx={{ width: "300px" }}
                color="primary"
                variant="contained"
                disableRipple
                onClick={async () => {
                  const reponse = await saveData();
                  if (reponse) {
                    navigate("/usecase-to-feature");
                  }
                }}
              >
                Save & Exit to Main Mapping Page
              </Button>
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default MappingRecommendation;

const ShowPath = (params) => {
  const [showCell, setShowCell] = useState(false);
  const { value } = params;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflowY: "hidden",
        gap: "0px 10px",
      }}
    >
      <Box>
        <IconButton
          onClick={() => {
            setShowCell(!showCell);
          }}
        >
          {showCell ? (
            <KeyboardDoubleArrowLeftIcon />
          ) : (
            <KeyboardDoubleArrowRightIcon />
          )}
        </IconButton>
      </Box>
      <Box>{showCell ? value.join(" > ") : value[value.length - 1]}</Box>
    </Box>
  );
};
