import React from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Box } from "@mui/material";

const CustomName = ({ name, isError }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "left",
      }}
    >
      {isError && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <WarningAmberRoundedIcon color="warning" style={{ marginRight: "20px" }} />
          <span>{name}</span>
        </Box>
      )}
      {!isError && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            overflowX: "auto", // Enable horizontal scrolling
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbar for WebKit browsers (Chrome, Safari)
            },
            "-ms-overflow-style": "none", // Hide scrollbar in Internet Explorer and Edge
            "scrollbar-width": "none", // Hide scrollbar in Firefox
          }}
        >
          <span>{name}</span>
        </Box>
      )}
    </Box>
  );
};

export default CustomName;
