import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import theme from "../../theme/theme";

const NumberInputAvailableUnitsOfAllocation = ({
  isDisabled,
  max,
  rowData,
  inputsLabel,
  totalLabel,
  onSet,
  page,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (inputsLabel !== "totalUnitsInput" && rowData[inputsLabel] === 0)
      setValue("User Defined");
    else setValue(rowData[inputsLabel]);
  }, [rowData, inputsLabel]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    const numVlue = Number(value);

    if (numVlue >= 0 && value !== "") {
      //

      // if same value enterted nothing will rerender

      //

      if (numVlue !== rowData[inputsLabel]) {
        if (numVlue > max) rowData[inputsLabel] = max;
        else rowData[inputsLabel] = numVlue;

        if (inputsLabel === "userUnitsInput" && rowData[inputsLabel] === 0) {
          rowData[inputsLabel] = 1;
        }
        setValue(rowData[inputsLabel]);

        // Disabling other Cells when there is an Error into current cell
        //
        const prod = [
          "userUnitsInput",
          "endUserOrganizationUnitsInput",
          "buyerGuideUnitsInput",
        ].reduce((acc, unit) => {
          const total = rowData[unit] > 0 ? rowData[unit] : 1;
          return acc * total;
        }, 1);

        if (
          inputsLabel !== "totalUnitsInput" &&
          rowData["totalUnitsInput"] > 0 &&
          rowData[inputsLabel] > 0
        ) {
          if (prod !== 1 && rowData["totalUnitsInput"] % prod !== 0)
            rowData["errorInputsLabel"] = inputsLabel;
          else rowData["errorInputsLabel"] = null;
        }

        //

        onSet();
      }
      // when 0 is input value in Total Units
      if (inputsLabel !== "totalUnitsInput" && numVlue === 0)
        setValue("User Defined");
    } else {
      //
      if (
        (value === "" || value === "User Defined" || value < 0) &&
        rowData[inputsLabel] !== 0
      ) {
        rowData[inputsLabel] = 0;

        if (inputsLabel === "userUnitsInput" && rowData[inputsLabel] === 0) {
          rowData[inputsLabel] = 1;
        }

        onSet();
      }

      // For restriction against -ve values when rowData[inputsLabel] === 0
      inputsLabel === "totalUnitsInput"
        ? setValue(0)
        : setValue("User Defined");
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {(max === 0 ||
        isDisabled ||
        (rowData["totalUnitsInput"] === 0 &&
          inputsLabel !== "totalUnitsInput")) && (
        <Tooltip
          title={"Please Enter Total Units For Allocation"}
          componentsProps={{
            tooltip: {
              sx: {
                background: theme.palette.secondary.main,
              },
            },
          }}
        >
          <TextField
            variant="standard"
            color="secondary"
            sx={{
              width: value?.length === 12 ? "90px" : "35px",
              paddingX: "auto",

              "& .css-1w7v89a-MuiInputBase-root-MuiInput-root.Mui-disabled:before ":
                {
                  borderBottom: "none",
                },
            }}
            value={value}
            size="small"
            disabled
            InputProps={{
              inputProps: {
                style: { textAlign: "center" },
              },
            }}
          />
        </Tooltip>
      )}
      {rowData.errorInputsLabel === inputsLabel && (
        <Tooltip
          title={
            "Please Enter the Value as factor of " + rowData.totalUnitsInput
          }
          componentsProps={{
            tooltip: {
              sx: {
                background: theme.palette.secondary.main,
              },
            },
          }}
        >
          <WarningAmberRoundedIcon
            color="warning"
            style={{ marginRight: "20px" }}
          />
        </Tooltip>
      )}
      {max > 0 &&
        !isDisabled &&
        page === "avalable_for_allocation" &&
        ((inputsLabel !== "totalUnitsInput" &&
          rowData["totalUnitsInput"] > 0) ||
          inputsLabel === "totalUnitsInput") && (
          <TextField
            autoComplete="off"
            variant="standard"
            color="secondary"
            sx={{
              width:
                value?.length === 12 && page === "avalable_for_allocation"
                  ? "90px"
                  : "35px",
              paddingX: "auto",

              "& .MuiInputBase-input": {
                // WebkitTextFillColor: 'black',
              },
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "2px solid",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "2px solid",
              },
            }}
            value={value}
            onChange={handleChange}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={handleBlur}
            size="small"
            InputProps={{
              inputProps: {
                style: { textAlign: "center" },
              },
            }}
          />
        )}
      {page === "avalable_for_allocation" && rowData[totalLabel] !== null && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              marginX: "4px",
              // fontFamily: theme.typography.h4,
            }}
          >
            /
          </Typography>
          <Typography>{rowData[totalLabel]}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NumberInputAvailableUnitsOfAllocation;
