import { Box  , CircularProgress} from '@mui/material'
import React from 'react'
import ExecutiveCommentry from './ExecutiveCommentry'
function ExecutiveSummary({commentary,executiveSummarydetail , setExecutiveSummary}) {
  return (
    <Box>
      {!commentary?.smartData?.quadrantSummary?.isLoading &&
      !commentary?.premiumData?.quadrantSummary?.isLoading &&
      !commentary?.budgetData?.quadrantSummary?.isLoading &&
      !commentary?.lowData?.quadrantSummary?.isLoading ? (
        <ExecutiveCommentry
          executiveSummarydetail={executiveSummarydetail}
          setExecutiveSummary={setExecutiveSummary}
          commentary={commentary}
        />
      ) : (
        <Box mt={4} textAlign="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default ExecutiveSummary