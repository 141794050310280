import React, {  useContext } from "react";
import "./styles/MainDashBoard.css";
import HeaderNaming from "../../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../../context/UserContext.jsx";
import ComparativeAnalysisWidgets from "../../Widget/ComparativeAnalysisWidget/ComparativeAnalysisWidgets.jsx";
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const ComparativeAnalysis = () => {
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    currentPackage,
    notification,
    handleCloseNotification,
  } = useContext(UserProfileContext);
  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
    "Purchased Package": currentPackage?.package_type,
  };
  return (
    <>
      <HeaderNaming data={data} />
      {/* @ComparativeAnalysisWidgets: Parent component responsible for rendering and managing the layout of all widgets on the comparative analysis page */}
      <ComparativeAnalysisWidgets />

      {/* notification */}
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <MuiAlert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ComparativeAnalysis;
