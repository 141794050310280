import React, { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { apiService } from "../../utils/utils";
import generateData from "../../utils/Allocation/generateData";
import generatePayload from "../../utils/Allocation/generatePayload";
import { UserProfileContext } from "../../context/UserContext";
import AllocationOfUserUnits from "./AllocationOfUnits";

const AdminAllocation = () => {
  const { userProfile } = useContext(UserProfileContext);
  // Stores the message from backend about the Allocation Endpoint
  const [purchagedPackageAllocation, setPurchagedPackageAllocation] = useState([]);
  // Shows circular Progress unit Allocation Endpoint does the request
  const [isLoading, setIsLoading] = useState(false);
  // Stores the final data for Calling the Endpoint at the end of Allocation flow
  const [payload, setPayload] = useState({});
  //Stores radio data of "Availabel Units For Allocation" page
  const [radioData, setRadioData] = useState([]);
  // Stores name of pages previously visited and yet to visit and idx is used to navigate between them
  const [pageData, setPagesData] = useState(["avalableForAllocation"]);
  const [idx, setIdx] = useState(1);
  const navigate = useNavigate();
  const availableUnitsForAllocation = JSON.parse(
    sessionStorage.getItem("availableUnitsForAllocation")
  );

  // Does the api Calling For Allocation whenever payload changes
  const reqest = useCallback(() => {
    if (Object.keys(payload)?.length > 0) {
      apiService(
        `https://newtestfuncpython.azurewebsites.net/api/allocatePurchasedPackage?`,
        {
          method: "POST",
          data: JSON.stringify(payload),
        },
        setIsLoading,
        setPurchagedPackageAllocation,
        (err) => {
          console.log(err);
        }
      );
    }
    // console.log("purchased package allocation", purchagedPackageAllocation);
  }, [payload]);

  // Checks for Changes in Radiodata and PageData
  useEffect(() => {
    // Check if no element in radioData has `prescription` as false and exists in pageData
    if (!radioData?.some((el) => el.prescription === false && pageData?.includes(el.page))) {
      // If the above condition is true, generate a payload using userProfile, pageData, and availableUnitsForAllocation
      const generatedPayload = generatePayload(userProfile, pageData, availableUnitsForAllocation);

      // If the generated payload contains any keys, update the payload state
      if (Object.keys(generatedPayload)?.length > 0) {
        setPayload(generatedPayload); // Set the payload state for further processing
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radioData, pageData]); // Re-run this effect whenever radioData or pageData changes

  useEffect(() => {
    reqest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    const radioDataFromSession = JSON.parse(sessionStorage.getItem("radioData"));
    setRadioData(radioDataFromSession);

    const newPrescribedPages =
      radioDataFromSession
        ?.filter(
          (el) =>
            el.value === "prescibe" && el.prescription === false && !pageData?.includes(el.page)
        )
        ?.map((el) => el.page) || [];

    setPagesData((prev) => [...prev, ...newPrescribedPages]);
    const currentIndex = JSON.parse(sessionStorage.getItem("idx"));
    if (currentIndex) setIdx(currentIndex);

    return () => {
      pageData?.forEach((el, idx) => {
        if (idx > 0) sessionStorage.removeItem(el);
      });
    };
  }, [pageData]);

  useEffect(() => {
    if (
      idx > 0 &&
      idx < pageData?.length &&
      availableUnitsForAllocation?.length > 0 &&
      availableUnitsForAllocation?.some((el) => el.totalUnitsInput > 0)
    ) {
      const pageUrlMapping = {
        userUnits: "user-units",
        endUserOrganizationUnits: "end-user-organization-units",
        buyerGuideUnits: "buyer-guide-units",
        unlockedVendors: "unlocked-vendors",
      };
      const page = pageUrlMapping[pageData[idx]];

      navigate(`/admin-allocation/${page}`, { replace: true });
    }
  }, [navigate, pageData, idx, userProfile, radioData,availableUnitsForAllocation]);

  if (isLoading)
    return (
      <Box mt={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  console.log("admin-allocation");
  console.log("pageData", pageData);
  if (idx === 0) {
    pageData?.forEach((el, idx) => {
      if (idx > 0) sessionStorage.removeItem(el);
    });
    sessionStorage.removeItem("idx");
    sessionStorage.removeItem("latestRadioData");
    navigate("/available-units-for-allocation");
  } else if (
    pageData?.length > idx &&
    availableUnitsForAllocation?.length > 0 &&
    availableUnitsForAllocation?.some((el) => el.totalUnitsInput > 0)
  ) {
    const pageRowData = generateData(userProfile, pageData[idx], availableUnitsForAllocation);

    return (
      <AllocationOfUserUnits
        idx={idx}
        page={pageData[idx]}
        rowData={pageRowData}
        setIdx={setIdx}
        setRadioData={setRadioData}
      />
    );
  } else if (Object.keys(payload)?.length > 0) {
    if (purchagedPackageAllocation.message) {
      pageData?.forEach((el, idx) => {
        if (idx > 0) sessionStorage.removeItem(el);
      });
      sessionStorage.removeItem("idx");
      sessionStorage.removeItem("radioData");
      sessionStorage.removeItem("latestRadioData");
      sessionStorage.removeItem("availableUnitsForAllocation");

      return <Box>{purchagedPackageAllocation.message}</Box>;
    }

    return <Box>Server is Not Responding open console for errors</Box>;
  }

  return (
    <Box sx={{ background: "red" }}>
      Error: Please give valid input to "Available Units for Allocation" page
    </Box>
  );
};

export default AdminAllocation;
