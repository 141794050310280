import React, {
  useContext,
  useState,
  useCallback,
  memo,
  Suspense,
  useEffect,
  lazy,
  useMemo,
  useRef,
} from "react";
import {
  Typography,
  Box,
  Collapse,
  IconButton,
  TextField,
  Button,
  Tooltip,
  CircularProgress,
  ThemeProvider,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import HeaderNaming from "../components/HeaderNaming/HeaderNaming";
import { useLocation, useNavigate } from "react-router-dom";
import { toPng } from "html-to-image";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { UserProfileContext } from "../context/UserContext";
import OfferSummery from "./Widget/ViewReport/OfferSummery";
import QuadrantSummary from "./Widget/ViewReport/QuadrantSummary";
import theme from "../theme/theme";
import { BlobServiceClient } from "@azure/storage-blob";
import axios from "axios";
import ExecutiveSummary from "./Widget/ViewReport/ExecutiveSummary";
import { apiService } from "../utils/utils";

const ChartComponent = lazy(() =>
  import("./Widget/ComparativeAnalysisWidget/ChartComponent")
);
const MandatoryUseCaseTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/MandatoryUseCaseTable")
);
const OverallUseCasesTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/OverallUseCasesTable")
);
const SupportedUseCaseOverallTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/SupportedUseCaseOverallTable")
);
const UnusedGenericTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnusedGenericTable")
);
const UnusedNotRequiredTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnusedNotRequiredTable")
);
const UnsupportedUseCaseMandatoryTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnsupportedUseCaseMandatoryTable")
);
const UnsupportedUseCaseOverallTable = lazy(() =>
  import("./Widget/OfferDetailsWidget/UnsupportedUseCaseOverallTable")
);

const sections = [
  "ExecutiveSummary",
  "SmartValyou",
  "PremiumValyou",
  "BudgetValyou",
  "LowValyou",
];
const fields = [
  "offerMetrics",
  "supportedUseCases",
  "unsupportedUseCases",
  "unusedFeatures",
];
const fieldTables = {
  offerMetrics: ["mandatory", "overall"],
  supportedUseCases: ["overall"],
  unsupportedUseCases: ["mandatory", "overall"],
  unusedFeatures: ["generic", "notRequired"],
};
const fieldsHeading = {
  offerMetrics: "OfferMetrics",
  supportedUseCases: "SupportedUseCases",
  unsupportedUseCases: "UnsupportedUseCases",
  unusedFeatures: "UnusedFeatures",
};

const ViewReport = () => {
  const date = new Date().toLocaleString();
  const {
    userProfile,
    primaryMetric,
    smartDataForStaticData,
    lowDataForStaticData,
    budgetDataForStaticData,
    premiumDataForStaticData,
    initialData,
    isLoading,
    budgetLoading,
    premiumLoading,
    lowLoading,
    currentOrganization,
    currentOrganizationId,
    scenarioName,
    currentPackage,
    currentBuyerGuideId,
    currentScenarioId,
    buyerGuideName,
    valyouMatrixData,
    currentOrg,
  } = useContext(UserProfileContext);
  const navigate = useNavigate();
  const companyName = userProfile?.companyName;
  const contentRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [reportName, setReportName] = useState("");
  const [isReportLoading, setReportLoading] = useState(false);
  const location = useLocation();
  const reportdata = location?.state?.reportdata;

  const initialAccordionState = useMemo(
    () =>
      sections.reduce(
        (acc, section) => ({
          ...acc,
          [section]: section === "ExecutiveSummary",
        }),
        {}
      ),
    []
  );
  const onChangeReportName = (event) => {
    setReportName(event.target.value);
  };

  const commentaryTemplate = useMemo(
    () => ({
      commentary: "...loading",
      prompt: "",
      aiCommentary: "",
      showBox: false,
      isLoading: true,
    }),
    []
  );
  const [executiveSummarydetail, setExecutiveSummary] =
    useState(commentaryTemplate);
  const handleExport = async () => {
    setAccordionState((prevState) => ({
      ...prevState,
      ExecutiveSummary: true,
      SmartValyou: true,
      PremiumValyou: true,
      BudgetValyou: true,
      LowValyou: true,
    }));
    const styleSheets = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          if (e.name !== "SecurityError") {
            console.error("Failed to access stylesheet:", e.message);
          }
          return "";
        }
      })
      .join("\n");

    const dataUrl = await toPng(document.getElementById("blob"));
    var img = new Image();
    img.src = dataUrl;
    img.alt = "Exported content";
    const htmlFile = document.getElementById("htmlContent");
    const newHTMLFile = htmlFile.cloneNode(true);
    const targetElement = newHTMLFile.querySelector("#blob");
    if (targetElement) {
      setReportLoading(true);
      targetElement.innerHTML = "";
      img.style.maxWidth = "100%"; // Ensure the image fits within the container
      img.style.height = "auto"; // Maintain aspect ratio
      img.style.objectFit = "contain"; // Ensure it scales without distortion
      img.alt = "Exported content";
      targetElement.appendChild(img);

      const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <style>
          ${styleSheets}
          img {
            max-width: 100%;   /* Ensure the image does not overflow */
            height: auto;      /* Maintain aspect ratio */
            object-fit: contain; /* Ensure the image scales well within the container */
          }
        </style>
    </head>
    <body>
        <div>${newHTMLFile.innerHTML}</div>
    </body>
    </html>
  `;

      try {
        const writeResponse = await axios.get(
          "https://newtestfuncpython.azurewebsites.net/api/generateWriteSasToken"
        );

        const blobServiceClient = BlobServiceClient.fromConnectionString(
          `BlobEndpoint=https://newtestfuncpython.blob.core.windows.net/;QueueEndpoint=https://newtestfuncpython.queue.core.windows.net/;FileEndpoint=https://newtestfuncpython.file.core.windows.net/;TableEndpoint=https://newtestfuncpython.table.core.windows.net/;SharedAccessSignature=${writeResponse?.data.writeSasToken}`
        );
        const containerClient =
          blobServiceClient.getContainerClient("staticreport");
        const currentDate = Date.now();
        const blockBlobClient = containerClient.getBlockBlobClient(
          `${currentOrganization}-${scenarioName}-${reportName}-${currentDate}.html`
        );
        const options = {
          blobHTTPHeaders: {
            blobContentType: "text/html", // Set Content-Type as text/html
            blobContentDisposition: "inline", // Set Content-Disposition as inline
          },
        };
        await blockBlobClient.upload(htmlContent, htmlContent.length, options);

        /*const readResponse = await axios.get(
          "http://127.0.0.1:4000/generateReadSasToken"
        );*/

        const response = await apiService(
          "https://newtestfuncpython.azurewebsites.net/api/finalizeReport?",
          {
            method: "POST",
            data: {
              creator: userProfile?.email,
              currentOrganisation: currentOrganizationId,
              currentBuyerGuide: currentBuyerGuideId,
              currentScenario: currentScenarioId,
              currentPackage: currentPackage?.id,
              reportName: reportName,
              urlPreview: `https://newtestfuncpython.blob.core.windows.net/staticreport/${currentOrganization}-${scenarioName}-${reportName}-${currentDate}.html`,
            },
          }
        );

        if (response?.["Successfully Created"]) {
          localStorage.removeItem("smartData");
          localStorage.removeItem("valyouMatrixData");
          localStorage.removeItem("premiumData");
          localStorage.removeItem("lowData");
          localStorage.removeItem("initialData");
          localStorage.removeItem("budgetData");
          smartDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          premiumDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          budgetDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          lowDataForStaticData?.items?.forEach((offer) => {
            sections?.forEach((section) => {
              fields?.forEach((field) => {
                fieldTables[field]?.forEach((table) => {
                  localStorage.removeItem(
                    `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
                  );
                });
              });
            });
          });
          navigate("/saved-reports");
        }
        setOpen(false);
        setReportLoading(false);
        setReportName("");
      } catch (error) {
        console.error("Error uploading HTML file:", error.message);
      }
    }
  };
  const convertToHTML = (text) => {
    const normalizedText = text.replace(/\r\n/g, "\n");
    return normalizedText.split("\n\n").map((paragraph, index) => (
      <p key={index}>
        {paragraph.split("\n").map((line, lineIndex) => (
          <React.Fragment key={lineIndex}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    ));
  };
  const offerTemplate = useMemo(
    () => ({
      offerSummary: {
        showBox: false,
        isLoading: true,
        aiCommentary: " ",
        commentary: "ankush",
        prompt: " ",
        all_widget_summaries: {},
        widget_jsons_input: {},
      },
      offerMetrics: {
        tables: {
          mandatory: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Offer Metrics - Mandatory Use Cases",
          },
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Offer Metrics - Overall Use Cases",
          },
        },
      },
      supportedUseCases: {
        tables: {
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Supported Use Cases (Overall)",
          },
        },
      },
      unsupportedUseCases: {
        tables: {
          mandatory: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unsupported Use Cases (Mandatory)",
          },
          overall: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unsupported Use Cases (Overall)",
          },
        },
      },
      unusedFeatures: {
        tables: {
          generic: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unusued Features (Generic)",
          },
          notRequired: {
            showBox: false,
            isLoading: true,
            aiCommentary: "",
            commentary: " ",
            prompt: "",
            widgetName: "Top 5 Unused Features ('Not Required' Use Cases)",
          },
        },
      },
    }),
    []
  );

  const [accordionState, setAccordionState] = useState(initialAccordionState);
  const [commentary, setCommentary] = useState({});
  const [promptsState, setPromptsState] = useState({});
  const [promptVisible, setPromptVisible] = useState(false);
  const [hoveredBoxes, setHoveredBoxes] = useState({});

  useEffect(() => {
    let initialCommentary = {
      SmartValyou:
        smartDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: " ",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
      PremiumValyou:
        premiumDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
      BudgetValyou:
        budgetDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
      LowValyou:
        lowDataForStaticData?.items?.reduce(
          (acc, item) => ({ ...acc, [item]: offerTemplate }),
          {
            quadrantSummary: {
              showBox: false,
              aiCommentary: "",
              commentary: "",
              prompt: "",
              isLoading: true,
              offer_summaries: [],
            },
          }
        ) || {},
    };
    setCommentary(initialCommentary);
    setPromptsState(initialCommentary);
  }, [
    smartDataForStaticData,
    premiumDataForStaticData,
    budgetDataForStaticData,
    lowDataForStaticData,
    commentaryTemplate,
    offerTemplate,
  ]);
  const toggleAccordion = useCallback((section) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  }, []);

  
  const handlePromptChange = useCallback(
    (section, offer, field, table, value) => {
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            [field]: {
              ...prevState[section][offer][field],
              tables: {
                ...prevState[section][offer][field].tables,
                [table]: {
                  ...prevState[section][offer][field].tables[table],
                  prompt: value,
                },
              },
            },
          },
        },
      }));
    },
    []
  );
  
  console.log("commentry:",commentary);
  console.log("promptState:",promptsState )
  const handleOfferPromptChange = useCallback((section, offer, value) => {
    setPromptsState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          offerSummary: {
            ...prevState[section][offer].offerSummary,
            prompt: value,
          },
        },
      },
    }));
  }, []);
  const handleSectionPromptChange = useCallback((section, value) => {
    setPromptsState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        quadrantSummary: {
          ...prevState[section].quadrantSummary,
          prompt: value,
        },
      },
    }));
  }, []);
  const generateExecutiveCommentary = useCallback(
    (section) => {
      const newComment = promptsState[section].prompt;
      const updateLorem = `${executiveSummarydetail.commentary} Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque commodi minus asperiores illum id esse obcaecati neque quam magnam nam reprehenderit dolorum dolore recusandae officia, reiciendis totam voluptatum pariatur illo soluta accusamus! Eius dolorum sed quaerat amet facilis impedit aspernatur ratione voluptas fugiat cupiditate consequuntur magnam necessitatibus dolorem eaque veniam iste, enim natus explicabo nostrum odio corporis totam. Numquam, debitis!`;
      setExecutiveSummary((prev) => {
        return {
          ...prev,
          commentary: updateLorem,
          prompt: "",
          aiCommentary: newComment,
        };
      });
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          prompt: "",
        },
      }));
    },
    [promptsState, executiveSummarydetail.commentary]
  );

  const generateCommentary = useCallback(
    async (section, offer, field, table) => {
      const prompt =
        promptsState?.[section]?.[offer]?.[field]?.tables[table]?.prompt.trim();
      const widgetName =
        commentary[section][offer][field].tables[table].widgetName;
      const data =
        commentary[section][offer]?.offerSummary?.widget_jsons_input?.[
          widgetName
        ];
      const MandatoryOffers = valyouMatrixData?.MandatoryOffers;
      const OverallOffers = valyouMatrixData?.OverallOffers;
      const index =
        OverallOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        }) ||
        MandatoryOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        });
      const vendorName =
        OverallOffers[index]?.VendorName || MandatoryOffers[index]?.VendorName;
      const payload = {
        widget_name: widgetName,
        widget_json: data,
        buyerGuideName: buyerGuideName,
        offerName: offer,
        vendorName: vendorName,
        userPrompt: prompt,
      };

      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            [field]: {
              ...prevState[section][offer][field],
              tables: {
                ...prevState[section][offer][field].tables,
                [table]: {
                  ...prevState[section][offer][field].tables[table],
                  isLoading: true,
                },
              },
            },
          },
        },
      }));

      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/generateWidgetSummary?",
        {
          method: "POST",
          data: payload,
        }
      );
      localStorage.setItem(
        `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-MandatoryUsecase`,
        JSON.stringify({ summary: response })
      );

      setCommentary((prev) => {
        return {
          ...prev,
          [section]: {
            ...prev[section],
            [offer]: {
              ...prev[section][offer],
              [field]: {
                ...prev[section][offer][field],
                tables: {
                  ...prev[section][offer][field].tables,
                  [table]: {
                    ...prev[section][offer][field].tables[table],
                    commentary: response,
                    isLoading: false,
                  },
                },
              },
              offerSummary: {
                ...prev[section][offer].offerSummary,
                all_widget_summaries: {
                  ...prev[section][offer].offerSummary.all_widget_summaries,
                  [widgetName]: response,
                },
                widget_jsons_input: {
                  ...prev[section][offer].offerSummary.widget_jsons_input,
                  [widgetName]: data,
                },
              },
            },
          },
        };
      });

     
    },
    [
      buyerGuideName,
      commentary,
      currentOrg,
      promptsState,
      scenarioName,
      valyouMatrixData,
    ]
  );

  const generateSectionCommentary = useCallback(
    async (section) => {
      
      const prompt = promptsState[section]?.quadrantSummary?.prompt;
      const payload = {
            quadrantName:section,
            buyerGuideName:buyerGuideName,
            offerSummaries:commentary[section]?.quadrantSummary?.offer_summaries,
            userPrompt:prompt
      }
      
      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          quadrantSummary: {
            ...prevState[section].quadrantSummary,
             // Clear the prompt after generating commentary
            isLoading: true,
          },
        },
      }));
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/generateQuadrantSummary?",
        {
          method: "POST",
          data: payload,
        }
      );
      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          quadrantSummary: {
            ...prevState[section].quadrantSummary,
            commentary:response,
            isLoading: false,
          },
        },
      }));
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          quadrantSummary: {
            ...prevState[section].quadrantSummary,
            commentary: "",
            prompt: "", // Clear the prompt after generating commentary
          },
        },
      }));
    },
    [commentary,promptsState,buyerGuideName]
  );
  const generateOfferCommentary = useCallback(
    async (section, offer) => {
      const prompt = promptsState?.[section]?.[offer]?.offerSummary?.prompt.trim();
      const MandatoryOffers = valyouMatrixData?.MandatoryOffers;
      const OverallOffers = valyouMatrixData?.OverallOffers;
      const index =
        OverallOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        }) ||
        MandatoryOffers?.findIndex((item) => {
          return item?.OfferName === offer ? true : false;
        });

      const vendorName =
        OverallOffers[index]?.VendorName || MandatoryOffers[index]?.VendorName;
      const payload = {
        offerName: offer,
        buyerGuideName: buyerGuideName,
        vendorName: vendorName,
        allWidgetSummaries:
          commentary[section][offer]?.offerSummary.all_widget_summaries,
        userPrompt: prompt,
      };

      setCommentary((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            offerSummary: {
              ...prevState[section][offer]?.offerSummary,
              isLoading: true,
            },
          },
        },
      }));
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/generateOfferSummary?",
        {
          method: "POST",
          data: payload,
        }
      );
      localStorage.setItem(
        `${currentOrg}-${buyerGuideName}-${scenarioName}-${offer}-OfferSummary`,
        JSON.stringify({ summary: response })
      );
      setCommentary((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          [offer]: {
            ...prev[section][offer],
            offerSummary: {
              ...prev[section][offer].offerSummary,
              commentary: response,
              isLoading: false,
            },
          },
          quadrantSummary: {
            ...prev[section].quadrantSummary,
            offer_summaries: [
              ...prev[section].quadrantSummary.offer_summaries,
              response,
            ],
          },
        },
      }));
      setPromptsState((prevState) => ({
        ...prevState,
        [section]: {
          ...prevState[section],
          [offer]: {
            ...prevState[section][offer],
            offerSummary: {
              ...prevState[section][offer]?.offerSummary,
              prompt: "",
            },
          },
        },
      }));
    },
    [
      commentary,
      buyerGuideName,
      currentOrg,
      promptsState,
      scenarioName,
      valyouMatrixData,
    ]
  );
  const toggleShowBox = useCallback((section, offer, field, table) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          [field]: {
            ...prevState[section][offer][field],
            tables: {
              ...prevState[section][offer][field].tables,
              [table]: {
                ...prevState[section][offer][field].tables[table],
                showBox:
                  !prevState[section][offer][field].tables[table].showBox,
              },
            },
          },
        },
      },
    }));
  }, []);
  const toggleOfferSummary = useCallback((section, offer) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [offer]: {
          ...prevState[section][offer],
          offerSummary: {
            ...prevState[section][offer].offerSummary,
            showBox: !prevState[section][offer].offerSummary.showBox,
          },
        },
      },
    }));
  }, []);
  const toggleSectionSummary = useCallback((section) => {
    setCommentary((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        quadrantSummary: {
          ...prevState[section].quadrantSummary,
          showBox: !prevState[section].quadrantSummary.showBox,
        },
      },
    }));
  }, []);
  const handleMouseEnter = useCallback((key) => {
    setHoveredBoxes((prev) => ({ ...prev, [key]: true }));
  }, []);

  const handleMouseLeave = useCallback((key) => {
    setHoveredBoxes((prev) => ({ ...prev, [key]: false }));
  }, []);
  const renderTable = useCallback(
    (field, section, offer, table, offerData) => {
      //console.log(table)
      //console.log(commentary[section][offer][field].tables[table].widgetName);
      const tableComponents = {
        offerMetrics: {
          mandatory: (
            <MandatoryUseCaseTable
              key={`${section}-${offer}-${field}-mandatory`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              table={table}
              offerData={offerData}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
          overall: (
            <OverallUseCasesTable
              key={`${section}-${offer}-${field}-overall`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
        supportedUseCases: {
          overall: (
            <SupportedUseCaseOverallTable
              key={`${section}-${offer}-${field}-supported`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
        unsupportedUseCases: {
          mandatory: (
            <UnsupportedUseCaseMandatoryTable
              key={`${section}-${offer}-${field}-unsupported-mandatory`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
          overall: (
            <UnsupportedUseCaseOverallTable
              key={`${section}-${offer}-${field}-unsupported-overall`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
        unusedFeatures: {
          generic: (
            <UnusedGenericTable
              key={`${section}-${offer}-${field}-unused-generic`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
          notRequired: (
            <UnusedNotRequiredTable
              key={`${section}-${offer}-${field}-unused-notrequired`}
              menu={true}
              isViewReport={true}
              quadrantOffer={offer}
              commentary={commentary}
              setCommentary={setCommentary}
              field={field}
              section={section}
              offer={offer}
              offerData={offerData}
              table={table}
              widgetName={
                commentary[section][offer][field].tables[table].widgetName
              }
            />
          ),
        },
      };
      return (
        <Suspense fallback={<div>Loading...</div>}>
          {tableComponents[field][table]}
        </Suspense>
      );
    },
    [commentary]
  );
  const renderOfferDetails = useCallback(
    (section, data, loading, otherData) => {
      return loading ? (
        <Typography variant="h6">{section} data is loading...</Typography>
      ) : data?.length === 0 ? (
        commentary && commentary[section] ? (
          <QuadrantSummary
            commentary={commentary}
            section={section}
            setCommentary={setCommentary}
            data={data}
          />
        ) : (
          <Box mt={4} textAlign="center">
            <CircularProgress />
          </Box>
        )
      ) : (
        <Box>
          {commentary && commentary[section] && (
            <Box marginTop={3}>
              <Typography variant="h3">Quadrant Summary</Typography>
              {commentary[section]?.quadrantSummary && (
                <Box
                  sx={{ ...commentaryBoxStyles, marginTop: "10px" }}
                  onMouseEnter={() =>
                    handleMouseEnter(`${section}-quadrantSummary`)
                  }
                  onMouseLeave={() =>
                    handleMouseLeave(`${section}-quadrantSummary`)
                  }
                >
                  <Box>
                    <QuadrantSummary
                      commentary={commentary}
                      section={section}
                      setCommentary={setCommentary}
                      data={data}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "flex-start",
                      display: hoveredBoxes[`${section}-quadrantSummary`]
                        ? "flex"
                        : "none",
                      transition: "opacity 0.3s",
                    }}
                  >
                    <Tooltip title="Copy" disableInteractive arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            commentary[section]?.quadrantSummary?.commentary
                          );
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Regenerate" disableInteractive arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          toggleSectionSummary(section);
                        }}
                      >
                        <ChangeCircleIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              )}
              <Collapse in={commentary[section].quadrantSummary.showBox}>
                <Box sx={{ alignItems: "center", marginTop: "10px" }}>
                  <TextField
                    label="Quadrant Prompt"
                    fullWidth
                    multiline
                    maxRows={4}
                    value={promptsState[section].quadrantSummary?.prompt}
                    onChange={(e) =>
                      handleSectionPromptChange(section, e.target.value)
                    }
                    key={`${section}`}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => generateSectionCommentary(section)}
                    sx={{ marginTop: "10px" }}
                  >
                    Generate Commentary
                  </Button>
                </Box>
              </Collapse>
            </Box>
          )}
          {commentary &&
            commentary[section] &&
            Object.keys(commentary[section])
              .filter(
                (offer) =>
                  offer !== "Unlock Vendor" && offer !== "quadrantSummary"
              )
              .map((offer) => {
                return (
                  <Box key={`${section}-${offer}`} marginTop={3}>
                    <Typography variant="h4" marginTop={3}>
                      {offer}
                    </Typography>
                    <Box>
                      <Typography variant="subtitle2" marginTop={3}>
                        Offer Summary
                      </Typography>
                      {commentary[section][offer]?.offerSummary?.commentary && (
                        <Box
                          sx={{ ...commentaryBoxStyles, marginTop: "10px" }}
                          onMouseEnter={() =>
                            handleMouseEnter(`${section}-${offer}-offerSummer`)
                          }
                          onMouseLeave={() =>
                            handleMouseLeave(`${section}-${offer}-offerSummer`)
                          }
                        >
                          <Typography>
                            <OfferSummery
                              section={section}
                              offer={offer}
                              commentary={commentary}
                              setCommentary={setCommentary}
                            />
                          </Typography>
                          <Box
                            sx={{
                              alignItems: "flex-start",
                              display: hoveredBoxes[
                                `${section}-${offer}-offerSummer`
                              ]
                                ? "flex"
                                : "none",
                              transition: "opacity 0.3s",
                            }}
                          >
                            <Tooltip title="Copy" disableInteractive arrow>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    commentary[section][offer].offerSummary
                                      .commentary
                                  );
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title="Regenerate"
                              disableInteractive
                              arrow
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  toggleOfferSummary(section, offer);
                                }}
                              >
                                <ChangeCircleIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      )}
                      <Collapse
                        in={commentary[section][offer].offerSummary.showBox}
                      >
                        <Box sx={{ alignItems: "center", marginTop: "10px" }}>
                          <TextField
                            label="Field Prompt"
                            fullWidth
                            multiline
                            maxRows={4}
                            value={
                              promptsState[section][offer].offerSummary?.prompt
                            }
                            onChange={(e) =>
                              handleOfferPromptChange(
                                section,
                                offer,
                                e.target.value
                              )
                            }
                            key={`${section}-${offer}`}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              generateOfferCommentary(section, offer)
                            }
                            sx={{ marginTop: "10px" }}
                          >
                            Generate Commentary
                          </Button>
                        </Box>
                      </Collapse>
                    </Box>
                    {fields.map((field) => (
                      <Box key={`${section}-${field}`} marginTop={3}>
                        <Typography variant="subtitle2">
                          {fieldsHeading[field].split(/(?=[A-Z])/).join(" ")}
                        </Typography>

                        {Object.keys(
                          commentary[section][offer][field].tables
                        ).map((table, tableIndex) => {
                          return (
                            <Box
                              key={`${section}-${offer}-${field}-table-${tableIndex}`}
                              marginTop={3}
                              onMouseEnter={() =>
                                handleMouseEnter(
                                  `${section}-${offer}-${field}-${table}`
                                )
                              }
                              onMouseLeave={() =>
                                handleMouseLeave(
                                  `${section}-${offer}-${field}-${table}`
                                )
                              }
                            >
                              {commentary[section][offer][field].tables[table]
                                ?.widgetName &&
                                renderTable(
                                  field,
                                  section,
                                  offer,
                                  table,
                                  otherData[offer]
                                )}
                              {commentary[section][offer][field].tables[table]
                                ?.commentary && (
                                <Box
                                  sx={{
                                    ...commentaryBoxStyles,
                                    marginTop: "10px",
                                  }}
                                >
                                  <Typography>
                                    {commentary[section][offer][field].tables[
                                      table
                                    ].isLoading ? (
                                      <Box mt={4} textAlign="center">
                                        <CircularProgress />
                                      </Box>
                                    ) : (
                                      convertToHTML(
                                        commentary[section][offer][field]
                                          .tables[table]?.commentary
                                      )
                                    )}{" "}
                                  </Typography>
                                  <Box
                                    sx={{
                                      alignItems: "flex-start",
                                      display: hoveredBoxes[
                                        `${section}-${offer}-${field}-${table}`
                                      ]
                                        ? "flex"
                                        : "none",
                                      transition: "opacity 0.3s",
                                    }}
                                  >
                                    <Tooltip
                                      title="Copy"
                                      disableInteractive
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            commentary[section][offer][field]
                                              .tables[table]?.commentary
                                          );
                                        }}
                                      >
                                        <ContentCopyIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                      title="Regenerate"
                                      disableInteractive
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          toggleShowBox(
                                            section,
                                            offer,
                                            field,
                                            table
                                          )
                                        }
                                      >
                                        <ChangeCircleIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              )}

                              <Collapse
                                in={
                                  commentary[section][offer][field].tables[
                                    table
                                  ].showBox
                                }
                              >
                                <Box sx={{ marginTop: "10px" }}>
                                  <TextField
                                    label="Table Prompt"
                                    fullWidth
                                    multiline
                                    maxRows={4}
                                    value={
                                      promptsState[section][offer][field]
                                        ?.tables[table].prompt
                                    }
                                    onChange={(e) =>
                                      handlePromptChange(
                                        section,
                                        offer,
                                        field,
                                        table,
                                        e.target.value
                                      )
                                    }
                                    key={`${section}-${offer}-${field}-table-${tableIndex}-prompt`}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      generateCommentary(
                                        section,
                                        offer,
                                        field,
                                        table
                                      )
                                    }
                                    sx={{ marginTop: "10px" }}
                                  >
                                    Generate Commentary
                                  </Button>
                                </Box>
                              </Collapse>
                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                  </Box>
                );
              })}
        </Box>
      );
    },
    [
      commentary,
      hoveredBoxes,
      promptsState,
      handleMouseEnter,
      handleMouseLeave,
      toggleShowBox,
      handlePromptChange,
      generateCommentary,
      renderTable,
      generateOfferCommentary,
      handleOfferPromptChange,
      toggleOfferSummary,
      handleSectionPromptChange,
      toggleSectionSummary,
      generateSectionCommentary,
    ]
  );

  if (isLoading) {
    return (
      <Box mt={4} textAlign="center">
        <Typography variant="h5">Loading data, please wait...</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme} >
      <CssBaseline />
   
      <div ref={contentRef} id="htmlContent">
        <HeaderNaming
          data={{
            "End-user Organisation Name":
              userProfile?.profileType === "endUser"
                ? userProfile?.companyName
                : currentOrganization,
            "Buyer Guide Name": buyerGuideName,
            "Scenario Name": scenarioName,
          }}
        />
        <Box sx={{ width: "100%", minWidth: "350px" }}>
          <Box sx={{ marginBottom: { xs: 18, md: 15 } }}>
            <Typography
              variant="h3"
              sx={{ textAlign: "center", marginBottom: 5 }}
            >
              Buyer Guide Report
            </Typography>
            <Box sx={{ width: "100%", alignItems: "center" }}>
              <Box
                sx={{ flexDirection: "column", width: "70%", float: "left" }}
              >
                <Typography>Company Name: {companyName}</Typography>
                <Typography>Date: {date}</Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  float: "right",
                  width: { xs: "200px", md: "250px" },
                  fontSize: { xs: "14px", md: "16px" },
                }}
                onClick={() => {
                  if (!reportdata?.urlPreview) {
                    setOpen(true);
                  } else {
                    navigate("/saved-reports");
                  }
                }}
              >
                {reportdata?.urlPreview
                  ? "Veiw Saved Report"
                  : "Finalize and Save Report"}
              </Button>
            </Box>
          </Box>
          <Box>
            {sections.map((section) => (
              <Box
                key={section}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="h1">
                    {section.split(/(?=[A-Z])/).join(" ")}
                  </Typography>
                  <IconButton
                    size="large"
                    sx={{ cursor: "pointer" }}
                    onClick={() => toggleAccordion(section)}
                  >
                    {accordionState[section] ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </IconButton>
                </Box>
                <Collapse in={accordionState[section]}>
                  <Box>
                    {section === "ExecutiveSummary" ? (
                      <>
                        <Suspense fallback={<div>Loading chart...</div>}>
                          <Box id="blob" sx={{ flex: 1 }}>
                            {primaryMetric === "offerScore" ? (
                              <ChartComponent
                                chartType="Bubble Chart"
                                initialData={initialData}
                                gridLines={valyouMatrixData?.Gridlines}
                                flag={true}
                              />
                            ) : (
                              <>
                                <Typography variant="subtitle1">
                                  The Valyou Matrix (Scatter Chart)
                                </Typography>
                                <ChartComponent
                                  chartType="Scatter Chart"
                                  initialData={initialData}
                                  flag={true}
                                />
                              </>
                            )}
                          </Box>
                        </Suspense>
                        <Box sx={{ display: "flex", alignItems: "self-start" }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <Box sx={{ cursor: "pointer" }}>
                              <Box
                                sx={{
                                  ...commentaryBoxStyles,
                                  marginTop: "10px",
                                }}
                                onMouseEnter={() =>
                                  handleMouseEnter(`ExecutiveSummary`)
                                }
                                onMouseLeave={() =>
                                  handleMouseLeave(`ExecutiveSummary`)
                                }
                              >
                                <ExecutiveSummary
                                  commentary={commentary}
                                  executiveSummarydetail={
                                    executiveSummarydetail
                                  }
                                  setExecutiveSummary={setExecutiveSummary}
                                />
                                <Box
                                  sx={{
                                    alignItems: "flex-start",
                                    display: hoveredBoxes[`ExecutiveSummary`]
                                      ? "flex"
                                      : "none",
                                    transition: "opacity 0.3s",
                                  }}
                                >
                                  <Tooltip
                                    title="Copy"
                                    disableInteractive
                                    arrow
                                  >
                                    <IconButton size="small">
                                      <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    title="Regenerate"
                                    disableInteractive
                                    arrow
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() =>
                                        setPromptVisible(!promptVisible)
                                      }
                                    >
                                      <ChangeCircleIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                            <Collapse
                              in={promptVisible}
                              style={{
                                transitionDelay: promptVisible
                                  ? "200ms"
                                  : "0ms",
                              }}
                            >
                              <Box sx={{ width: "100%", marginTop: "20px" }}>
                                <TextField
                                  label="Prompt"
                                  fullWidth
                                  multiline
                                  maxRows={4}
                                  value={promptsState?.ExecutiveSummary?.prompt}
                                  onChange={(e) =>
                                    setPromptsState({
                                      ...promptsState,
                                      ExecutiveSummary: {
                                        ...promptsState?.ExecutiveSummary,
                                        prompt: e.target.value,
                                      },
                                    })
                                  }
                                  key={`ExecutiveSummary-prompt`}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    generateExecutiveCommentary(
                                      "ExecutiveSummary"
                                    )
                                  }
                                  sx={{ width: "250px", marginTop: "10px" }}
                                >
                                  Generate Commentary
                                </Button>
                              </Box>
                            </Collapse>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      renderOfferDetails(
                        section,
                        section === "SmartValyou"
                          ? smartDataForStaticData?.items
                          : section === "PremiumValyou"
                          ? premiumDataForStaticData?.items
                          : section === "BudgetValyou"
                          ? budgetDataForStaticData?.items
                          : section === "LowValyou"
                          ? lowDataForStaticData?.items
                          : [],
                        section === "SmartValyou"
                          ? isLoading
                          : section === "PremiumValyou"
                          ? premiumLoading
                          : section === "BudgetValyou"
                          ? budgetLoading
                          : section === "LowValyou"
                          ? lowLoading
                          : false,
                        section === "SmartValyou"
                          ? smartDataForStaticData?.otherData
                          : section === "PremiumValyou"
                          ? premiumDataForStaticData?.otherData
                          : section === "BudgetValyou"
                          ? budgetDataForStaticData?.otherData
                          : section === "LowValyou"
                          ? lowDataForStaticData?.otherData
                          : {}
                      )
                    )}
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        </Box>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ backgroundColor: "white" }}>
          Enter-ReportName
        </DialogTitle>
        <DialogContent sx={{ color: "white", backgroundColor: "white" }}>
          <TextField
            data-test-id="Report-Name"
            margin="dense"
            label="Report Name"
            type="search"
            fullWidth
            value={reportName}
            onChange={onChangeReportName}
          />
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "white" }}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={
              isReportLoading
                ? null
                : () => {
                    handleExport();
                  }
            }
            color="primary"
          >
            {isReportLoading ? <CircularProgress size={24} /> : "Add-Report"}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

const commentaryBoxStyles = {
  borderRadius: "5px",
  padding: "10px",
  backgroundColor: "transparent",
  transition: "border-color 0.3s, background-color 0.3s", // Add transition for smooth effect
  "&:hover": {
    border: "1px solid #40bab4 inset",
    borderColor: "#40bab4", // Change border color on hover
    backgroundColor: "rgba(64, 186, 180, 0.2)", // Semi-transparent background color
  },
};

export default memo(ViewReport);
