import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import axios from "axios";

function StaticReport() {
  const location = useLocation();
  const [fileUrl, setFileUrl] = useState(location?.state?.urlPreview);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const fetchdata = async () => {
      const readResponse = await axios.get(
        "https://newtestfuncpython.azurewebsites.net/api/generateReadSasToken"
      );
      setFileUrl((prev)=>{
            return `${prev}?${readResponse?.data?.readSasToken}`
      })
      setLoading(false)
    };
    fetchdata();
  }, []);
  const HtmlFilePreview = ({ fileUrl }) => {
    return (
      <div width={"500px"}>
        {isLoading ? (
          <Box mt={4} textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <iframe
            src={fileUrl}
            width="100%"
            height="1200px"
            title="HTML File Preview"
            style={{ border: "none"}}
          ></iframe>
        )}
      </div>
    );
  };

  return (
    <Box>
      <HtmlFilePreview fileUrl={fileUrl} />
    </Box>
  );
}

export default StaticReport;
