import React, { useState, useEffect, useContext, memo } from "react";
import { Box, CircularProgress, Divider } from "@mui/material";

//import { apiService } from "../../../utils/utils";
import DataGridTable from "../../../components/DataGridTable";
import theme from "../../../theme/theme";
import "../../GridLayout/GridComponent.css";
import { apiService } from "../../../utils/utils";
import { UserProfileContext } from "../../../context/UserContext";
import NoDataAvilable from "../../../components/NoDataAvilable";

const getColumnNames = (name) => {
  switch (name) {
    case "metrics":
      return "Offer Metrics";
    case "metric":
      return "Offer Metric Value (%)";
    case "vendorMax":
      return "Best Offer Metric- Vendor (%)";
    case "scenarioMax":
      return "Best Offer Metric-Scenario (%)";
    case "mandatoryUseCasesMax":
      return "Mandatory Use Cases Max (%)";
    case "overallUseCasesMax":
      return "Overall Use Cases Max";
    default:
      return name;
  }
};
const OverallUseCasesTable = memo(
  ({
    menu,
    isViewReport,
    quadrantOffer,
    setCommentary,
    field,
    section,
    offer,
    table,
    widgetName,
    /**Result section below */
    widgetKey,
    widgetLabels,
    setLayout,
    issmallscreen,
    offerData,
  }) => {
    const [rows, setRows] = useState([]);
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [, setCommentryLoading] = useState(true);
    const [, setCommentryData] = useState("");
    const {
      currentOrg,
      currentOrganizationId,
      userProfile,
      currentScenarioId,
      currentBuyerGuideId,
      scenarioName,
      buyerGuideName,
      currentPackage,
      offerId,
      valyouMatrixData,
    } = useContext(UserProfileContext);
    const staticData =
      '### Offer Metrics Summary: Overall Use Cases\r\n\r\n#### Offer: Zscaler Private Access\r\n- **Vendor:** Zscaler\r\n- **Overall Offer Score:** 43.89\r\n- **Overall Offer Relevance:** 39.63\r\n- **Overall Offer Utilization:** 48.15\r\n\r\n#### Comparative Analysis:\r\n- **Max Vendor Offer Score:** 43.89\r\n- **Max Vendor Offer Relevance:** 39.63\r\n- **Max Vendor Offer Utilization:** 48.15\r\n\r\n- **Max Global Offer Score:** 43.89\r\n- **Max Global Offer Relevance:** 39.63\r\n- **Max Global Offer Utilization:** 55.56\r\n\r\n#### Rankings:\r\n- **Offer Score Rank:** 1\r\n- **Relevance Rank:** 1\r\n- **Utilization Rank:** 2\r\n\r\n### Summary:\r\nThe "Zscaler Private Access" offer from Zscaler demonstrates a strong performance across multiple metrics. It is ranked first in both Offer Score and Relevance on a global scale, underscoring its effectiveness and importance in its respective use cases. The offer achieves an Overall Offer Score of 43.89 and an Overall Offer Relevance of 39.63. In terms of utilization, it shows a high score of 48.15, although it ranks second globally, indicating there are offers with slightly higher utilization.\r\n\r\nOverall, Zscaler Private Access is an exceptional offering within its category, excelling particularly in Offer Score and Relevance. These metrics highlight its significant value proposition and reliability for users.';
    useEffect(() => {
      const fetchData = async () => {
        try {
          const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            currentOffer: isViewReport ? offerData?.offerId : offerId,
          };

          let localStoreTableData = JSON.parse(
            localStorage.getItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
            )
          );

          let response = null;
          if (isViewReport && localStoreTableData) {
            response = localStoreTableData;
            setLoading(false);
            setData(response);
          } else {
            response = await apiService(
              "https://newtestfuncpython.azurewebsites.net/api/getOfferMetricsAndRank?", // replace with your actual API endpoint
              {
                method: "POST",
                data: payload,
              },
              setLoading,
              setData,
              setError
            );
            if (isViewReport) {
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`,
                JSON.stringify(response)
              );
            }
          }
          const columnKeys = Object.keys(response[0]);
          const transformedColumns = columnKeys.map((key, index) => ({
            field: key, // Use the key as field name
            headerName: getColumnNames(key), // Format header name with spaces (optional)
            flex: 1,
            minWidth: 250,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
              <Box
                sx={{
                  overflowY: "hidden",
                  display: "flex",
                  justifyContent: index === 0 ? "flex-start" : "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                {params.value}
              </Box>
            ),
          }));

          const transformedRows = response.map((data, index) => {
            const { ...rowData } = data; // Destructure to exclude "metric"
            return {
              id: index, // Ensure unique ID for each row
              ...rowData, // Spread the remaining data into the row object
            };
          });

          setColumns(transformedColumns);
          setRows(transformedRows);
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, [
      userProfile,
      currentOrg,
      buyerGuideName,
      scenarioName,
      offerId,
      isViewReport,
      issmallscreen,
      quadrantOffer,
      setLayout,
      widgetKey,
      field,
      offer,
      section,
      table,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage.id,
      currentScenarioId,
      offerData,
    ]);

    useEffect(() => {
      if (!isViewReport) {
        setLayout((prev) => {
          return prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              return {
                ...item,
                h: 1.5 + (rows.length > 0 ? rows.length : 1),
              };
            }
          });
        });
      }
    }, [rows.length, isViewReport, setLayout, widgetKey]);

    useEffect(() => {
      if (isViewReport) {
        const reportData = JSON.parse(
          localStorage.getItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-OverallUsecase`
          )
        );
        const MandatoryOffers = valyouMatrixData?.MandatoryOffers||[];
        const OverallOffers = valyouMatrixData?.OverallOffers||[];
        const index =
          OverallOffers?.findIndex((item) => {
            return item?.OfferName === offer ? true : false;
          }) ||
          MandatoryOffers?.findIndex((item) => {
            return item?.OfferName === offer ? true : false;
          });
        const vendorName = OverallOffers[index]?.VendorName || MandatoryOffers[index]?.VendorName;
        const fetchData = async () => {
          const payload = {
            widget_name: widgetName,
            widget_json: data,
            buyerGuideName: buyerGuideName,
            offerName: offer,
            vendorName: vendorName,
          };
          if (widgetName && data) {
            try {
              const response = await apiService(
                "https://newtestfuncpython.azurewebsites.net/api/generateWidgetSummary?",
                {
                  method: "POST",
                  data: payload,
                },
                setCommentryLoading,
                setCommentryData,
                setError
              );

              setCommentary((prev) => {
                return {
                  ...prev,
                  [section]: {
                    ...prev[section],
                    [offer]: {
                      ...prev[section][offer],
                      [field]: {
                        ...prev[section][offer][field],
                        tables: {
                          ...prev[section][offer][field].tables,
                          [table]: {
                            ...prev[section][offer][field].tables[table],
                            commentary: response,
                            isLoading: false,
                          },
                        },
                      },
                      offerSummary: {
                        ...prev[section][offer].offerSummary,
                        all_widget_summaries: {
                          ...prev[section][offer].offerSummary.all_widget_summaries,
                          [widgetName]: response,
                        },
                        widget_jsons_input: {
                          ...prev[section][offer].offerSummary.widget_jsons_input,
                          [widgetName]: data,
                        },
                      },
                    },
                  },
                };
              });

              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-OverallUsecase`,
                JSON.stringify({ summary: response })
              );
            } catch (error) {
              console.error("Failed to fetch data:", error);
              setCommentary((prev) => {
                return {
                  ...prev,
                  [section]: {
                    ...prev[section],
                    [offer]: {
                      ...prev[section][offer],
                      [field]: {
                        ...prev[section][offer][field],
                        tables: {
                          ...prev[section][offer][field].tables,
                          [table]: {
                            ...prev[section][offer][field].tables[table],
                            commentary: staticData,
                            isLoading: false,
                          },
                        },
                      },
                      offerSummary: {
                        ...prev[section][offer].offerSummary,
                        all_widget_summaries: {
                          ...prev[section][offer].offerSummary.all_widget_summaries,
                          [widgetName]: staticData,
                        },
                        widget_jsons_input: {
                          ...prev[section][offer].offerSummary.widget_jsons_input,
                          [widgetName]: data,
                        },
                      },
                    },
                  },
                };
              });
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-OverallUsecase`,
                JSON.stringify({ summary: staticData })
              );
            }
          }
        };
        if (widgetName && data && !reportData?.summary) {
          fetchData();
        } else if (widgetName && data) {
          setCommentary((prev) => {
            return {
              ...prev,
              [section]: {
                ...prev[section],
                [offer]: {
                  ...prev[section][offer],
                  [field]: {
                    ...prev[section][offer][field],
                    tables: {
                      ...prev[section][offer][field].tables,
                      [table]: {
                        ...prev[section][offer][field].tables[table],
                        commentary: reportData.summary,
                        isLoading: false,
                      },
                    },
                  },
                  offerSummary: {
                    ...prev[section][offer].offerSummary,
                    all_widget_summaries: {
                      ...prev[section][offer].offerSummary.all_widget_summaries,
                      [widgetName]: reportData.summary,
                    },
                    widget_jsons_input: {
                      ...prev[section][offer].offerSummary.widget_jsons_input,
                      [widgetName]: data,
                    },
                  },
                },
              },
            };
          });
        }
      }
    }, [
      section,
      offer,
      field,
      table,
      setCommentary,
      data,
      widgetName,
      currentOrg,
      isViewReport,
      scenarioName,
      offerId,
      buyerGuideName,
      valyouMatrixData,
    ]);
    return loading ? (
      // If loading is true, display a loading spinner inside a box.
      <Box
        sx={{
          height: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    ) : rows.length > 0 ? (
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,

            color: "white",
          },
        }}
      >
        <Divider sx={{ margin: "15px" }} />
        <Box
          className="thinner-scrollbar"
          sx={{
            width: "100%",
            height: "100%",
            paddingX: "10px",
          }}
        >
          <DataGridTable
            name={widgetLabels}
            rows={rows}
            columns={columns}
            loading={loading}
            error={error}
            menu={menu}
            sx={{
              "& .MuiDataGrid-cell": {
                wordWrap: "break-word",
                whiteSpace: "normal",
              },
            }}
          />
        </Box>
      </Box>
    ) : (
      // If no data (rows.length === 0), display a message.
      <NoDataAvilable widgetLabels={widgetLabels} widgetKey={widgetKey} handleRemoveWidget />
    );
  }
);

export default OverallUseCasesTable;
