// import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from 'axios';
// import { Box, Button, Container, Typography, styled } from "@mui/material";
// import { UserProfileContext } from "../context/UserContext";


// import ExploreIcon from "@mui/icons-material/Explore";
// import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
// import NearMeIcon from '@mui/icons-material/NearMe';


// const StyledButton = styled(Button)(({ theme }) => ({
//  position: "relative",
//  overflow: "hidden",
//  padding: "2.5rem",
//  height: "100%",
//  display: "flex",
//  flexDirection: "column",
//  justifyContent: "center",
//  alignItems: "center",
//  boxShadow: theme.shadows[3],
//  background: "transparent",
//  color: theme.palette.text.primary,
//  transition: "color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//  "&:before": {
//    content: '""',
//    position: "absolute",
//    left: 0,
//    bottom: 0,
//    width: "100%",
//    height: "0%",
//    backgroundColor: theme.palette.primary.main,
//    zIndex: 0,
//    transition: "height 0.3s ease-in-out",
//  },
//  "&:hover:before": {
//    height: "100%",
//    color: "#fff",
//  },
//  "&:hover": {
//    boxShadow: theme.shadows[6],
//    color: "#fff",
//  },
//  "& .MuiTypography-root": {
//    position: "relative",
//    zIndex: 1,
//    transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
//  },
//  "& .title": {
//    transform: "translateY(0)",
//    textAlign: "center",
//    height: "auto",
//    display: "block",
//  },
//  "& .subtitle": {
//    display: "none",
//    transform: "translateY(100%)",
//    color: "#fff",
//    textAlign: "center",
//  },
//  "&:hover .title": {
//    transform: "translateY(-100%)",
//    height: 0,
//    visibility: "hidden",
//    overflow: "hidden",
//    margin: 0,
//    padding: 0,
//    display: "none",
//  },
//  "&:hover .subtitle": {
//    display: "block",
//    transform: "translateY(0)",
//  },
//  "& .icon": {
//    fontSize: "3rem",
//    marginBottom: "1rem",
//  },
//  "&:hover .icon": {
//    opacity: 0,
//    transform: "scale(0)",
//    display: "none",
//  },
// }));


// const AnalystWelcome = () => {
//  const { userProfile } = useContext(UserProfileContext);
//  const navigate = useNavigate();

//  const fetchBuyerGuideData = async () => {
//    const url = "https://newtestfuncpython.azurewebsites.net/api/getBuyerGuideForAnalyst";
//    try {
//      const response = await axios.post(url, { userEmail: userProfile.email });
//     //  const response = await axios.post(url, { userEmail: "nitin.acharekar@gmail.com" });
//     //  const response = await axios.post(url, { userEmail: "rajat.gupta@smartdecision.ai" });

//      const buyerGuides = response.data;
//      navigate('/buyer-guide-menu', { state: { buyerGuides } });
//    } catch (error) {
//      console.error('Failed to fetch buyer guides:', error);
//    }
//  };

//  return (
//    <Container

//    sx={{
//      textAlign: "center",
//      marginTop: "2rem",
//      height: "70%",
//      width: "100%",
//    }}
//  >

// <Typography data-test-id="Welcome-name" variant="h2" gutterBottom>
//        Welcome {userProfile?.displayName}!
//      </Typography>
//      <Typography data-test-id="today" variant="h4" gutterBottom sx={{ color: "#666666" }}>
//        What do you want to do today?
//      </Typography>
    
//      <Box
//      sx={{
//        display: "flex",
//          flexDirection: "row",
//          flexWrap: "wrap",
//          height: "100%",
//          width: "100%",
//          gap: 3,
//          justifyContent: "center",
//          alignItems: "center",
//          marginTop: { sm: 8, lg: 2 },
//          }}>


//        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={fetchBuyerGuideData}>
//          <Typography data-test-id="Buyer Guide" variant="h2" gutterBottom className="title">
//            Buyer Guide
//          </Typography>
//          <LibraryBooksIcon className="icon" />
//          <Typography data-test-id="Explore Buyer Guide" className="subtitle" variant="body1">
//            Explore Buyer Guides
//          </Typography>
//        </StyledButton>

//        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={()=>navigate("/mapping")}>
//          <Typography variant="h2" gutterBottom className="title">
//            Mapping
//          </Typography>
//          <NearMeIcon className="icon" />
//          <Typography className="subtitle" variant="body1">
//            Explore Mapping
//          </Typography>
//        </StyledButton>


//        <StyledButton  variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/vendor-offer-page")}>
//          <Typography data-test-id="Vendor/Offer" variant="h2" gutterBottom className="title">
//            Vendor/Offer
//          </Typography>
//          <ExploreIcon className="icon" />
//          <Typography data-test-id="Manage Vendors and Offers" className="subtitle" variant="body1">
//            Manage Vendors and Offers
//          </Typography>
//        </StyledButton>
//      </Box>
//    </Container>
//  );
// };

// export default AnalystWelcome;



import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { Box, Button, Container, Typography, styled } from "@mui/material";
import { UserProfileContext } from "../context/UserContext";

import ExploreIcon from "@mui/icons-material/Explore";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import NearMeIcon from '@mui/icons-material/NearMe';

const StyledButton = styled(Button)(({ theme }) => ({
 position: "relative",
 overflow: "hidden",
 padding: "2.5rem",
 height: "100%",
 display: "flex",
 flexDirection: "column",
 justifyContent: "center",
 alignItems: "center",
 boxShadow: theme.shadows[3],
 background: "transparent",
 color: theme.palette.text.primary,
 transition: "color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
 "&:before": {
   content: '""',
   position: "absolute",
   left: 0,
   bottom: 0,
   width: "100%",
   height: "0%",
   backgroundColor: theme.palette.primary.main,
   zIndex: 0,
   transition: "height 0.3s ease-in-out",
 },
 "&:hover:before": {
   height: "100%",
   color: "#fff",
 },
 "&:hover": {
   boxShadow: theme.shadows[6],
   color: "#fff",
 },
 "& .MuiTypography-root": {
   position: "relative",
   zIndex: 1,
   transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
 },
 "& .title": {
   transform: "translateY(0)",
   textAlign: "center",
   height: "auto",
   display: "block",
 },
 "& .subtitle": {
   display: "none",
   transform: "translateY(100%)",
   color: "#fff",
   textAlign: "center",
 },
 "&:hover .title": {
   transform: "translateY(-100%)",
   height: 0,
   visibility: "hidden",
   overflow: "hidden",
   margin: 0,
   padding: 0,
   display: "none",
 },
 "&:hover .subtitle": {
   display: "block",
   transform: "translateY(0)",
 },
 "& .icon": {
   fontSize: "3rem",
   marginBottom: "1rem",
 },
 "&:hover .icon": {
   opacity: 0,
   transform: "scale(0)",
   display: "none",
 },
}));

const AnalystWelcome = () => {
 const { userProfile } = useContext(UserProfileContext);
 const navigate = useNavigate();

 const fetchBuyerGuideData = async () => {
   const url = "https://newtestfuncpython.azurewebsites.net/api/getBuyerGuideForAnalyst";
   try {
      const response = await axios.post(url, { email: userProfile.email });
     //const response = await axios.post(url, { email: "nitin.acharekar@gmail.com" });
    //  const response = await axios.post(url, { userEmail: "rajat.gupta@smartdecision.ai" });

     const buyerGuides = response.data;
     navigate('/buyer-guide-menu', { state: { buyerGuides } });
   } catch (error) {
     console.error('Failed to fetch buyer guides:', error);
   }
 };

 const fetchVendorData = async () => {
   const url = "https://newtestfuncpython.azurewebsites.net/api/displayVendorForAnalyst";
   try {
     const response = await axios.post(url, { email: userProfile.email });
     const vendors = response.data;
    //  console.log("Vendors Data--->",vendors);
    
     navigate("/vendor-offer-page", { state: { vendors } });
   } catch (error) {
     console.error('Failed to fetch vendor data:', error);
   }
 };

 return (
   <Container
     sx={{
       textAlign: "center",
       marginTop: "2rem",
       height: "70%",
       width: "100%",
     }}
   >
     <Typography data-test-id="Welcome-name" variant="h2" gutterBottom>
       Welcome {userProfile?.displayName}!
     </Typography>
     <Typography data-test-id="today" variant="h4" gutterBottom sx={{ color: "#666666" }}>
       What do you want to do today?
     </Typography>
    
     <Box
       sx={{
         display: "flex",
         flexDirection: "row",
         flexWrap: "wrap",
         height: "100%",
         width: "100%",
         gap: 3,
         justifyContent: "center",
         alignItems: "center",
         marginTop: { sm: 8, lg: 2 },
       }}
     >
       <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={fetchBuyerGuideData}>
         <Typography data-test-id="Buyer Guide" variant="h2" gutterBottom className="title">
           Buyer Guide
         </Typography>
         <LibraryBooksIcon className="icon" />
         <Typography data-test-id="Explore Buyer Guide" className="subtitle" variant="body1">
           Explore Buyer Guides
         </Typography>
       </StyledButton>

       <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-type")}>
         <Typography variant="h2" gutterBottom className="title">
           Mapping
         </Typography>
         <NearMeIcon className="icon" />
         <Typography className="subtitle" variant="body1">
           Explore Mapping
         </Typography>
       </StyledButton>

       <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={fetchVendorData}>
         <Typography data-test-id="Vendor/Offer" variant="h2" gutterBottom className="title">
           Vendor/Offer
         </Typography>
         <ExploreIcon className="icon" />
         <Typography data-test-id="Manage Vendors and Offers" className="subtitle" variant="body1">
           Manage Vendors and Offers
         </Typography>
       </StyledButton>
     </Box>
   </Container>
 );
};

export default AnalystWelcome;


