import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Divider,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Add, Remove, Check, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { apiService } from "../../utils/utils";
import HeaderNaming from "../../components/HeaderNaming/HeaderNaming.jsx";
import { UserProfileContext } from "../../context/UserContext.jsx";
import NoDataAvailable from "../../components/NoDataAvilable.jsx";

export default function VendorOptions() {
  const navigate = useNavigate();
  const {
    userProfile,
    currentOrganization,
    buyerGuideName,
    scenarioName,
    currentScenarioId,
    currentBuyerGuideId,
    currentPackage,
    currentOrg,
    setNotification,
    notification,
    handleCloseNotification,
  } = useContext(UserProfileContext);

  const [vendors, setVendors] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendorId, setVendorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [vendorsToUnlock, setVendorsToUnlock] = useState([]);
  const [vendorsToInclude, setVendorsToInclude] = useState([]);
  const [offerToInclude, setOfferToInclude] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const packageLimits = {
    "Solo Starter": 1,
    "Duel Dynamic": 2,
    "Focus Five": 5,
    "All Available": 999,
  };
  const unallocatedVendorCount =
    Math.min(packageLimits[currentPackage?.package_type], vendors.length) -
      vendors.filter((vendor) => vendor.isUnlocked).length >
    0
      ? Math.min(packageLimits[currentPackage?.package_type], vendors.length) -
        vendors.filter((vendor) => vendor.isUnlocked).length
      : 0;
  const unlockVendorCount = vendors.filter((vendor) => vendor.isUnlocked).length;

  const data = {
    "End-user Organisation Name":
      userProfile?.profileType === "endUser" ? userProfile?.companyName : currentOrganization,
    "Buyer Guide Name": buyerGuideName,
    "Scenario Name": scenarioName,
    "Purchased Package": currentPackage?.package_type,
    "Unlocked Vendor Count": unlockVendorCount,
    "Unallocated Vendor Count": unallocatedVendorCount,
  };
  //unlocked vendor = count of isUnlocked in response

  //min. of (package of limit , total vendors)-unlocked vendors = unallocated vendor count

  //isUnlocked then checkbox with #CHECKED in it

  const handleUnlockVendor = async () => {
    if (vendorsToUnlock.length > unallocatedVendorCount) {
      setDialogOpen(true); // Open dialog if limit exceeds
    } else {
      // console.log(
      //   unallocatedVendorCount - unlockVendorCount,
      //   "unallocatedVendorCount - unlockVendorCount"
      // );
      // console.log(vendorsToUnlock.length, "vendorsToUnlock.length");                 do not remove

      const payload = {
        email: userProfile?.email,
        currentOrganisation: currentOrg,
        currentBuyerGuide: currentBuyerGuideId,
        currentScenario: currentScenarioId,
        currentPackage: currentPackage.id,
        currentVendors: vendorsToUnlock,
      };
      const response = await apiService(
        "https://newtestfuncpython.azurewebsites.net/api/unlockVendor?",
        {
          method: "POST",
          data: payload,
        },
        setLoading,
        null,
        (error) => {
          console.error("Failed to unlock vendor:", error);
        }
      );
      if (response.messages[0] === "Vendor unlocked successfully.") {
        setVendorsToUnlock([]);
        handleCloseNotification();
        loadVendors();
      } else {
        setNotification("Failed to unlock vendor");
      }
    }
  };

  /**
   * The function `loadVendors` sends a POST request to a specified API endpoint with certain payload
   * data, processes the response by updating vendor information, and sets the updated vendor list in
   * the state.
   */
  const loadVendors = async () => {
    const payload = {
      email: userProfile?.email,
      currentOrganisation: currentOrg,
      currentBuyerGuide: currentBuyerGuideId,
      currentPackage: currentPackage.id,
    };
    const response = await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/listOfVendors?",
      {
        method: "POST",
        data: payload,
      },
      setLoading,
      null,
      (error) => {
        console.error("Failed to load vendors:", error);
      }
    );

    if (response) {
      // Update the vendors state with a toggled field set to false for each vendor
      const updatedVendors = response.map((vendor) => ({
        ...vendor,
        toggled: false,
      }));
      setVendors(updatedVendors);

      // Collect vendor IDs and offer IDs in temporary arrays
      let vendorsToInclude = [];
      let offersToInclude = [];

      updatedVendors.forEach((vendor) => {
        if (vendor.isSelected) {
          // Add the vendor_id to the vendorsToInclude array if vendor is selected
          vendorsToInclude.push(vendor.vendor_id);

          // Filter and collect the offer IDs that are selected
          const selectedOffers = vendor.offers
            .filter((offer) => offer.isSelected === true)
            .map((offer) => offer.offer_id);

          // Add the selected offer IDs to the offersToInclude array
          offersToInclude = [...offersToInclude, ...selectedOffers];
        }
      });

      // Update state once with the accumulated vendor and offer IDs
      setVendorsToInclude((prev) => [...prev, ...vendorsToInclude]);
      setOfferToInclude((prev) => [...prev, ...offersToInclude]);
    }
  };

  useEffect(() => {
    loadVendors();
    // eslint-disable-next-line
  }, [userProfile, currentOrg, currentBuyerGuideId, currentPackage.id, currentScenarioId]);

  /**
   *
   * @param {vendor} @object of vendor
   */
  const handleToggle = (vendor) => {
    if (vendor.vendor_id === vendorId && vendor.toggled) {
      setShow(false); // Hide the vendor details
      setVendors((prevVendors) =>
        prevVendors.map(
          (vendorD) =>
            vendorD.vendor_id === vendor.vendor_id
              ? { ...vendorD, toggled: false } // Set the current vendor's toggled state to false
              : vendorD // Keep other vendors the same
        )
      );
    } else {
      // For a new vendor or if the current one is not toggled
      setShow(true); // Show the vendor details
      setSelectedVendor(vendor); // Update the selected vendor
      setVendorId(vendor?.vendor_id); // Track the selected vendor by ID
      // Update the vendors' toggled state
      setVendors((prevVendors) =>
        prevVendors.map(
          (vendorD) =>
            vendorD.vendor_id === vendor.vendor_id
              ? { ...vendorD, toggled: true } // Set the current vendor's toggled state to true
              : { ...vendorD, toggled: false } // Set all others to toggled: false
        )
      );
    }
  };

  /**
   *
   * @param {vendorid} vendor_id
   * @returns
   */
  const handleLockVendor = (vendorid) => {
    setVendorsToUnlock((prev) => {
      if (prev.includes(vendorid)) {
        return prev.filter((vendor) => vendor !== vendorid);
      } else {
        return [...prev, vendorid];
      }
    });
  };

  /**
   *
   * @param {vendorid || null} vendorid
   */
  const handleVendorInclude = (vendorid = null) => {
    if (vendorid === null) {
      // Handle "Select All" logic
      const unlockedVendors = vendors.filter((v) => v.isUnlocked);
      // Filter only unlocked vendors once and store them

      const allSelected = vendorsToInclude.length === unlockedVendors.length;
      // Check if all unlocked vendors are selected

      if (allSelected) {
        // If all unlocked vendors are selected, clear the selection
        setVendorsToInclude([]); // Clear selected vendor IDs
        setOfferToInclude([]); // Clear selected offer IDs
        return; // Exit the function
      } else {
        // If not all are selected, include only unlocked vendor IDs
        setVendorsToInclude(() => {
          setOfferToInclude(
            unlockedVendors.flatMap((vendor) => vendor.offers.map((offer) => offer.offer_id))
            // Use flatMap to get all offer IDs from each vendor's offers array and flatten them into a single array
          );
          return unlockedVendors.map((vendor) => vendor.vendor_id);
        });
        // Set vendorsToInclude to only the IDs of unlocked vendors
      }
    } else {
      const vendor = vendors.find((v) => v.vendor_id === vendorid); // Find the vendor by vendorid
      const offerIds = vendor.offers.map((offer) => offer.offer_id); // Get the list of offer IDs for the vendor

      // Update vendorsToInclude
      setVendorsToInclude((prev) => {
        if (prev.includes(vendorid)) {
          // If the vendor is already selected, remove it
          setOfferToInclude((prevOffers) => {
            // Remove the offers associated with the deselected vendor
            return prevOffers.filter((id) => !offerIds.includes(id));
          });
          return prev.filter((vendor) => vendor !== vendorid); // Remove the vendor ID from vendorsToInclude
        } else {
          // If the vendor is not selected, add it
          setOfferToInclude((prevOffers) => {
            // Add the offers associated with the newly selected vendor
            return [...new Set([...prevOffers, ...offerIds])]; // Use Set to avoid duplicates
          });
          return [...prev, vendorid]; // Add the vendor ID to vendorsToInclude
        }
      });
    }
  };

  /**
   *
   * @param {offerid || null} offerid
   */
  const handleOfferInclude = (offerid = null, vendor_id = null) => {
    if (offerid === null && vendor_id) {
      // For "Select All" logic

      const offerIds = selectedVendor.offers.map((offer) => offer.offer_id); // Get all offer IDs for this vendor

      setOfferToInclude((prevOffers) => {
        const allSelected = offerIds.every((id) => prevOffers.includes(id)); // Check if all offers for this vendor are selected

        if (allSelected) {
          // If all offers are selected, deselect all offers for this vendor
          setVendorsToInclude((prevVendors) =>
            prevVendors.filter((id) => id !== selectedVendor.vendor_id)
          ); // Remove vendor from vendorsToInclude

          return prevOffers.filter((id) => !offerIds.includes(id)); // Remove vendor's offers from offerToInclude
        } else {
          // If not all offers are selected, select all offers for this vendor
          setVendorsToInclude((prevVendors) => [...prevVendors, selectedVendor.vendor_id]); // Add vendor to vendorsToInclude

          return [...new Set([...prevOffers, ...offerIds])]; // Add all offers of this vendor, avoid duplicates with Set
        }
      });
    } else {
      // For single offer selection
      setOfferToInclude((prevOffers) => {
        if (prevOffers.includes(offerid)) {
          // If the offer is already selected, deselect it
          const updatedOffers = prevOffers.filter((offer) => offer !== offerid); // Remove the selected offer
          const anyOffersSelected = selectedVendor.offers.some((offer) =>
            updatedOffers.includes(offer.offer_id)
          );

          // Check if any other offers from the selected vendor are still selected
          if (!anyOffersSelected) {
            setVendorsToInclude((prev) => prev.filter((id) => id !== selectedVendor.vendor_id)); // Remove vendor if no offers are selected
          }

          return updatedOffers;
        } else {
          // Otherwise, select the offer
          if (!vendorsToInclude.includes(selectedVendor.vendor_id)) {
            setVendorsToInclude((prev) => [...prev, selectedVendor.vendor_id]);
          }
          return [...prevOffers, offerid];
        }
      });
    }
  };

  /**
   * The handleDialogClose function sets the dialog to close and navigates to the "/packages" page if
   * confirmed.
   */
  const handleDialogClose = (confirm) => {
    setDialogOpen(false);
    if (confirm) {
      navigate("/packages");
    }
  };

  const dataTosend = useMemo(() => {
    return vendors.map((vendor) => {
      return {
        vendor_id: vendor.vendor_id,
        isSelected: vendorsToInclude.includes(vendor.vendor_id),
        offers: vendor.offers.map((offer) => {
          return {
            offer_id: offer.offer_id,
            isSelected:
              offerToInclude?.includes(offer.offer_id) &&
              vendorsToInclude?.includes(vendor.vendor_id),
          };
        }),
      };
    });
  }, [vendors, vendorsToInclude, offerToInclude]);

  const handelSaveAndContinue = async () => {
    const payload = {
      email: userProfile?.email,
      currentOrganisation: currentOrg,
      currentBuyerGuide: currentBuyerGuideId,
      currentPackage: currentPackage.id,
      currentScenario: currentScenarioId,
      selectedvendorsAndOffers: dataTosend,
    };
    const response = await apiService(
      "https://newtestfuncpython.azurewebsites.net/api/updateVendorOptions?",
      {
        method: "POST",
        data: payload,
      },
      setLoading,
      null,
      (error) => {
        console.error("Failed to update vendors and offers:", error);
      }
    );
    if (response.message === "Successfully saved.") {
      navigate("/edit-scenario/update-use-cases");
      setNotification({
        open: true,
        message: "Saved Selected Vendor and offers.",
        type: "success",
      });
    }
  };

  return loading ? (
    <Box
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      display="flex"
    >
      <CircularProgress />
    </Box>
  ) : vendors.length > 0 ? (
    <Box sx={{ width: "100%", padding: "20px" }}>
      <HeaderNaming data={data} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* LHS */}
        <Grid item xs={12} md={6}>
          <List sx={{ width: "100%", maxWidth: 500, bgcolor: "" }}>
            <Typography variant="h3" textAlign="center">
              Vendor List
            </Typography>
            {vendors.some((item) => item.isUnlocked === true) && (
              <>
                <ListItem>
                  <ListItemButton dense onClick={() => handleVendorInclude()}>
                    <ListItemIcon>
                      <Checkbox
                        color="secondary"
                        checked={
                          vendorsToInclude.length === vendors.filter((v) => v.isUnlocked).length
                        }
                        name="selectAll"
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}

            {/* rendering vendors with check box */}
            {vendors.map((vendor) => (
              <>
                <ListItem key={vendor.vendor_id}>
                  <ListItemButton
                    dense
                    selected={vendorId === vendor.vendor_id}
                    onClick={() => {
                      handleToggle(vendor);
                    }}
                  >
                    {vendor.isUnlocked ? (
                      <>
                        <ListItemIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            handleVendorInclude(vendor.vendor_id);
                          }}
                        >
                          <Checkbox
                            color="secondary"
                            checked={vendorsToInclude?.includes(vendor.vendor_id)}
                          />
                        </ListItemIcon>
                      </>
                    ) : (
                      <>
                        <ListItemIcon
                          onClick={(e) => {
                            handleLockVendor(vendor.vendor_id);
                            e.stopPropagation();
                          }}
                          sx={{
                            height: "42px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {vendorsToUnlock?.includes(vendor.vendor_id) ? <Check /> : <Lock />}
                        </ListItemIcon>
                      </>
                    )}
                    <ListItemText primary={vendor.vendor_name} />
                    <ListItemText
                      secondary={
                        vendor.offers.filter((offer) => offerToInclude?.includes(offer.offer_id))
                          .length !== vendor.offers.length
                          ? `${
                              vendor.offers.filter((offer) =>
                                offerToInclude?.includes(offer.offer_id)
                              ).length
                            }/${vendor.offers.length}`
                          : ""
                      }
                      aria-details="selectedOfferCount"
                      sx={{ textAlign: "right" }}
                    />
                  </ListItemButton>

                  <IconButton
                    sx={{ marginLeft: "5px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleToggle(vendor);
                    }}
                  >
                    {vendor.toggled ? <Remove color="secondary" /> : <Add color="primary" />}
                  </IconButton>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Grid>
        {/* LHS */}
        {/* RHS */}
        {show && selectedVendor && (
          <Grid item xs={12} md={6}>
            <List sx={{ width: "100%", maxWidth: 500, bgcolor: "" }}>
              <Typography variant="h3" textAlign="center">
                {selectedVendor.vendor_name} - Offer List
              </Typography>

              {selectedVendor.isUnlocked && (
                <>
                  <ListItem>
                    <ListItemButton
                      dense
                      onClick={() => handleOfferInclude(null, selectedVendor.vendor_id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          color="secondary"
                          name="selectAll"
                          checked={selectedVendor?.offers?.every((offer) =>
                            offerToInclude?.includes(offer.offer_id)
                          )}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Select All" />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              )}

              {selectedVendor.offers.map((offer) => (
                <ListItem key={offer.offer_id}>
                  <ListItemButton sx={{ cursor: "default" }}>
                    {selectedVendor.isUnlocked && (
                      <ListItemIcon onClick={() => handleOfferInclude(offer.offer_id)}>
                        <Checkbox
                          color="secondary"
                          checked={offerToInclude?.includes(offer.offer_id)}
                        />
                      </ListItemIcon>
                    )}
                    <ListItemText primary={offer.offer_name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </Grid>
      <Box marginTop={5} display="flex" flexDirection="column" justifyContent="center" gap={2}>
        <Button
          sx={{ width: "250px" }}
          color="primary"
          variant="contained"
          disableRipple
          onClick={handelSaveAndContinue}
        >
          <Typography data-test-id="Save&Continue" color="white"> Save & Continue </Typography>
        </Button>
        {unlockVendorCount < packageLimits[currentPackage?.package_type] && (
          <Button
            sx={{ width: "250px" }}
            color="primary"
            variant="contained"
            disableRipple
            onClick={handleUnlockVendor}
          >
            Unlock Vendors
          </Button>
        )}

        <Button
          sx={{ width: "250px" }}
          color="secondary"
          variant="contained"
          disableRipple
          onClick={() => navigate("/results/comparative-analysis")}
        >
          <Typography color="white">Save & Exit to Main Dashboard</Typography>
        </Button>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Limit Exceeding"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are trying to unlock more vendors than your current package allows. Would you like
            to upgrade your package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleDialogClose(true)} color="primary" autoFocus>
            Upgrade Package
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={notification.open} autoHideDuration={6000} onClose={handleCloseNotification}>
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  ) : (
    <>
      <NoDataAvailable message={"Somthing Went Wrong"} />
    </>
  );
}
