// import React from 'react';
// import { Box, Typography, Container,Button } from '@mui/material';
// import { styled } from '@mui/system';
// import { useNavigate } from 'react-router-dom';
// import NearMeIcon from '@mui/icons-material/NearMe';
// import ExploreIcon from '@mui/icons-material/Explore';
// import MapIcon from '@mui/icons-material/Map';
// import LayersIcon from '@mui/icons-material/Layers';

// // Reuse the StyledButton from AnalystWelcome
// const StyledButton = styled(Button)(({ theme }) => ({
//  position: "relative",
//  overflow: "hidden",
//  padding: "2.5rem",
//  height: "100%",
//  display: "flex",
//  flexDirection: "column",
//  justifyContent: "center",
//  alignItems: "center",
//  boxShadow: theme.shadows[3],
//  background: "transparent",
//  color: theme.palette.text.primary,
//  transition: "color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//  "&:before": {
//    content: '""',
//    position: "absolute",
//    left: 0,
//    bottom: 0,
//    width: "100%",
//    height: "0%",
//    backgroundColor: theme.palette.primary.main,
//    zIndex: 0,
//    transition: "height 0.3s ease-in-out",
//  },
//  "&:hover:before": {
//    height: "100%",
//    color: "#fff",
//  },
//  "&:hover": {
//    boxShadow: theme.shadows[6],
//    color: "#fff",
//  },
//  "& .MuiTypography-root": {
//    position: "relative",
//    zIndex: 1,
//    transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
//  },
//  "& .title": {
//    transform: "translateY(0)",
//    textAlign: "center",
//    height: "auto",
//    display: "block",
//  },
//  "& .subtitle": {
//    display: "none",
//    transform: "translateY(100%)",
//    color: "#fff",
//    textAlign: "center",
//  },
//  "&:hover .title": {
//    transform: "translateY(-100%)",
//    height: 0,
//    visibility: "hidden",
//    overflow: "hidden",
//    margin: 0,
//    padding: 0,
//    display: "none",
//  },
//  "&:hover .subtitle": {
//    display: "block",
//    transform: "translateY(0)",
//  },
//  "& .icon": {
//    fontSize: "3rem",
//    marginBottom: "1rem",
//  },
//  "&:hover .icon": {
//    opacity: 0,
//    transform: "scale(0)",
//    display: "none",
//  },
// }));

// const MappingType = () => {
//  const navigate = useNavigate();

//  return (
//    <Container
//      sx={{
//        textAlign: "center",
//        marginTop: "3rem", // Increased margin for vertical spacing
//        height: "70%",
//        width: "100%",
//      }}
//    >
//      <Typography variant="h2" gutterBottom>
//        Mapping Types
//      </Typography>
//      <Typography variant="h4" gutterBottom sx={{ color: "#666666", marginBottom: "3rem" }}>
//        Choose the mapping type you want to explore
//      </Typography>

//      <Box
//        sx={{
//          display: "grid",
//          gridTemplateColumns: "repeat(2, 290px)", // Ensures two buttons per row
//          gridGap: "3rem", // Ensures equal vertical and horizontal gap
//          justifyContent: "center", // Centers the grid
//          alignItems: "center", // Aligns buttons vertically in the grid
//        }}
//      >
//        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/buyer-guide-mapping-one-to-one")}>
//          <Typography variant="h2" gutterBottom className="title">
//            1:1 Buyer Guide To Offer Mapping
//          </Typography>
//          <MapIcon className="icon" />
//          <Typography className="subtitle" variant="body1">
//          1:1 Buyer Guide To Offer Mapping
//          </Typography>
//        </StyledButton>

//        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-scenario")}>
//          <Typography variant="h2" gutterBottom className="title">
//          1:n Buyer Guide To Offer Mapping
//          </Typography>
//          <ExploreIcon className="icon" />
//          <Typography className="subtitle" variant="body1">
//          1:n Buyer Guide To Offer Mapping
//          </Typography>
//        </StyledButton>

//        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-type-nearme")}>
//          <Typography variant="h2" gutterBottom className="title">
//          m:1 Buyer Guide To Offer Mapping
//          </Typography>
//          <NearMeIcon className="icon" />
//          <Typography className="subtitle" variant="body1">
//          m:1 Buyer Guide To Offer Mapping
//          </Typography>
//        </StyledButton>

//        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-layer")}>
//          <Typography variant="h2" gutterBottom className="title">
//          m:n Buyer Guide To Offer Mapping
//          </Typography>
//          <LayersIcon className="icon" />
//          <Typography className="subtitle" variant="body1">
//          m:n Buyer Guide To Offer Mapping
//          </Typography>
//        </StyledButton>
//      </Box>
//    </Container>
//  );
// };

// export default MappingType;


import React,{ useContext } from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import NearMeIcon from '@mui/icons-material/NearMe';
import ExploreIcon from '@mui/icons-material/Explore';
import MapIcon from '@mui/icons-material/Map';
import LayersIcon from '@mui/icons-material/Layers';
import { UserProfileContext } from "../context/UserContext";

// Reuse the StyledButton from AnalystWelcome
const StyledButton = styled(Button)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  padding: "2.5rem",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: theme.shadows[3],
  background: "transparent",
  color: theme.palette.text.primary,
  transition: "color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:before": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "0%",
    backgroundColor: theme.palette.primary.main,
    zIndex: 0,
    transition: "height 0.3s ease-in-out",
  },
  "&:hover:before": {
    height: "100%",
    color: "#fff",
  },
  "&:hover": {
    boxShadow: theme.shadows[6],
    color: "#fff",
  },
  "& .MuiTypography-root": {
    position: "relative",
    zIndex: 1,
    transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
  },
  "& .title": {
    transform: "translateY(0)",
    textAlign: "center",
    height: "auto",
    display: "block",
  },
  "& .subtitle": {
    display: "none",
    transform: "translateY(100%)",
    color: "#fff",
    textAlign: "center",
  },
  "&:hover .title": {
    transform: "translateY(-100%)",
    height: 0,
    visibility: "hidden",
    overflow: "hidden",
    margin: 0,
    padding: 0,
    display: "none",
  },
  "&:hover .subtitle": {
    display: "block",
    transform: "translateY(0)",
  },
  "& .icon": {
    fontSize: "3rem",
    marginBottom: "1rem",
  },
  "&:hover .icon": {
    opacity: 0,
    transform: "scale(0)",
    display: "none",
  },
}));

const MappingType = () => { // Assuming userProfile is passed as a prop
  const navigate = useNavigate();
  const { userProfile } = useContext(UserProfileContext);

  // Function to fetch buyer guide data
  const fetchBuyerGuideData = async () => {
    const url = "https://newtestfuncpython.azurewebsites.net/api/getBuyerGuideForAnalyst";
    try {
      const response = await axios.post(url, { email: userProfile.email });

      const buyerGuides = response.data;
      navigate('/buyer-guide-mapping-one-to-one', { state: { buyerGuides } });
    } catch (error) {
      console.error('Failed to fetch buyer guides:', error);
    }
  };

  return (
    <Container
      sx={{
        textAlign: "center",
        marginTop: "3rem",
        height: "70%",
        width: "100%",
      }}
    >
      <Typography variant="h2" gutterBottom>
        Mapping Types
      </Typography>
      <Typography variant="h4" gutterBottom sx={{ color: "#666666", marginBottom: "3rem" }}>
        Choose the mapping type you want to explore
      </Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 290px)", // Ensures two buttons per row
          gridGap: "3rem", // Ensures equal vertical and horizontal gap
          justifyContent: "center", // Centers the grid
          alignItems: "center", // Aligns buttons vertically in the grid
        }}
      >
        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={fetchBuyerGuideData}>
          <Typography variant="h2" gutterBottom className="title">
            1:1 Buyer Guide To Offer Mapping
          </Typography>
          <MapIcon className="icon" />
          <Typography className="subtitle" variant="body1">
            1:1 Buyer Guide To Offer Mapping
          </Typography>
        </StyledButton>

        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-scenario")}>
          <Typography variant="h2" gutterBottom className="title">
            1:n Buyer Guide To Offer Mapping
          </Typography>
          <ExploreIcon className="icon" />
          <Typography className="subtitle" variant="body1">
            1:n Buyer Guide To Offer Mapping
          </Typography>
        </StyledButton>

        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-type-nearme")}>
          <Typography variant="h2" gutterBottom className="title">
            m:1 Buyer Guide To Offer Mapping
          </Typography>
          <NearMeIcon className="icon" />
          <Typography className="subtitle" variant="body1">
            m:1 Buyer Guide To Offer Mapping
          </Typography>
        </StyledButton>

        <StyledButton variant="outlined" sx={{ height: "280px", width: "290px" }} onClick={() => navigate("/mapping-layer")}>
          <Typography variant="h2" gutterBottom className="title">
            m:n Buyer Guide To Offer Mapping
          </Typography>
          <LayersIcon className="icon" />
          <Typography className="subtitle" variant="body1">
            m:n Buyer Guide To Offer Mapping
          </Typography>
        </StyledButton>
      </Box>
    </Container>
  );
};

export default MappingType;
