import React, { useState, useEffect, useContext, memo } from "react";
import DataGridTable from "../../../components/DataGridTable";
import { Box, CircularProgress, Divider } from "@mui/material";

//import { apiService } from "../../../utils/utils";
import { apiService } from "../../../utils/utils";
import theme from "../../../theme/theme";
import { UserProfileContext } from "../../../context/UserContext";
import "../../GridLayout/GridComponent.css";
import NoDataAvilable from "../../../components/NoDataAvilable";

const getColumnNames = (name) => {
  switch (name) {
    case "metrics":
      return "Offer Metrics";
    case "metric":
      return "Offer Metric Value (%)";
    case "vendorMax":
      return "Best Offer Metric- Vendor (%)";
    case "scenarioMax":
      return "Best Offer Metric-Scenario (%)";
    case "offerRank":
      return "Offer Rank";
    default:
      return name;
  }
};
const MandatoryUseCaseTable = memo(
  ({
    menu,
    isViewReport,
    quadrantOffer,
    setCommentary,
    field,
    section,
    offer,
    table,
    widgetName,
    widgetKey,
    widgetLabels,
    setLayout,
    issmallscreen,
    offerData,
  }) => {
    const [rows, setRows] = useState([]);
    const [data, setData] = useState(null);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [, setCommentryLoading] = useState(true);
    const [error, setError] = useState(null);
    const [, setCommentryData] = useState("");
    const {
      currentOrg,
      userProfile,
      currentOrganizationId,
      currentScenarioId,
      currentBuyerGuideId,
      scenarioName,
      buyerGuideName,
      currentPackage,
      offerId,
      valyouMatrixData,
    } = useContext(UserProfileContext);
    const staticData =
      "### Summary of Top 5 Unused Features ('Not Required' Use Cases)\r\n\r\nThe widget titled \"Top 5 Unused Features ('Not Required' Use Cases)\" identifies the five least utilized features within a given application, specifically highlighting areas where these features are deemed unnecessary by users. The data represents an analysis of feature usage within the Zscaler Private Access platform. Each feature listed contributes equally to the total share of unused features, with a contribution score of 11.11%.\r\n\r\n#### Detailed Breakdown:\r\n\r\n1. **Secure Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce\r\n\r\n2. **Secure Hybrid Workforce**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Secure Workforce > Secure Hybrid Workforce\r\n\r\n3. **Branch & Remote Office Connectivity**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Branch & Remote Office Connectivity\r\n\r\n4. **App Segmentation**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > App Segmentation\r\n\r\n5. **Digital Experience Monitoring**\r\n   - **Feature Contribution Score:** 11.11%\r\n   - **Lineage:** Zscaler Private Access > Monitoring & Optimization > Digital Experience Monitoring\r\n\r\nEach feature’s lineage indicates its hierarchical placement within the broader Zscaler Private Access system, providing context on its primary function and relationship to other functionalities within the platform. By identifying these unused features, stakeholders can gain insights into possible areas for optimization, user education, or feature redesign, aligning product offerings more closely with user needs and preferences.";
    useEffect(() => {
      const fetchData = async () => {
        try {
          const payload = {
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            currentOffer: isViewReport ? offerData?.offerId : offerId,
          };
          let localStoreTableData = JSON.parse(
            localStorage.getItem(
              `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`
            )
          );

          let response = null;
          if (isViewReport && localStoreTableData) {
            response = localStoreTableData;
            setLoading(false);
            setData(response);
          } else {
            response = await apiService(
              "https://newtestfuncpython.azurewebsites.net/api/getMandatoryMetricsAndRank?",
              {
                method: "POST",
                data: payload,
              },
              setLoading,
              setData,
              setError
            );
            if (isViewReport) {
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-${table}`,
                JSON.stringify(response)
              );
            }
          }

          const columnKeys = Object.keys(response[0]);
          const transformedColumns = columnKeys.map((key, index) => ({
            field: key, // Use the key as field name
            headerName: getColumnNames(key), // Format header name with spaces (optional)
            flex: 1,
            minWidth: 250,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
              <Box
                sx={{
                  overflowY: "hidden",
                  display: "flex",
                  justifyContent: index === 0 ? "flex-start" : "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                {params.value}
              </Box>
            ),
          }));

          const transformedRows = response.map((data, index) => {
            const { ...rowData } = data; // Destructure to exclude "metric"
            return {
              id: index, // Ensure unique ID for each row
              ...rowData, // Spread the remaining data into the row object
            };
          });

          setColumns(transformedColumns);
          setRows(transformedRows);
          setLoading(false);
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setError(error);
          setLoading(false);
        }
      };

      fetchData();
    }, [
      userProfile,
      currentOrg,
      scenarioName,
      buyerGuideName,
      issmallscreen,
      offerId,
      isViewReport,
      quadrantOffer,
      setLayout,
      widgetKey,
      field,
      offer,
      section,
      table,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage.id,
      currentScenarioId,
      offerData,
    ]);
    useEffect(() => {
      if (!isViewReport) {
        setLayout((prev) => {
          return prev.map((item) => {
            if (item.i !== widgetKey) {
              return item;
            } else {
              return {
                ...item,
                h: 1.5 + (rows.length > 0 ? rows.length : 1),
              };
            }
          });
        });
      }
    }, [rows.length, isViewReport, setLayout, widgetKey]);

    useEffect(() => {
      if (isViewReport) {
        const reportData = JSON.parse(
          localStorage.getItem(
            `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-MandatoryUsecase`
          )
        );

        const fetchData = async () => {
          const MandatoryOffers = valyouMatrixData?.MandatoryOffers||[];
          const OverallOffers = valyouMatrixData?.OverallOffers||[];
          const index =
            OverallOffers?.findIndex((item) => {
              return item?.OfferName === offer ? true : false;
            }) ||
            MandatoryOffers?.findIndex((item) => {
              return item?.OfferName === offer ? true : false;
            });
          const vendorName = OverallOffers[index]?.VendorName || MandatoryOffers[index]?.VendorName;
          const payload = {
            widget_name: widgetName,
            widget_json: data,
            buyerGuideName: buyerGuideName,
            offerName: offer,
            vendorName: vendorName,
            email: userProfile?.email,
            currentOrganisation: currentOrganizationId,
            currentBuyerGuide: currentBuyerGuideId,
            currentPackage: currentPackage.id,
            currentScenario: currentScenarioId,
            currentOffer: isViewReport ? offerData?.offerId : offerId,
          };
          if (widgetName && data) {
            try {
              const response = await apiService(
                "https://newtestfuncpython.azurewebsites.net/api/generateWidgetSummary?",
                {
                  method: "POST",
                  data: payload,
                },
                setCommentryLoading,
                setCommentryData,
                setError
              );

              setCommentary((prev) => {
                return {
                  ...prev,
                  [section]: {
                    ...prev[section],
                    [offer]: {
                      ...prev[section][offer],
                      [field]: {
                        ...prev[section][offer][field],
                        tables: {
                          ...prev[section][offer][field].tables,
                          [table]: {
                            ...prev[section][offer][field].tables[table],
                            commentary: response,
                            isLoading: false,
                          },
                        },
                      },
                      offerSummary: {
                        ...prev[section][offer].offerSummary,
                        all_widget_summaries: {
                          ...prev[section][offer].offerSummary.all_widget_summaries,
                          [widgetName]: response,
                        },
                        widget_jsons_input: {
                          ...prev[section][offer].offerSummary.widget_jsons_input,
                          [widgetName]: data,
                        },
                      },
                    },
                  },
                };
              });

              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-MandatoryUsecase`,
                JSON.stringify({ summary: response })
              );
            } catch (error) {
              console.error("Failed to fetch data:", error);
              setError(error);
              setCommentary((prev) => {
                return {
                  ...prev,
                  [section]: {
                    ...prev[section],
                    [offer]: {
                      ...prev[section][offer],
                      [field]: {
                        ...prev[section][offer][field],
                        tables: {
                          ...prev[section][offer][field].tables,
                          [table]: {
                            ...prev[section][offer][field].tables[table],
                            commentary: staticData,
                            isLoading: false,
                          },
                        },
                      },
                      offerSummary: {
                        ...prev[section][offer].offerSummary,
                        all_widget_summaries: {
                          ...prev[section][offer].offerSummary.all_widget_summaries,
                          [widgetName]: staticData,
                        },
                        widget_jsons_input: {
                          ...prev[section][offer].offerSummary.widget_jsons_input,
                          [widgetName]: data,
                        },
                      },
                    },
                  },
                };
              });
              localStorage.setItem(
                `${currentOrg}-${buyerGuideName}-${scenarioName}-${section}-${offer}-${field}-MandatoryUsecase`,
                JSON.stringify({ summary: staticData })
              );
            }
          }
        };

        if (widgetName && data && !reportData?.summary) {
          fetchData();
        } else if (widgetName && data) {
          setCommentary((prev) => {
            return {
              ...prev,
              [section]: {
                ...prev[section],
                [offer]: {
                  ...prev[section][offer],
                  [field]: {
                    ...prev[section][offer][field],
                    tables: {
                      ...prev[section][offer][field].tables,
                      [table]: {
                        ...prev[section][offer][field].tables[table],
                        commentary: reportData.summary,
                        isLoading: false,
                      },
                    },
                  },
                  offerSummary: {
                    ...prev[section][offer].offerSummary,
                    all_widget_summaries: {
                      ...prev[section][offer].offerSummary.all_widget_summaries,
                      [widgetName]: reportData.summary,
                    },
                    widget_jsons_input: {
                      ...prev[section][offer].offerSummary.widget_jsons_input,
                      [widgetName]: data,
                    },
                  },
                },
              },
            };
          });
        } else {
          return;
        }
      }
    }, [
      section,
      offer,
      field,
      table,
      setCommentary,
      data,
      widgetName,
      currentOrg,
      scenarioName,
      isViewReport,
      offerId,
      buyerGuideName,
      valyouMatrixData,
      currentBuyerGuideId,
      currentOrganizationId,
      currentPackage.id,
      currentScenarioId,
      offerData,
      userProfile
    ]);

    return loading ? (
      // If loading is true, display a loading spinner inside a box.
      <Box
        sx={{
          height: "70px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    ) : rows.length > 0 ? (
      <Box
        sx={{
          "& .super-app-theme--header": {
            backgroundColor: theme.palette.primary.main,

            color: "white",
          },
        }}
      >
        <Divider sx={{ marginY: "10px", width: "95%" }} />
        <Box
          className="thinner-scrollbar"
          sx={{
            width: "100%",
            height: "100%",
            paddingX: "10px",
            overflowX: "auto",
          }}
        >
          <DataGridTable
            name={widgetLabels}
            rows={rows}
            columns={columns}
            loading={loading}
            error={error}
            menu={menu}
            sx={{
              minWidth: "1080px",
              "& .MuiDataGrid-cell": {
                wordWrap: "break-word",
                whiteSpace: "normal",
              },
            }}
          />
        </Box>
      </Box>
    ) : (
      // If no data (rows.length === 0), display a message.
      <NoDataAvilable widgetLabels={widgetLabels} widgetKey={widgetKey} />
    );
  }
);

export default MandatoryUseCaseTable;
