import React, { useState } from "react";
import { Box, Typography, Card, CardContent, Tooltip } from "@mui/material";

const levelColors = [
  "#FFE0B2", 
  "#40BAB4", 
  "#C8E6C9", 
  "#FFF9C4", 
  "#E1BEE7", 
  "#FFE0B2", 
];

const TreeNode = ({ data, level }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
    if (data["Use Case"]) {
      console.log("Children Data of", data.useCaseName || data.buyerGuideName, ":", data["Use Case"]);
    } else {
      console.log("No further children available for", data.useCaseName || data.buyerGuideName);
    }
  };

  const importanceScale = Math.min(Math.max(data.importance, 1), 10); 
  const baseWidth = `${importanceScale * 10 + 100}px`; 
  const expandedWidth = isExpanded && level > 1 ? "90%" : baseWidth; 

  return (
    <Card
      onClick={(e) => {
        e.stopPropagation();
        toggleExpand();
      }}
      sx={{
        margin: "0.5px", 
        borderRadius: 2,
        border: "1px solid black", 
        backgroundColor: levelColors[level % levelColors.length],
        cursor: "pointer",
        width: expandedWidth,  
        minWidth: baseWidth,   
        height: "auto", 
        transition: "all 0.3s ease",
        ":hover": {
          transform: "scale(1.02)",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        },
        flexGrow: 1,
        flexBasis: "33.33%", 
      }}
    >
      <CardContent>
        <Tooltip title={data.useCaseName || data.buyerGuideName} placement="top">
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              fontSize: level > 2 ? "1rem" : "1.2rem",
            }}
          >
            {data.useCaseName || data.buyerGuideName}
          </Typography>
        </Tooltip>
        <Typography variant="body2">Importance: {data.importance}</Typography>
        {isExpanded && data["Use Case"] && (
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%", 
            }}
          >
            {data["Use Case"].map((child, index) => (
              <TreeNode key={index} data={child} level={level + 1} />
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

const TreeMap = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap", 
        justifyContent: "flex-start", 
        alignItems: "flex-start",
        width: "100%",
        padding: 0,
        boxSizing: "border-box",
        border: "2px solid #7b1fa2", 
        backgroundColor: "#e9f9f9", 
        borderRadius: 4, 
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
      }}
    >
      {data.map((item, index) => (
        <TreeNode key={index} data={item} level={1} />
      ))}
    </Box>
  );
};

export default TreeMap;
