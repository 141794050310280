// import React, { useState,useContext } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Box, Typography, Button, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Snackbar, ThemeProvider, useTheme, Container, FormControlLabel, Radio } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';
// import theme from '../theme/theme';
// import { UserProfileContext } from "../context/UserContext";

// const VendorOfferMenu = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const muiTheme = useTheme();
//   const { userProfile } = useContext(UserProfileContext);

//   // Extracting offers from location.state, which comes from the previous page
//   const offers = location.state?.offers ?? [];
//   // console.log("Offer Data-->  ", offers); // Debug statement to verify data

//   const [selectedOfferId, setSelectedOfferId] = useState(null);
//   const [collaboratorEmail, setCollaboratorEmail] = useState('');
//   const [openInvite, setOpenInvite] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   const toggleSelection = (id) => {
//     setSelectedOfferId(selectedOfferId === id ? null : id);
//     localStorage.setItem('selectedOfferId', id);
//   };

//   const columns = [
//     {
//       field: 'select',
//       headerName: 'Select',
//       width: 150,
//       headerClassName: 'super-app-theme--header',
//       renderCell: (params) => (
//         <FormControlLabel
//           control={
//             <Radio
//               checked={selectedOfferId === params.id}
//               onClick={() => toggleSelection(params.id)}
//               value={params.id}
//             />
//           }
//           label=""
//         />
//       ),
//     },
//     { field: 'offerName', headerName: 'Offer Name', flex: 1, headerClassName: 'super-app-theme--header' },
//     { field: 'status', headerName: 'Status', flex: 1, headerClassName: 'super-app-theme--header' },
//     { field: 'version', headerName: 'Version', flex: 1, headerClassName: 'super-app-theme--header' },
//     { field: 'createdBy', headerName: 'Created By', flex: 1, headerClassName: 'super-app-theme--header' }
//   ];

//   const handleOpenInvite = () => setOpenInvite(true);
//   const handleCloseInvite = () => setOpenInvite(false);
//   const handleCloseSnackbar = () => setOpenSnackbar(false);

//   const handleSaveInvite = async () => {
//     if (!collaboratorEmail || !selectedOfferId) {
//       console.error("Collaborator email or selected offer is missing");
//       return;
//     }

//     const payload = {
//       email: userProfile.email,  // Adjust as necessary
//       currentOfferId: selectedOfferId,  // Use the selected offer ID
//       collaboratorEmail: collaboratorEmail
//     };

//     try {
//       const response = await fetch(
//         "https://newtestfuncpython.azurewebsites.net/api/inviteAnalystToCoCreateOffer",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json"
//           },
//           body: JSON.stringify(payload)
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to send invitation");
//       }

//       // const data = await response.json();
//       // console.log("Invitation sent successfully:", data);
//       setOpenSnackbar(true);
//     } catch (error) {
//       console.error("Error sending invitation:", error);
//     }

//     handleCloseInvite();
//   };

//   const handleEditOffer = async () => {
//     if (!selectedOfferId) {
//       console.error("No offer selected for editing.");
//       return;
//     }
  
//     const payload = {
//       email: userProfile.email, // Adjust as necessary
//       currentOfferId: selectedOfferId,
//     };
  
//     try {
//       const response = await fetch("https://newtestfuncpython.azurewebsites.net/api/getExistingOfferFeatures", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(payload),
//       });
  
//       if (!response.ok) {
//         throw new Error("Failed to fetch existing offer details");
//       }
  
//       const data = await response.json();
//       // console.log("Existing Offer Details:", data);
  
//       // Navigate to the /analysis page with the fetched data and additional state to indicate it came from VendorOfferMenu
//       navigate('/analysis', { state: { buyerGuideData: data, fromVendorOfferMenu: true } });
//     } catch (error) {
//       console.error("Error fetching existing offer details:", error);
//     }
//   };
  

//   const handleAddNewVendor = () => {
//     navigate('/add-new-offer'); // Adjust the path as necessary
//   };

//   const renderButtons = () => {
//     if (selectedOfferId) {
//       return (
//         <>
//           <Button sx={{ width: '250px' }} variant="contained" color="primary" onClick={handleEditOffer}>
//             Edit Offer
//           </Button>
//           <Button sx={{ width: '250px' }} variant="contained" color="secondary" onClick={handleOpenInvite}>
//             Invite Collaborator
//           </Button>
//         </>
//       );
//     }
//     return (
//       <Button sx={{ width: '250px' }} variant="contained" color="secondary" onClick={handleAddNewVendor}>
//         Add New Offer
//       </Button>
//     );
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Box>
//         <Container sx={{ display: 'contents', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 0 }}>
//           <Typography variant="h4" gutterBottom>
//             Vendor Offers
//           </Typography>
//           <Box>
//             <DataGrid
//               rows={offers}
//               columns={columns}
//               pageSize={5}
//               rowsPerPageOptions={[5]}
//               checkboxSelection={false}
//               hideFooter
//               getRowId={(row) => row.offerId} // Using offerId as the unique identifier
//               sx={{
//                 width: '100%',
//                 height: '100%',
//                 '& .super-app-theme--header': {
//                   backgroundColor: muiTheme.palette.primary.main,
//                   color: 'white',
//                   fontSize: '14px',
//                   '.MuiSvgIcon-root': {
//                     color: 'white',
//                   }
//                 },
//                 '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
//                   fontWeight: 'normal',
//                 },
//                 '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
//                   outline: 'none !important',
//                 },
//               }}
//             />
//           </Box>
//           <Box mt={2} display="flex" flexDirection="column" gap={2}>
//             {renderButtons()}
//           </Box>
//         </Container>
//       </Box>
//       <Dialog open={openInvite} onClose={handleCloseInvite} maxWidth="sm" fullWidth>
//         <DialogTitle>Invite Collaborator</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             id="collaboratorEmail"
//             label="Collaborator's Email"
//             type="email"
//             fullWidth
//             value={collaboratorEmail}
//             onChange={(e) => setCollaboratorEmail(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button variant="outlined" onClick={handleCloseInvite}>Cancel</Button>
//           <Button variant="contained" color="primary" onClick={handleSaveInvite}>Send Invitation</Button>
//         </DialogActions>
//       </Dialog>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={handleCloseSnackbar}
//         message="Invitation sent successfully!"
//       />
//     </ThemeProvider>
//   );
// };

// export default VendorOfferMenu;


// reusable...


// import React, { useState, useContext } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { Box, Typography, Button, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Snackbar, ThemeProvider, useTheme, Container, FormControlLabel, Radio } from '@mui/material';
// import theme from '../theme/theme';
// import { UserProfileContext } from "../context/UserContext";
// import {DataTable} from './reusable/DataTable'; // Updated import for DataTable

// const VendorOfferMenu = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const muiTheme = useTheme();
//   const { userProfile } = useContext(UserProfileContext);

//   // Extracting offers from location.state, which comes from the previous page
//   const offers = location.state?.offers ?? [];

//   const [selectedOfferId, setSelectedOfferId] = useState(null);
//   const [collaboratorEmail, setCollaboratorEmail] = useState('');
//   const [openInvite, setOpenInvite] = useState(false);
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   const toggleSelection = (id) => {
//     setSelectedOfferId(selectedOfferId === id ? null : id);
//     localStorage.setItem('selectedOfferId', id);
//   };

//   const columns = [
//     {
//       field: 'select',
//       headerName: 'Select',
//       width: 150,
//       renderCell: (params) => (
//         <FormControlLabel
//           control={
//             <Radio
//               checked={selectedOfferId === params.id}
//               onClick={() => toggleSelection(params.id)}
//               value={params.id}
//             />
//           }
//           label=""
//         />
//       ),
//     },
//     { field: 'offerName', headerName: 'Offer Name', flex: 1 },
//     { field: 'status', headerName: 'Status', flex: 1 },
//     { field: 'version', headerName: 'Version', flex: 1 },
//     { field: 'createdBy', headerName: 'Created By', flex: 1 },
//   ];

//   const handleOpenInvite = () => setOpenInvite(true);
//   const handleCloseInvite = () => setOpenInvite(false);
//   const handleCloseSnackbar = () => setOpenSnackbar(false);

//   const handleSaveInvite = async () => {
//     if (!collaboratorEmail || !selectedOfferId) {
//       console.error("Collaborator email or selected offer is missing");
//       return;
//     }

//     const payload = {
//       email: userProfile.email,
//       currentOfferId: selectedOfferId,
//       collaboratorEmail: collaboratorEmail,
//     };

//     try {
//       const response = await fetch(
//         "https://newtestfuncpython.azurewebsites.net/api/inviteAnalystToCoCreateOffer",
//         {
//           method: "POST",
//           headers: { "Content-Type": "application/json" },
//           body: JSON.stringify(payload),
//         }
//       );

//       if (!response.ok) throw new Error("Failed to send invitation");

//       setOpenSnackbar(true);
//     } catch (error) {
//       console.error("Error sending invitation:", error);
//     }

//     handleCloseInvite();
//   };

//   const handleEditOffer = async () => {
//     if (!selectedOfferId) {
//       console.error("No offer selected for editing.");
//       return;
//     }

//     const payload = {
//       email: userProfile.email,
//       currentOfferId: selectedOfferId,
//     };

//     try {
//       const response = await fetch("https://newtestfuncpython.azurewebsites.net/api/getExistingOfferFeatures", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(payload),
//       });

//       if (!response.ok) throw new Error("Failed to fetch existing offer details");

//       const data = await response.json();

//       navigate('/analysis', { state: { buyerGuideData: data, fromVendorOfferMenu: true } });
//     } catch (error) {
//       console.error("Error fetching existing offer details:", error);
//     }
//   };

//   const handleAddNewVendor = () => navigate('/add-new-offer');

//   const renderButtons = () => {
//     if (selectedOfferId) {
//       return (
//         <>
//           <Button sx={{ width: '250px' }} variant="contained" color="primary" onClick={handleEditOffer}>
//             Edit Offer
//           </Button>
//           <Button sx={{ width: '250px' }} variant="contained" color="secondary" onClick={handleOpenInvite}>
//             Invite Collaborator
//           </Button>
//         </>
//       );
//     }
//     return (
//       <Button sx={{ width: '250px' }} variant="contained" color="secondary" onClick={handleAddNewVendor}>
//         Add New Offer
//       </Button>
//     );
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Box>
//         <Container sx={{ display: 'contents', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 0 }}>
//           <Typography variant="h4" gutterBottom>
//             Vendor Offers
//           </Typography>
//           <Box>
//             <DataTable
//               rows={offers}
//               columns={columns}
//               pageSize={5}
//               hideFooter
//               selectedRowId={selectedOfferId}
//               onSelectRow={toggleSelection}
//               getRowId={(row) => row.offerId} // Using offerId as the unique identifier
//               sx={{
//                 width: '100%',
//                 height: '100%',
//                 '& .super-app-theme--header': {
//                   backgroundColor: muiTheme.palette.primary.main,
//                   color: 'white',
//                   fontSize: '14px',
//                   '.MuiSvgIcon-root': { color: 'white' },
//                 },
//               }}
//             />
//           </Box>
//           <Box mt={2} display="flex" flexDirection="column" gap={2}>
//             {renderButtons()}
//           </Box>
//         </Container>
//       </Box>

//       {/* Invite Collaborator Dialog */}
//       <Dialog open={openInvite} onClose={handleCloseInvite} maxWidth="sm" fullWidth>
//         <DialogTitle>Invite Collaborator</DialogTitle>
//         <DialogContent>
//           <TextField
//             autoFocus
//             margin="dense"
//             label="Collaborator's Email"
//             type="email"
//             fullWidth
//             value={collaboratorEmail}
//             onChange={(e) => setCollaboratorEmail(e.target.value)}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button variant="outlined" onClick={handleCloseInvite}>Cancel</Button>
//           <Button variant="contained" color="primary" onClick={handleSaveInvite}>Send Invitation</Button>
//         </DialogActions>
//       </Dialog>

//       {/* Snackbar for invitation success message */}
//       <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} message="Invitation sent successfully!" />
//     </ThemeProvider>
//   );
// };

// export default VendorOfferMenu;


// final reusable 


import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Snackbar, ThemeProvider, useTheme, Container } from '@mui/material';
import theme from '../theme/theme';
import { UserProfileContext } from "../context/UserContext";
import { DataTable } from './reusable/DataTable'; // Updated import for DataTable

 const VendorOfferMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const muiTheme = useTheme();
  const { userProfile } = useContext(UserProfileContext);

  // Extracting offers from location.state, which comes from the previous page
  const offers = location.state?.offers ?? [];

  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [collaboratorEmail, setCollaboratorEmail] = useState('');
  const [openInvite, setOpenInvite] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Function to toggle offer selection
  const toggleSelection = (id) => {
    const newSelectedOfferId = selectedOfferId === id ? null : id;
    setSelectedOfferId(newSelectedOfferId);
    localStorage.setItem('selectedOfferId', newSelectedOfferId);
  };

  // Define columns for DataTable
  const columns = [
    { field: 'select', headerName: 'Select', width: 150 },
    { field: 'offerName', headerName: 'Offer Name', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'version', headerName: 'Version', flex: 1 },
    { field: 'createdBy', headerName: 'Created By', flex: 1 },
  ];

  // Handle opening and closing invite dialog
  const handleOpenInvite = () => setOpenInvite(true);
  const handleCloseInvite = () => setOpenInvite(false);
  const handleCloseSnackbar = () => setOpenSnackbar(false);

  // Save the invitation to collaborate on an offer
  const handleSaveInvite = async () => {
    if (!collaboratorEmail || !selectedOfferId) {
      console.error("Collaborator email or selected offer is missing");
      return;
    }

    const payload = {
      email: userProfile.email,
      currentOfferId: selectedOfferId,
      collaboratorEmail,
    };

    try {
      const response = await fetch(
        "https://newtestfuncpython.azurewebsites.net/api/inviteAnalystToCoCreateOffer",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) throw new Error("Failed to send invitation");

      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error sending invitation:", error);
    }

    handleCloseInvite();
  };

  // Handle editing the selected offer
  const handleEditOffer = async () => {
    if (!selectedOfferId) {
      console.error("No offer selected for editing.");
      return;
    }

    const payload = {
      email: userProfile.email,
      currentOfferId: selectedOfferId,
    };

    try {
      const response = await fetch("https://newtestfuncpython.azurewebsites.net/api/getExistingOfferFeatures", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok) throw new Error("Failed to fetch existing offer details");

      const data = await response.json();

      navigate('/analysis', { state: { buyerGuideData: data, fromVendorOfferMenu: true } });
    } catch (error) {
      console.error("Error fetching existing offer details:", error);
    }
  };

  // Add a new vendor
  const handleAddNewVendor = () => navigate('/add-new-offer');

  // Render action buttons based on selected offer
  const renderButtons = () => {
    if (selectedOfferId) {
      return (
        <>
          <Button sx={{ width: '250px' }} variant="contained" color="primary" onClick={handleEditOffer}>
            Edit Offer
          </Button>
          <Button sx={{ width: '250px' }} variant="contained" color="secondary" onClick={handleOpenInvite}>
            Invite Collaborator
          </Button>
        </>
      );
    }
    return (
      <Button sx={{ width: '250px' }} variant="contained" color="secondary" onClick={handleAddNewVendor}>
        Add New Offer
      </Button>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Container sx={{ display: 'contents', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 0 }}>
          <Typography variant="h4" gutterBottom>
            Vendor Offers
          </Typography>
          <Box>
            <DataTable
              rows={offers}
              columns={columns}
              pageSize={5}
              hideFooter
              selectedRowId={selectedOfferId}
              onSelectRow={toggleSelection} // Pass toggleSelection function for toggling
              getRowId={(row) => row.offerId} // Using offerId as the unique identifier
              sx={{
                width: '100%',
                height: '100%',
                '& .super-app-theme--header': {
                  backgroundColor: muiTheme.palette.primary.main,
                  color: 'white',
                  fontSize: '14px',
                  '.MuiSvgIcon-root': { color: 'white' },
                },
              }}
            />
          </Box>
          <Box mt={2} display="flex" flexDirection="column" gap={2}>
            {renderButtons()}
          </Box>
        </Container>
      </Box>

      {/* Invite Collaborator Dialog */}
      <Dialog open={openInvite} onClose={handleCloseInvite} maxWidth="sm" fullWidth>
        <DialogTitle>Invite Collaborator</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Collaborator's Email"
            type="email"
            fullWidth
            value={collaboratorEmail}
            onChange={(e) => setCollaboratorEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseInvite}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleSaveInvite}>Send Invitation</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for invitation success message */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} message="Invitation sent successfully!" />
    </ThemeProvider>
  );
};


export default VendorOfferMenu;