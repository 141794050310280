import React, { useContext, useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Typography,
  Grid,
  Paper,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import { UserProfileContext } from "../context/UserContext";
import CustomButton from "../components/CustomButton";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import { handleCheckout } from "../utils/utils";

const TabContent = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
  borderRadius: "4px",
  boxShadow: theme.shadows[1],
}));

const FormField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Header = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function PurchasePackSection() {
  const location = useLocation();
  const theme = useTheme();
  const { userProfile, packageCount } = useContext(UserProfileContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const durationKey = location && location?.state?.tabValue;
  // console.log("durationKey", durationKey);
  const monthlyPackages = location && location?.state?.packages;
  // console.log("monthlyPackages", monthlyPackages);
  const priceIds = location && location?.state?.priceIds;
  // console.log("priceIds", priceIds);
  const priceIdsForSUb = location && location?.state?.priceIdsForSUb;
  const [volumeUnitsByPackage, setVolumeUnitsByPackage] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0,
  });

  const [totalUnits, setTotalUnits] = useState(0);

  const [tabValue, setTabValue] = useState(monthlyPackages?.[durationKey]?.[0]);

  const rows = [
    { volumeUnit: "0 - 4", discount: "0%" },
    { volumeUnit: "5 - 24", discount: "10%" },
    { volumeUnit: "25 - 99", discount: "20%" },
    { volumeUnit: "100 or more", discount: "30%" },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (state, setState, key, value) => {
    // console.log("state", state, setState, key, value);
    setState({ ...state, [key]: Number(value) });
  };

  const calculateVolumeSum = () => {
    return Object.values(volumeUnitsByPackage).reduce(
      (total, num) => total + num,
      0
    );
  };

  useEffect(() => {
    const totalVolume = calculateVolumeSum();
    setTotalUnits(totalVolume);
    // console.log("Total Volume:", totalVolume);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeUnitsByPackage]);

  // const getDiscountCoupon = (quantity) => {
  //   if (+quantity >= 0 && +quantity <= 4) {
  //     return;
  //   }
  //   if (+quantity >= 5 && +quantity <= 24) {
  //     return "10PERCENTOFF";
  //   }
  //   if (+quantity >= 25 && +quantity <= 99) {
  //     return "20PERCENTOFF";
  //   }
  //   if (+quantity >= 100) {
  //     return "30PERCENTOFF";
  //   }
  // };

  const getProductsForSub = () => {
    let products = [];
    for (let key in priceIdsForSUb) {
      products.push({
        price: priceIdsForSUb[key],
        quantity: volumeUnitsByPackage[key],
        // discounts: [
        //   {
        //     coupon: getDiscountCoupon(volumeUnitsByPackage[key]),
        //   },
        // ],
      });
    }
    return products;
  };

  // console.log("getProductsForSub", getProductsForSub());

  const getProducts = () => {
    let products = [];
    for (let key in priceIds) {
      products.push({
        price: priceIds[key],
        quantity: volumeUnitsByPackage[key],
        // discounts: [
        //   {
        //     coupon: getDiscountCoupon(volumeUnitsByPackage[key]),
        //   },
        // ],
      });
    }
    return products;
  };

  const getPackageDetails = () => {
    const packages = {
      duration: location?.state?.packages,
      volumeUnitsByPackage,
    };
    //  monthlyPackages?.length > 0 &&
    //    monthlyPackages?.forEach((val) => {
    //      if (val === 1) {
    //        packages.tab1State = tab1State;
    //      }
    //      if (val === 2) {
    //        packages.tab2State = tab2State;
    //      }
    //      if (val === 3) {
    //        packages.tab3State = tab3State;
    //      }
    //    });
    return packages;
  };

  const returnDiscountLevel = () => {
    if (
      +volumeUnitsByPackage[tabValue] >= 0 &&
      +volumeUnitsByPackage[tabValue] <= 4
    ) {
      return 0;
    }
    if (
      +volumeUnitsByPackage[tabValue] >= 5 &&
      +volumeUnitsByPackage[tabValue] <= 24
    ) {
      return 1;
    }
    if (
      +volumeUnitsByPackage[tabValue] >= 25 &&
      +volumeUnitsByPackage[tabValue] <= 99
    ) {
      return 2;
    }
    if (+volumeUnitsByPackage[tabValue] >= 100) {
      return 3;
    }
  };

  return (
    <Container maxWidth="lg">
      <Header>
        <Typography variant="h6" component="div">
          User Name: John Doe
        </Typography>
        <Typography variant="h6" component="div" sx={{ marginBottom: "16px" }}>
          Buyer Guide: Current Buyer Guide
        </Typography>
      </Header>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant={isMobile ? "scrollable" : "standard"}
              scrollButtons="auto"
              aria-label="tabs example"
              centered={isMobile ? false : true}
            >
              {monthlyPackages &&
                monthlyPackages?.[durationKey]?.length > 0 &&
                monthlyPackages?.[durationKey]
                  .sort()
                  .map((val, ind) => (
                    <Tab
                      value={val}
                      key={val}
                      label={
                        val === 0
                          ? "Solo Starter"
                          : val === 1
                          ? "Duel Dynamics"
                          : val === 2
                          ? "Focus Five"
                          : "All Available"
                      }
                    />
                  ))}
            </Tabs>

            {monthlyPackages &&
              monthlyPackages?.[durationKey]?.length > 0 &&
              monthlyPackages?.[durationKey].sort().map((val, ind) => {
                return (
                  <TabPanel value={tabValue} index={val} key={val}>
                    {renderTabContent(
                      volumeUnitsByPackage,
                      setVolumeUnitsByPackage
                    )}
                  </TabPanel>
                );
              })}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Alert severity="info">
              Volume Units is calculated as the product of Number of Users,
              Number of End-User Organizations, and Number of Buyer Guide.
            </Alert>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={2}
            >
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Volume Unit</TableCell>
                      <TableCell>Discount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor:
                            returnDiscountLevel() === index && "primary.main",
                        }}
                      >
                        <TableCell>{row.volumeUnit}</TableCell>
                        <TableCell>{row.discount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Package Name</TableCell>
                      <TableCell>Net Purchase Volume</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {monthlyPackages &&
                      monthlyPackages?.[durationKey]?.length > 0 &&
                      monthlyPackages?.[durationKey]
                        .sort()
                        ?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              {row === 0
                                ? "Solo Starter"
                                : row === 1
                                ? "Duel Dynamics"
                                : row === 2
                                ? "Focus Five"
                                : "All Available"}
                            </TableCell>
                            <TableCell>{volumeUnitsByPackage[row]}</TableCell>
                          </TableRow>
                        ))}
                    <TableRow>
                      <TableCell>
                        <Typography variant="h3">Total Volume Units</Typography>
                      </TableCell>
                      <TableCell>
                        {" "}
                        <Typography variant="h3">{totalUnits}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <CustomButton
              style={{ width: "250px", marginBottom: "7px" }}
              color="primary"
              variant="contained"
              onClick={() =>
                handleCheckout(
                  userProfile?.email,
                  getProducts,
                  packageCount,
                  getPackageDetails,
                  getProductsForSub
                )
              }
              text="Checkout"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );

  function renderTabContent(state, setState) {
    return (
      <TabContent>
        <FormField
          label="Volume Units"
          type="number"
          variant="outlined"
          value={state[tabValue]}
          onChange={(e) => {
            const inputValue = e.target.value;
            // Convert input value to a number and ensure it's at least 1
            const numericValue = Math.max(1, Number(inputValue));
            handleInputChange(state, setState, tabValue, numericValue);
          }}
          onKeyDown={(e) => {
            // Prevent the user from entering 'e', '-', '.', or ',' characters
            if (["e", "E", "-", ".", ","].includes(e.key)) {
              e.preventDefault();
            }
          }}
          inputProps={{ min: 1 }}
          fullWidth
        />
      </TabContent>
    );
  }
}

export default PurchasePackSection;
