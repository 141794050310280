import React, { useState } from "react";
import { Box, TextField, Typography, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";

const CommonForm = ({
 title,
 nameLabel,
 versionLabel,
 descriptionLabel,
 radioLabel,
 documentLinkLabel,
 analystPromptLabel,
 buttonLabelAI,
 buttonLabelNonAI,
 handleSubmitAI,
 handleSubmitNonAI,
 initialName = "",
 initialVersion = "",
 initialDescription = "",
 initialGenerateWithAI = "yes",
 initialDocumentLink = "",
 initialAnalystPrompt = ""
}) => {
 const [name, setName] = useState(initialName);
 const [version, setVersion] = useState(initialVersion);
 const [description, setDescription] = useState(initialDescription);
 const [generateWithAI, setGenerateWithAI] = useState(initialGenerateWithAI);
 const [documentLink, setDocumentLink] = useState(initialDocumentLink);
 const [analystPrompt, setAnalystPrompt] = useState(initialAnalystPrompt);

 const handleRadioChange = (event) => {
   setGenerateWithAI(event.target.value);
 };

 const handleNameChange = (e) => setName(e.target.value);
 const handleVersionChange = (e) => setVersion(e.target.value);
 const handleDescriptionChange = (e) => setDescription(e.target.value);
 const handleDocumentLinkChange = (e) => setDocumentLink(e.target.value);
 const handleAnalystPromptChange = (e) => setAnalystPrompt(e.target.value);

 const handleSubmit = () => {
   if (generateWithAI === "yes") {
     handleSubmitAI(name, version, description);
   } else {
     handleSubmitNonAI(documentLink, analystPrompt);
   }
 };

 return (
   <Box sx={{ width: '100%', padding: '16px' }}>
     <Typography variant="h6" sx={{ marginBottom: "16px" }}>{title}</Typography>

     <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
       <Typography sx={{ minWidth: '200px' }}>{nameLabel}</Typography>
       <TextField
         fullWidth
         value={name}
         onChange={handleNameChange}
         sx={{ flex: 1 }}
       />
     </Box>

     <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
       <Typography sx={{ minWidth: '200px' }}>{versionLabel}</Typography>
       <TextField
         fullWidth
         value={version}
         onChange={handleVersionChange}
       />
     </Box>

     <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
       <Typography sx={{ minWidth: '200px' }}>{descriptionLabel}</Typography>
       <TextField
         fullWidth
         multiline
         rows={3}
         value={description}
         onChange={handleDescriptionChange}
       />
     </Box>

     <Typography sx={{ marginBottom: '8px' }}>{radioLabel}</Typography>
     <RadioGroup row value={generateWithAI} onChange={handleRadioChange}>
       <FormControlLabel value="yes" control={<Radio />} label="Yes" />
       <FormControlLabel value="no" control={<Radio />} label="No" />
     </RadioGroup>

     {generateWithAI === "no" && (
       <>
         <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
           <Typography sx={{ minWidth: '200px' }}>{documentLinkLabel}</Typography>
           <TextField
             fullWidth
             value={documentLink}
             onChange={handleDocumentLinkChange}
           />
         </Box>

         <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
           <Typography sx={{ minWidth: '200px' }}>{analystPromptLabel}</Typography>
           <TextField
             fullWidth
             multiline
             rows={3}
             value={analystPrompt}
             onChange={handleAnalystPromptChange}
           />
         </Box>
       </>
     )}

     <Button
       variant="contained"
       color="primary"
       sx={{ width: "250px", alignSelf: 'flex-start', mt: 2 }}
       onClick={handleSubmit}
     >
       {generateWithAI === "yes" ? buttonLabelAI : buttonLabelNonAI}
     </Button>
   </Box>
 );
};

export default CommonForm;


