import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import theme from "../../theme/theme";

const NumberInput = ({
  id,
  isDisabled,
  max,
  rowData,
  inputsLabel,
  totalLabel,
  onSet,
  page,
}) => {
  const [value, setValue] = useState(0);
  // console.log("cbvbnxcbvx"+id);
  useEffect(() => {
    setValue(rowData[inputsLabel]);
  }, [rowData, inputsLabel]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    const numVlue = Number(value);

    if (numVlue >= 0 && value !== "") {
      // if same value enterted nothing will rerender
      if (numVlue !== rowData[inputsLabel]) {
        if (numVlue > max) rowData[inputsLabel] = max;
        else rowData[inputsLabel] = numVlue;
        setValue(rowData[inputsLabel]);
        onSet();
      }
    } else {
      if ((value === "" || value < 0) && rowData[inputsLabel] !== 0) {
        rowData[inputsLabel] = 0;
        onSet();
      }

      // For restriction against -ve values when rowData[inputsLabel] === 0
      setValue(0);
    }
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {(max === 0 || isDisabled) && (
        <Tooltip
          title={
            max !== 0
              ? "Please fill all the Required Fields"
              : `You don't have any Valid package Unit for Allocation`
          }
          componentsProps={{
            tooltip: {
              sx: {
                background: theme.palette.secondary.main,
              },
            },
          }}
        >
          <TextField
            variant="standard"
            data-test-id="input-field"
            color="secondary"
            sx={{
              width: "35px",
              paddingX: "auto",

              "& .css-1w7v89a-MuiInputBase-root-MuiInput-root.Mui-disabled:before ": {
                borderBottom: "none",
              },
            }}
            value={max}
            size="small"
            disabled
            InputProps={{
              inputProps: {
                style: { textAlign: "center" },
              },
            }}
          />
        </Tooltip>
      )}
      {page === "avalable_for_allocation" && rowData.errorInputsLabel === inputsLabel && (
        <WarningAmberRoundedIcon
          data-test-id="warning"
          color="warning"
          style={{ marginRight: "20px" }}
        />
      )}
      {max > 0 &&
        !isDisabled &&
        page === "avalable_for_allocation" &&
        ((inputsLabel !== "totalUnitsInput" && rowData["totalUnitsInput"] > 0) ||
          inputsLabel === "totalUnitsInput") && (
          <TextField
            autoComplete="off"
            variant="standard"
            color="secondary"
            sx={{
              width: value?.length === 12 && page === "avalable_for_allocation" ? "90px" : "35px",
              paddingX: "auto",

              "& .MuiInputBase-input": {
                // WebkitTextFillColor: 'black',
              },
              "& .MuiInput-underline:before": {
                borderBottom: "none",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "2px solid",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "2px solid",
              },
            }}
            value={value}
            onChange={handleChange}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={handleBlur}
            size="small"
            InputProps={{
              inputProps: {
                "data-test-id": id,
                style: { textAlign: "center" },
              },
            }}
          />
        )}
      {page === "avalable_for_allocation" && rowData[totalLabel] !== null && (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            data-test-id={`slash-${id}`}
            sx={{
              marginX: "4px",
              // fontFamily: theme.typography.h4,
            }}
          >
            /
          </Typography>
          <Typography data-test-id={`label-${id}`}>{rowData[totalLabel]}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default NumberInput;
